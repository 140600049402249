import React, {useEffect, useState} from 'react';
import {API_ENDPOINT} from "../../../utils/constants/app.constants";
import {postAuthenticated} from "../../../utils/actions/post.actions";
import {isEmpty} from "../../../utils/helpers/app.helpers";
import moment from "moment/moment";

const ServiceReportsModal = (props) => {
    const fleet = props.fleet;
    const fleetBatch = props.fleetBatch;
    const [init, setInit] = useState(false);
    const [serviceReports, setServiceReports] = useState([]);

    useEffect(() => {
        getCustomerServiceReports();
    }, []);

    function getCustomerServiceReports() {
        let url = API_ENDPOINT + "v1/ServiceReports/getCustomerServiceReports";
        let params = {
            fleet_no: fleet.fleet_no,
            rego_no: fleet.rego_no
        };

        postAuthenticated(url, params).then(results => {
            setServiceReports(results.data);
            setInit(true);
        });
    }

    function hideServiceReport(serviceReport) {
        let value = serviceReport.is_hidden == 0 ? 1 : 0;

        let url = API_ENDPOINT + "v1/ServiceReports/hideServiceReport";
        let params = {
            id: serviceReport.id,
            is_hidden: value
        };

        postAuthenticated(url, params).then(results => {
            getCustomerServiceReports();
        });
    }

    return (
        init &&
        <div className="modal d-block bg-dark bg-opacity-50" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog mw-80 modal-dialog-scrollable modal-entrance d-flex">
                <div className="modal-content w-80 h-80 m-auto">
                    <div className="modal-header">
                        <h2 className="lead fs-32">Service Reports ({serviceReports.length})</h2>
                    </div>

                    <div className={`modal-body`} style={{overflowY: "scroll"}}>
                        <table className="table table-borderless w-90 m-auto">
                            <thead>
                                <tr className="border border-2 border-start-0 border-end-0 border-top-0">
                                    <th>Service Report No.</th>
                                    <th>Start Date</th>
                                    <th>Finish Date</th>
                                    <th className="text-end">Hide</th>
                                </tr>
                            </thead>
                            <tbody>
                                {serviceReports.map((serviceReport, index) =>
                                    <tr className="border border-1 border-start-0 border-end-0 border-top-0">
                                        <td><a href={`/service-report/edit/${serviceReport.srno}`}>{serviceReport.srno}</a></td>
                                        <td>{!isEmpty(serviceReport.start_date) ? moment(serviceReport.start_date).format("DD/MM/YYYY") : ""}</td>
                                        <td>{!isEmpty(serviceReport.end_date) ? moment(serviceReport.end_date).format("DD/MM/YYYY") : ""}</td>
                                        <td>
                                            <div className={`form-check form-switch mb-1 ms-5`}>
                                                <input
                                                    type="checkbox" role="switch" className="form-check-input float-end"
                                                    name="is_hidden" value={serviceReport.is_hidden}
                                                    checked={serviceReport.is_hidden == 1}
                                                    onChange={(e) => hideServiceReport(serviceReport)}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    <div className="modal-footer d-flex flex-nowrap w-95 m-auto">
                        <div className="col-6 text-end">
                            <button className="btn btn-warning text-dark" onClick={() => props.onClose()}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceReportsModal;