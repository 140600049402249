import React from 'react';
import * as serviceReportConstants from "../../../utils/constants/serviceReport.constants";
import {useSelector} from "react-redux";
import {PARTS_DETAILS_TABLE} from "../../../utils/structures/serviceReport.structures";
import {isEmpty, isObjectEmpty} from "../../../utils/helpers/app.helpers";
import TextInput from "../../form/TextInput";

const PartTableModal = (props) => {
    const serviceReportDetails = props.service_report;
    const userPermissions = useSelector(state => state.userPermissions);

    function renderTD(index) {
        let td = [];
        let textStyle = "text-secondary-emphasis lead fs-14 border-0 bg-white ps-0 ms-0";
        PARTS_DETAILS_TABLE.map((part, partIndex) => {
            td.push(
                part.constant !== "clear" &&
                <React.Fragment key={partIndex}>
                    {userPermissions.parts_table[part.object] &&
                        <td className="align-middle" key={partIndex} data-label={part.name} style={{width: `1%`}}>
                            {part.object === "location" ?
                                <React.Fragment>
                                    <div className={`d-xl-block d-none`} style={{backgroundColor: '#fff'}}>
                                        <span className={textStyle}>
                                            {!isObjectEmpty(serviceReportDetails) && !isEmpty(serviceReportDetails.parts_table[index][part.object])
                                                ? serviceReportDetails.parts_table[index][part.object]
                                                : ''}
                                        </span>
                                    </div>
                                </React.Fragment>
                            : part.object === "price" ?
                                <React.Fragment>
                                    <TextInput
                                        name={`${part.object}`}
                                        value={!isObjectEmpty(serviceReportDetails) && serviceReportDetails.parts_table[index][part.object]}
                                        className={textStyle}
                                        disabled={true}
                                    />
                                </React.Fragment>
                            : part.object === "total_price" ?
                                    <TextInput
                                        name={`${part.object}`}
                                        value={!isObjectEmpty(serviceReportDetails) && serviceReportDetails.parts_table[index][part.object]}
                                        className={textStyle}
                                        disabled={true}
                                    />
                            : part.object === "discount" ?
                                <TextInput
                                    name={`${part.object}`}
                                    value={!isObjectEmpty(serviceReportDetails) && serviceReportDetails.parts_table[index][part.object]}
                                    className={textStyle}
                                    disabled={true}
                                />
                            :
                                <TextInput
                                    name={`${part.object}`}
                                    value={serviceReportDetails.parts_table[index][part.object]}
                                    className={textStyle}
                                    disabled={true}
                                />
                            }
                        </td>
                    }
                </React.Fragment>
            );
        })
        return td;
    }

    function renderTR() {
        let tr = [];
        serviceReportDetails.parts_table.map((row, index) =>
            tr.push(
                <tr key={index} className="border border-1 border-top-0 border-start-0 border-end-0">{renderTD(row.index)}</tr>
            ))

        return tr;
    }

    return (
        <div className="modal d-block bg-dark bg-opacity-50" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog mw-80 modal-dialog-scrollable modal-entrance">
                <div className="modal-content w-100 h-95 m-auto">
                    <div className="modal-header d-block p-3">
                        <div className="w-95 m-auto">
                            <div className="d-flex">
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Parts Table</h3>
                                <button onClick={() => props.onClose()}
                                        className="border border-0 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                    <i className="bi bi-x-lg text-secondary-emphasis" />
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                            <h4 className="lead fs-16 fst-italic pb-0 text-secondary">for <span className="ms-1 text-decoration-underline fw-light">{props.service_report.srno}</span></h4>
                        </div>
                    </div>

                    <div className={`modal-body bg-white w-95 m-auto`} style={{overflowY: "scroll"}}>
                        <table className="table table-borderless table-responsive lead fs-14 text-start w-100 m-auto">
                            <thead>
                                <tr>
                                    {userPermissions.parts_table.qty && <th>{serviceReportConstants.QTY_LABEL}</th>}
                                    {userPermissions.parts_table.part && <th>{serviceReportConstants.PART_NO_LABEL}</th>}
                                    {userPermissions.parts_table.desc && <th>{serviceReportConstants.DESCRIPTION_LABEL}</th>}
                                    {userPermissions.parts_table.location && <th>{serviceReportConstants.LOCATION_LABEL}</th>}
                                    {userPermissions.parts_table.discount && <th>{serviceReportConstants.DISCOUNT_LABEL}</th>}
                                    {userPermissions.parts_table.price && <th>{serviceReportConstants.PRICE_EA_LABEL}</th>}
                                    {userPermissions.parts_table.total_price && <th>{serviceReportConstants.TOTAL_PRICE_LABEL}</th>}
                                </tr>
                            </thead>

                            <tbody>
                                {renderTR()}
                            </tbody>
                        </table>
                    </div>

                    <div className="modal-footer justify-content-start items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                        <button className="btn btn-primary text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            onClick={() => props.onClose()}>
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PartTableModal;