import React from 'react';
import {CHANGE_LOGS} from "../../../utils/structures/changelog.structures";
import Back from "../../common/Back";

const Changelog = () => {
    return (
        <div>
            <div className="w-90 m-auto">
                <div className="m-4">
                    <h1 className="lead fs-1 mb-5">Changelog</h1>

                    {CHANGE_LOGS.map((change, index) =>
                        <React.Fragment>
                            <div className="mb-2 pb-1">
                                <div className="lead fs-4 mb-2">{change.version} ({change.date})</div>
                                <ul className="border border-primary border-2 border-top-0 border-end-0 border-bottom-0 lead fs-5">
                                    {change.log.map(log =>
                                        <li dangerouslySetInnerHTML={{__html: log.change}} />
                                    )}
                                </ul>
                            </div>

                            <hr />
                        </React.Fragment>
                    )}
                </div>
            </div>

            <div className="w-95 m-auto">
                <Back />
            </div>
        </div>
    );
};

export default Changelog;