import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import moment from "moment";

import Loader from "../../../common/Loader";
import TextInput from "../../../form/TextInput";
import ServiceLogModal from "../../../modals/serviceReportModals/ServiceLogModal";
import {postAuthenticated} from "../../../../utils/actions/post.actions";
import {setServiceLogTracker} from "../../../../utils/helpers/action.helpers";
import {API_ENDPOINT} from "../../../../utils/constants/app.constants";

const ServiceReportLogs = () => {
    const dispatch = useDispatch();
    const serviceLogTracker = useSelector(state => state.serviceLogTracker);

    const [init, setInit] = useState(false);
    const [hasFocused, setHasFocused] = useState(false);
    const [isReinstating, setIsReinstating] = useState(false);
    const [openServiceLogModal, setOpenServiceLogModal] = useState(false);
    const [serviceReportLogs, setServiceReportLogs] = useState([]);
    const [selectedLog, setSelectedLog] = useState({});

    useEffect(() => {
        getLogs();
    }, [serviceLogTracker.search_term]);

    useEffect(() => {
        if(init) {
            let recentServiceReportLog = document.getElementById(serviceLogTracker.lastSelectedLog.id);
            if(recentServiceReportLog) {
                recentServiceReportLog.focus();
                openAccordion(serviceLogTracker.lastSelectedLog);
                setHasFocused(true);
            }
        }
    }, [init]);

    useEffect(() => {
        if(hasFocused) {
            let recentServiceRevisionLog = document.getElementById(`revision_${serviceLogTracker.lastSelectedRevision.id}`);
            if(recentServiceRevisionLog) {
                recentServiceRevisionLog.focus();
            }
            setHasFocused(false);
        }
    }, [hasFocused]);

    function getLogs() {
        setInit(false);

        let url = API_ENDPOINT + "v1/ServiceReportLogs/getLogs";
        let params = {
            keywords: serviceLogTracker.search_term
        };

        postAuthenticated(url, params).then(results => {
            setServiceReportLogs(results.data);
            setInit(true);
        })
    }

    function reinstateServiceReport(service_report_log) {
        setIsReinstating(true);

        let url = API_ENDPOINT + "v1/ServiceReports/reinstateServiceReport";
        let params = {
            service_report_log: service_report_log.id
        };

        postAuthenticated(url, params).then(results => {
            setIsReinstating(false);
        })
    }

    function openAccordion(service_report_log) {
        let selectedReport = {...serviceReportLogs.find(x => x.id == service_report_log.id)};
        let selectedReportIndex = serviceReportLogs.findIndex(x => x.id == service_report_log.id);

        selectedReport.is_open = !selectedReport.is_open;
        let _serviceReportLogs = Object.assign([], {...serviceReportLogs, [selectedReportIndex]: selectedReport});
        setServiceReportLogs(_serviceReportLogs);

        let _serviceLogTracker = {
            lastSelectedLog: service_report_log
        }
        dispatch(setServiceLogTracker(_serviceLogTracker));
    }

    function rememberLastSelectedRevision(log) {
        let _serviceLogTracker = {
            ...serviceLogTracker,
            lastSelectedRevision: log
        };
        dispatch(setServiceLogTracker(_serviceLogTracker));
    }

    function updateSearch(event) {
        const _serviceLogTracker = {
            ...serviceLogTracker,
            search_term: event.target.value
        }
        dispatch(setServiceLogTracker(_serviceLogTracker));
    }

    console.log(serviceReportLogs)

    return (
        <div>
            {openServiceLogModal &&
                <ServiceLogModal
                    serviceReportLog={selectedLog}
                    closeModal={setOpenServiceLogModal} /> }

            <hr/>
            <h2 className="fs-1 lead p-2">Service Report Logs</h2>

            <div className="w-100 m-auto input-group mb-3 mt-2">
                <TextInput
                    name="search_term"
                    value={serviceLogTracker.search_term}
                    placeholder="Search..."
                    onChange={(e) => updateSearch(e)}
                />
            </div>

            <label className="lead text-decoration-underline mb-4">Results: {serviceReportLogs.length}</label>
            <div className="row">
                {init ?
                    Object.keys(serviceReportLogs).length === 0 ?
                        <div>No logs were found</div>
                    :
                        <div className="">
                            {serviceReportLogs.map((serviceReportLog, index) =>
                                <div id={serviceReportLog.id} className="p-3 mb-3 rounded-0 w-100" style={{border: `${serviceLogTracker.lastSelectedLog.id == serviceReportLog.id ? '3px solid blue' : '1px solid #CFCFCF'}`}} tabIndex={0}>
                                    <div role="button" onClick={() => openAccordion(serviceReportLog)}>
                                        <i className={`fa ${serviceReportLog.is_open ? 'fa-chevron-down' : 'fa-chevron-right'} me-3`} />
                                        <label className="lead">{serviceReportLog.srno} - Revisions <span className="fw-light">({serviceReportLog.revisions - 1})</span></label>

                                        <div className="lead fs-6 mt-3">Last edited by: {serviceReportLog.logs[0].user.name}</div>
                                        <div className="fs-6">Last revision was {moment(serviceReportLog.logs[0].created).fromNow()} - {moment(serviceReportLog.logs[0].created).format("DD/MM/YYYY")} {moment(serviceReportLog.logs[0].created).format("hh:mm:ss A")}</div>
                                    </div>

                                    {serviceReportLog.is_open &&
                                        <React.Fragment>
                                            <hr />
                                            <ul className="px-4 mt-3">
                                                {serviceReportLog.logs.map((log, childIndex) =>
                                                    childIndex !== serviceReportLog.revisions - 1 &&
                                                    <React.Fragment>
                                                        <div id={`revision_${log.id}`} tabIndex={0} className="d-lg-flex d-md-block mb-4" style={{backgroundColor: `${serviceLogTracker.lastSelectedRevision.id == log.id ? '#ffff8a' : ''}`, padding: `${serviceLogTracker.lastSelectedRevision.id == log.id ? '5px' : ''}`}}>
                                                            <div className="col-9">
                                                                <li className="lead label mb-3">
                                                                    <Link to={`/service-report/revision/${log.id}`} state={{ log: log, serviceReportLog: serviceReportLog}} onClick={() => rememberLastSelectedRevision(log)}>
                                                                        <span className="link-primary">#{log.id} - View this revision</span>
                                                                    </Link>
                                                                    <div className="lead fs-6 form-label p-0 m-0">Edited by: {log.user.name}</div>
                                                                    <div className="fs-6">Last updated {moment(log.created).fromNow()} - {moment(log.created).format("DD/MM/YYYY")} {moment(log.created).format("hh:mm:ss A")}</div>
                                                                </li>
                                                            </div>
                                                            <div className="col-lg-3 text-end">
                                                                <button className="btn btn-warning w-100" disabled={isReinstating} onClick={() => reinstateServiceReport(log)}>
                                                                    {isReinstating
                                                                        ? 'Reinstating'
                                                                        : 'Reinstate'
                                                                    }
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                            </ul>
                                        </React.Fragment>
                                    }
                                </div>
                            )}
                        </div>
                : <Loader /> }
            </div>
        </div>
    );
};

export default ServiceReportLogs;
