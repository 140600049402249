import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {SketchPicker} from "react-color";

import * as serviceReportConstants from "../../../utils/constants/serviceReport.constants";

import TextInput from "../../form/TextInput";
import CheckboxInput from "../../form/CheckboxInput";
import InvoiceModal from "../../modals/historyModals/InvoiceModal";
import SignaturePadModal from "../../modals/serviceReportModals/SignaturePadModal";
import colorWheelIcon from "../../../assets/icons/icons8-color-wheel.png";

import {setModal} from "../../../utils/slices/modal.slice";
import {postAuthenticated} from "../../../utils/actions/post.actions";
import {setServiceReport} from "../../../utils/helpers/action.helpers";
import {isEmpty, isObjectEmpty} from "../../../utils/helpers/app.helpers";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";

const ServiceStatus = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userProfile = useSelector(state => state.userProfile);
    const userPermissions = useSelector(state => state.userPermissions);
    const serviceReportDetails = useSelector(state => state.serviceReport);
    const [isServiceStatusEditable, setIsServiceStatusEditable] = useState(false);
    const [selectedColorPicker, setSelectedColorPicker] = useState(0);

    const [showInputField, setShowInputField] = useState(false);
    const [openColorPicker, setOpenColorPicker] = useState(false);

    const [openSignaturePad, setOpenSignaturePad] = useState(false);

    const [allReportColors, setAllReportColors] = useState(null);
    const [newReportStatusDetails, setNewReportStatusDetails] = useState({
        report_status: "",
        color: "#ffffff",
    });

    useEffect(() => {
        getReportColors();
    }, []);

    function handleChange(event) {
        let _fields = {...newReportStatusDetails, [event.target.name]: event.target.value}
        setNewReportStatusDetails(_fields);
    }

    function handleToggle(event) {
        let value = event.target.value === "NO" ? "NULL" : "NO";
        let _fields = {
            [event.target.name]: value
        }

        dispatch(setServiceReport(_fields));
        props.updateServiceReport(_fields);
    }

    function handleKeyDown(event) {
        event.key === "Escape" && resetReportColorButton();
        if (event.key === 'Enter') {
            if(event.target.value != ""){
                addNewReportColorStatus();
            }
        }
    }

    function handleColorChange(colorValue) {
        setNewReportStatusDetails({...newReportStatusDetails, ["color"]: colorValue});
    }

    function getReportColors() {
        let url = API_ENDPOINT + 'v1/ReportColors/getReportColors';
        let params = {}

        postAuthenticated(url, params).then(results => {
            setAllReportColors(Object.assign([], results.data));
        });
    }

    function addNewReportColorStatus() {
        let url = API_ENDPOINT + 'v1/ReportColors/addReportColorStatus';
        let params = {
            report_status: newReportStatusDetails.report_status,
            color: newReportStatusDetails.color
        }

        postAuthenticated(url, params).then(() => {
            resetReportColorButton();
            getReportColors();
        });
    }

    function updateReportColorById(_fields, id) {
        let url = API_ENDPOINT + 'v1/ReportColors/updateReportColorById';
        let params = {
            report_status_id: id,
            fields: _fields,
        }

        postAuthenticated(url, params).then(results => {}).catch();
    }

    function clearSignature() {
        let _fields = {
            "signature": "",
        };
        dispatch(setServiceReport(_fields));
        props.updateServiceReport(_fields);
    }

    function canViewServiceStatus(constant) {
        return userPermissions.service_status[constant] == "true" || userPermissions.service_status[constant] == true;
    }

    function canUserViewEditServiceStatuses() {
        return userProfile.role == 4 || userProfile.role == 1;
    }

    function updateServiceStatus(serviceBtn) {
        let _fields = {
            'service_status': serviceBtn.id
        }
        dispatch(setServiceReport(_fields));
        props.updateServiceReport(_fields);
    }

    function handleUpdateServiceStatusChanges(event, report_status, index) {
        let value = event.target.value;
        let _fields = {
            [event.target.name]: value
        }

        setAllReportColors(prevState => {
            const newState = prevState.map(obj => {
                if(obj.id == report_status.id) {
                    return {...obj, 'report_status': value}
                }
                return obj;
            })
            return newState;
        });
        updateReportColorById(_fields, report_status.id);
    }

    function handleUpdateServiceStatusColorChanges(report_status, color) {
        let _fields = {
            ["color"]: color
        }

        setAllReportColors(prevState => {
            const newState = prevState.map(obj => {
                if(obj.id == report_status.id) {
                    return {...obj, 'color': color}
                }
                return obj;
            })
            return newState;
        });
        updateReportColorById(_fields, report_status.id);
    }

    function canSetInvoiceDetails() {
        return userProfile.role == 1 || userProfile.role == 4;
    }

    function resetReportColorButton() {
        newReportStatusDetails.report_status = "";
        newReportStatusDetails.color = "#ffffff";
        setShowInputField(false);
        setOpenColorPicker(false);
    }

    function launchInvoiceModal() {
        let modalSettings = {
            config: {
                component: InvoiceModal,
                props: {
                    sr: serviceReportDetails,
                }
            },
            onClose: () => {
                props.getServiceReportById();
            }
        }

        dispatch(setModal(modalSettings));
    }

    function isAdmin() {
        return userProfile.role == 4 || userProfile.role == 1;
    }


    function isInvoiced() {
        return serviceReportDetails.service_status == 5 && !isAdmin();
    }

    function serviceReportActionPermission(constant) {
        return userPermissions.service_report_actions[constant] == true;
    }

    return (
        <div className="d-flex mt-4">
            {!isInvoiced() &&
                <div className="col-6">
                    <b>
                        {serviceReportConstants.SERVICE_STATUS_LABEL}
                        {canUserViewEditServiceStatuses() && (props.actionType === "edit" || props.actionType === "create") &&
                            <span className="text-primary p-0" role="button" onClick={() => setIsServiceStatusEditable(!isServiceStatusEditable)}>
                                {!isServiceStatusEditable ? " - Open Edit" : " - Close Edit"}
                            </span>
                        }
                    </b>
                    {!isObjectEmpty(userPermissions) &&
                        <div className="d-flex m-auto flex-wrap">
                            {allReportColors !== null ?
                                allReportColors.map((list, index) => (
                                    canViewServiceStatus(list.constant) && list.id != 5 &&
                                    <div key={list.id} className="mt-3 me-3 text-center" style={{width: "150px"}}>
                                        <button
                                            type="button"
                                            className={`service-status-buttons ${list.id == serviceReportDetails.service_status ? 'active' : ''}`}
                                            onClick={() => !isServiceStatusEditable && updateServiceStatus(list)}
                                            disabled={props.isEditable}
                                            style={{backgroundColor: `${list.color}`}}
                                        >
                                            {!isServiceStatusEditable
                                                ? <span className="text-dark">{list.report_status}</span>
                                                :
                                                <React.Fragment>
                                                    <img src={colorWheelIcon}
                                                         alt="" width={20} height={20}
                                                         className="float-end"
                                                         onClick={() => selectedColorPicker == list.id ? setSelectedColorPicker(0) : setSelectedColorPicker(list.id)}
                                                         style={{marginTop: '-20px'}}
                                                    />
                                                    {selectedColorPicker == list.id &&
                                                        <div className="position-relative"
                                                             style={{zIndex: "1", top: "60px", left: "-40px"}}>
                                                            <SketchPicker
                                                                color={list.color}
                                                                className="position-absolute"
                                                                onChangeComplete={(color) => handleUpdateServiceStatusColorChanges(list, color.hex)}
                                                            />
                                                        </div>
                                                    }
                                                    <TextInput
                                                        name="report_status"
                                                        value={list.report_status}
                                                        onChange={(e) => handleUpdateServiceStatusChanges(e, list, index)}
                                                    />
                                                </React.Fragment>
                                            }
                                        </button>
                                    </div>
                                ))
                                : null}

                            {props.actionType !== "view" && canViewServiceStatus('add') &&
                                <div className="mt-3 me-3 text-center" style={{width: "150px"}}>
                                    <button type="button" className="service-status-buttons" style={{backgroundColor: `${newReportStatusDetails.color}`}}>
                                        <div className="text-end" onClick={() => setOpenColorPicker(!openColorPicker)}>
                                            <img src={colorWheelIcon} alt="" width={20} height={20} style={{marginTop: '-35px'}}/>
                                        </div>
                                        <div style={{marginTop: "-20px"}}>
                                            {!showInputField
                                                ? <span className="fs-5" onClick={() => setShowInputField(!showInputField)}>+Add</span>
                                                :
                                                <TextInput
                                                    name="report_status" value={newReportStatusDetails.report_status}
                                                    onChange={(e) => handleChange(e)} onKeyDown={(e) => handleKeyDown(e)}
                                                    style={{backgroundColor: `${newReportStatusDetails.color}`}}
                                                />
                                            }
                                        </div>
                                    </button>
                                    {openColorPicker &&
                                        <div>
                                            <SketchPicker color={newReportStatusDetails.color} onChangeComplete={(color) => handleColorChange(color.hex)}/>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    }
                </div>
            }

            <div className="col-xl-6 mt-sm-3">
                <CheckboxInput
                    name="ifpo"
                    label="If No PO Provided"
                    value={serviceReportDetails.ifpo}
                    checked={serviceReportDetails.ifpo === "NO"}
                    onChange={(e) => handleToggle(e)}
                    disabled={props.isEditable}
                    className="me-2"
                />

                {serviceReportDetails.signature ?
                    <div className="w-50 mt-3">
                        <button className="w-100 p-2 border border-1 btn btn-danger" disabled={props.isEditable} onClick={() => clearSignature()}>Remove Signature</button>
                    </div>
                :
                    !openSignaturePad ?
                        <button type="button" onClick={() => setOpenSignaturePad(!openSignaturePad)}
                                className="mt-4 btn btn-primary p-3"
                                disabled={props.isEditable}
                                style={{fontSize: "14px", backgroundColor: "#9c27b0"}}>
                            {serviceReportConstants.OPEN_SIGNATURE_PAD_LABEL}
                        </button>
                        :
                        <SignaturePadModal
                            setOpenSignaturePad={setOpenSignaturePad}
                        />
                }

                <div className="d-flex mt-4">
                    {canSetInvoiceDetails() &&
                        <div className="">
                            <button type="button" className="btn btn-success text-white me-4" onClick={() => launchInvoiceModal()} disabled={props.isEditable}>
                                {!isEmpty(serviceReportDetails.invoice_no) ? 'Edit Invoice' : 'Create Invoice'}
                            </button>
                        </div>
                    }

                    {serviceReportActionPermission("print") &&
                        <div className="">
                            <button type="button" className="btn btn-secondary text-white" onClick={() => navigate(`/print-report/${serviceReportDetails.id}`)} disabled={props.isEditable}>Print Report</button>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default ServiceStatus;
