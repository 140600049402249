import React from 'react';

const TextAreaInput = (props) => {
    return (
        <React.Fragment>
            {props.label && <label htmlFor={props.name} className={`lead form-label fs-16 p-0 m-0 mb-1 ${props.labelClassName}`}>{props.label}</label> }
            {props.required && <span className="text-danger"> *</span> }
            <textarea
                id={props.id || props.name}
                name={props.name}
                value={props.value}
                rows={props.rows}
                cols={props.cols}
                disabled={props.disabled}
                onChange={props.onChange}
                onBlur={props.onBlur}
                onFocus={props.onFocus}
                className={`${props.className}`}
                style={{
                    ...props.style,
                    border: `${(props.error || props.errorBorder) && !props.successBorder ? '1px solid red' : ''}`,
                }}
            />
            {props.error && <div className={`${props.errorClassName} lead text-danger fs-6 mt-1`}>{props.error}</div> }
        </React.Fragment>
    );
};

export default TextAreaInput;
