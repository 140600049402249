import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import MobileDetect from "mobile-detect";

import {LAYOUT_VIEW} from "../../utils/structures/serviceReport.structures";
import {API_ENDPOINT} from "../../utils/constants/app.constants";
import {postAuthenticated} from "../../utils/actions/post.actions";
import {setUserProfile} from "../../utils/helpers/action.helpers";

const LayoutView = () => {
    const dispatch = useDispatch();
    const userProfile = useSelector(state => state.userProfile);
    const [width, setWidth] = useState(window.innerWidth);

    const md = new MobileDetect(window.navigator.userAgent);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    useEffect(() => {
        let newLayoutView;

        if(md.mobile() != null && md.phone() != null) {
            newLayoutView = LAYOUT_VIEW[2];
        }else if(md.tablet() != null) {
            newLayoutView = LAYOUT_VIEW[1];
        }else if(md.mobile() == null && md.phone() == null && md.tablet() == null){
            newLayoutView = LAYOUT_VIEW[0];
        }

        updateLayoutView(newLayoutView);
    }, [width]);

    function updateLayoutView(_selectedLayoutOption) {
        let url = API_ENDPOINT + 'v1/Users/updateLayoutView';
        let params = {
            user_id: userProfile.id,
            layout_view_constant: _selectedLayoutOption.constant
        }

        postAuthenticated(url, params).then(results => {
            let _userProfile = {...userProfile, 'layout_view_constant': _selectedLayoutOption.constant};
            dispatch(setUserProfile(_userProfile));
        });
    }

    function changeSelectedLayoutView(event) {
        let selectedLayoutOption = LAYOUT_VIEW.find(x => x.constant === event.target.value);
        updateLayoutView(selectedLayoutOption);
    }

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    return (
        <div className="m-auto mt-3 p-0">
            {userProfile.layout_view_constant ?
                <React.Fragment>
                    <div className="">Select Layout View:</div>
                    <select className="form-control w-100" onChange={(e) => changeSelectedLayoutView(e)}>
                        {LAYOUT_VIEW.map((layout, index) =>
                            userProfile.layout_view_constant === layout.constant
                                ? <option key={index} className="form-control w-100" value={layout.constant} selected>{layout.label}</option>
                                : <option key={index} className="form-control w-100" value={layout.constant}>{layout.label}</option>
                        )}
                    </select>
                </React.Fragment>
            :null }
        </div>
    );
};

export default LayoutView;