import {createSlice} from "@reduxjs/toolkit";
import {PAGE_SELECTIONS} from "../structures/archived.structures";

const selectedArchivePageSlice = createSlice({
    name: "fleetServiceReport",
    initialState: PAGE_SELECTIONS[0],
    reducers: {
        updateSelectedArchivePageSlice: (state, {payload}) => {
            return {
                ...state,
                ...payload,
            };
        },
    }
})

export const {updateSelectedArchivePageSlice} = selectedArchivePageSlice.actions;
export default selectedArchivePageSlice.reducer;
