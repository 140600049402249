import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import {useDispatch} from "react-redux";

import "../../../styles/materialise/materialize.css";

import Back from "../../common/Back";
import Loader from "../../common/Loader";
import TextInput from "../../form/TextInput";
import TextAreaInput from "../../form/TextAreaInput";
import CustomerSignatureModal from "../../modals/jobModals/CustomerSignatureModal";

import {isEmpty} from "../../../utils/helpers/app.helpers";
import {setModal} from "../../../utils/slices/modal.slice";
import {postAuthenticated} from "../../../utils/actions/post.actions";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";

const Jobs = (props) => {
    const action = props.action;

    const {job_id} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [init, setInit] = useState(false);
    const [checkingRego, setCheckingRego] = useState(false);

    const [view, setView] = useState("form");
    const [job, setJob] = useState({});
    const [jobs, setJobs] = useState([]);
    const [errors, setErrors] = useState({});

    const [HAS_SIGNATURE, setHasSignature] = useState(false);

    useEffect(() => {
        setInit(false);
        if(props.action == "view") {
            getJobs();
        }
        if(props.action == "edit") {
            getJobById();
        }
        if(props.action == "create") {
            getJobDefault();
        }
    }, [props.action]);

    function getJobDefault() {
        let url = API_ENDPOINT + "v1/Jobs/getDefaultJob";
        let params = {};

        postAuthenticated(url, params).then(results => {
            setJob(results.data);
            setView('form');
            setInit(true);
        });
    }

    function getJobs() {
        let url = API_ENDPOINT + "v1/Jobs/getJobs";
        let params = {};

        postAuthenticated(url, params).then(results => {
            setJobs(results.data);
            setInit(true);
        });
    }

    function getJobById() {
        let url = API_ENDPOINT + "v1/Jobs/getById";
        let params = {
            id: job_id
        };

        postAuthenticated(url, params).then(results => {
            let job = results.data;

            setJob(job);
            setHasSignature((job && job.content && job.content.signature && job.content.signature != "") ? true : false);
            setInit(true);
        });
    }

    function getJobByRegoNo() {
        setCheckingRego(true);

        let url = API_ENDPOINT + "v1/Jobs/getByRegoNo";
        let params = {
            rego_no: job.rego_no
        };

        postAuthenticated(url, params).then(results => {
            if(results.data) {
                let dataToRetrieve = Object.assign({}, {
                    content: {
                        ...job.content,
                        name: results.data.content.name,
                        email_address: results.data.content.email_address,
                        phone_no: results.data.content.phone_no,
                        company_name: results.data.content.company_name
                    }
                });
                let merged = {...job, ...dataToRetrieve};
                setJob(merged);
            }
            setCheckingRego(false);
        })
    }

    function createJob() {
        let url = API_ENDPOINT + "v1/Jobs/createJob";
        let params = {
            job: job
        };

        postAuthenticated(url, params).then(results => {
            if(results.data) {
                setView('success');
            }
        });
    }

    function updateJob() {
        let url = API_ENDPOINT + "v1/Jobs/updateJob";
        let params = {
            job: job
        };

        postAuthenticated(url, params).then(results => {
            navigate('/jobs');
        })
    }

    function validateForm() {
        let _errors = {};
        let order = []; //['rego_no', 'name', 'date', 'phone_no', 'email_address', 'company_name', 'description'];
        // if(action === "edit") {
        //     order.push('pickup_date', 'signature')
        // }

        order.forEach(key => {
            if(key === 'rego_no') {
                if(isEmpty(job[key])) _errors[key] = "Field cannot be empty";
            }else{
                if(isEmpty(job.content[key])) _errors[key] = "Field cannot be empty";
            }
        })

        setErrors(_errors);
        if(Object.keys(_errors).length === 0) {
            action === "edit" ? updateJob() : createJob();
        }else{
            let ele = document.getElementById(Object.keys(_errors)[0]);
            if(ele) {
                ele.focus();
            }
        }
    }

    function signJob() {
        let modalSettings = {
            config: {
                component: CustomerSignatureModal,
                props: {
                    setJob: setJob
                }
            },
            onClose: () => { getJobs(); }
        }

        dispatch(setModal(modalSettings));
    }

    function handleJobChange(event) {
        setJob({...job, [event.target.name]: event.target.value})
    }

    function handleJobContentChange(event) {
        setJob({
            ...job,
            content: {
                ...job.content,
                [event.target.name]: event.target.value
            }
        })
    }

    function hasSignature(job) {
        return job && job.content && job.content.signature && job.content.signature != "" ? true : false
    }

    return (
        init ?
            <div className="w-90 m-auto">
                <div id="use-materialise" className="w-90 m-auto mt-4 pb-5">
                    {action === "view" ?
                        <React.Fragment>
                            <h2 id="filters" className="lead fs-32 m-0">Jobs</h2>
                            <div className="d-flex mt-4">
                                <div className="col-6 text-secondary-emphasis fs-14 align-self-end">Results: {jobs ? jobs.length : 0}</div>
                                <div className="col-6 text-end">
                                    {jobs ?
                                        <button className="btn w-50" onClick={() => navigate('/jobs/create')}>
                                            Create Job
                                        </button>
                                    :null}
                                </div>
                            </div>

                            <div className="mt-3">
                                {jobs.length === 0 ? <div className="fst-italic lead fs-16">No jobs were found</div> :
                                    jobs.map((job, index) =>
                                        <div key={index} className="border border-1 rounded p-3 mb-3">
                                            <div className="d-flex">
                                                <div className="col-9">
                                                    <div className="lead fs-18">Rego No: <span className="fw-semibold">{job.rego_no}</span></div>
                                                    <div className="lead fs-18">Customer: <span className="fw-semibold">{job.content.name}</span></div>
                                                    <div className="lead fs-18">Company name: <span className="fw-semibold">{job.content.company_name}</span></div>
                                                    <div className="lead fs-18">Email address: <span className="fw-semibold">{job.content.email_address}</span></div>
                                                    <div className="lead fs-18">Phone No: <span className="fw-semibold">{job.content.phone_no}</span></div>
                                                    <div className="lead fs-18">Complaint: <span className="fw-semibold">{job.content.description}</span></div>
                                                    <div role="button" className="lead fs-18">Service Report No:
                                                        <Link to={`/service-report/view/${job.service_report_id}`} className="ps-1">
                                                            <span className="fw-semibold link-primary text-decoration-underline">{job.service_report_id}</span>
                                                        </Link>
                                                    </div>
                                                </div>

                                                <div className="col-3 text-end">
                                                    <span className={`lead fst-italic fs-18 ${hasSignature(job) ? 'text-success' : 'text-warning'} fw-semibold`}>{hasSignature(job) ? 'Signed' : 'Requires signature'}</span>
                                                </div>
                                            </div>

                                            <button className="btn btn-primary bg-primary mt-3" onClick={() => navigate(`/jobs/edit/${job.id}`)}>
                                                <i className={`fa fa-${hasSignature(job) ? 'eye' : 'edit'} fs-16`} />
                                                <span className="text-capitalize ms-1">{hasSignature(job) ? 'View' : 'Edit'}</span>
                                            </button>
                                        </div>
                                    )
                                }
                            </div>
                        </React.Fragment>
                    :null}

                    {(action === "create" || action === "edit") && job && job.content ?
                        <React.Fragment>
                            {view !== "success" ?
                                <div className="w-70 text-end m-auto">
                                    <button className="btn w-50" onClick={() => navigate('/jobs')}>
                                        View Jobs
                                    </button>
                                </div>
                            : null}

                            {view === "form" ?
                                <div className="w-70 m-auto bg-light p-4 mt-3 border border-1">
                                    <div className="d-flex ps-4">
                                        <div className="col-9 lead fs-42">Job Card</div>
                                        <div className="col-3 lead text-end text-uppercase fst-italic fs-16">{props.action}</div>
                                    </div>

                                    <div className="p-4 py-4">
                                        <div className="w-100 mb-3">
                                            <TextInput
                                                name="rego_no"
                                                label="Registration Plate Number:"
                                                value={job.rego_no}
                                                onChange={(e) => handleJobChange(e)}
                                                className={`mb-2 ps-2`}
                                                error={errors.rego_no}
                                                disabled={HAS_SIGNATURE}
                                            />

                                            {!HAS_SIGNATURE && action !== "edit" ?
                                                <button className="btn w-40" onClick={() => getJobByRegoNo()}>
                                                    {checkingRego ? <Loader /> : 'Search Rego'}
                                                </button>
                                            :null}
                                        </div>

                                        <div className="d-flex mt-2">
                                            <div className="w-60">
                                                <TextInput
                                                    name="name"
                                                    label="Name:"
                                                    value={job.content.name}
                                                    onChange={(e) => handleJobContentChange(e)}
                                                    className="ps-2"
                                                    error={errors.name}
                                                    disabled={HAS_SIGNATURE}
                                                />
                                            </div>

                                            <div className="w-35 ms-auto">
                                                <TextInput
                                                    type="date"
                                                    name="date"
                                                    label="Date:"
                                                    value={job.content.date}
                                                    onChange={(e) => handleJobContentChange(e)}
                                                    className="ps-2"
                                                    error={errors.date}
                                                    disabled={HAS_SIGNATURE}
                                                />
                                            </div>
                                        </div>

                                        <div className="mt-4">
                                            <TextInput
                                                name="phone_no"
                                                label="Phone:"
                                                value={job.content.phone_no}
                                                onChange={(e) => handleJobContentChange(e)}
                                                className="ps-2"
                                                error={errors.phone_no}
                                                disabled={HAS_SIGNATURE}
                                            />
                                        </div>

                                        <div className="mt-4">
                                            <TextInput
                                                name="email_address"
                                                label="Email Address:"
                                                value={job.content.email_address}
                                                onChange={(e) => handleJobContentChange(e)}
                                                className="ps-2"
                                                error={errors.email_address}
                                                disabled={HAS_SIGNATURE}
                                            />
                                        </div>

                                        <div className="mt-4">
                                            <div className="w-100">
                                                <TextInput
                                                    name="company_name"
                                                    label="Company Name:"
                                                    value={job.content.company_name}
                                                    onChange={(e) => handleJobContentChange(e)}
                                                    className="ps-2"
                                                    error={errors.company_name}
                                                    disabled={HAS_SIGNATURE}
                                                />
                                            </div>
                                        </div>

                                        <div className="mt-4">
                                            <TextAreaInput
                                                name="description"
                                                label="Customer complaint"
                                                value={job.content.description}
                                                onChange={(e) => handleJobContentChange(e)}
                                                rows="6"
                                                cols="60"
                                                className="border border-1 h-100 p-1"
                                                error={errors.description}
                                                disabled={HAS_SIGNATURE}
                                            />
                                        </div>

                                        {action === "edit" ?
                                            <React.Fragment>
                                                <div className="mt-4">
                                                    <TextInput
                                                        type="date"
                                                        name="pickup_date"
                                                        label="Pickup date:"
                                                        value={job.content.pickup_date}
                                                        onChange={(e) => handleJobContentChange(e)}
                                                        className="ps-2"
                                                        error={errors.pickup_date}
                                                        disabled={HAS_SIGNATURE}
                                                    />
                                                </div>

                                                <div className="mt-3">
                                                    <span role="button" className="my-1 lead link-primary" onClick={() => HAS_SIGNATURE ? null : signJob()}>
                                                        {HAS_SIGNATURE
                                                            ? <img className="border border-2 w-100 h-100" src={`${API_ENDPOINT}assets/images/signatures/${job.content.signature}`} />
                                                            : <div>{job.content.signature ? 'Signed' : <div>Sign here <i className=" ms-1 bi bi-pen" /></div>}</div>
                                                        }
                                                    </span>
                                                    {errors.signature ? <div className={`lead text-danger fs-6 mt-1`}>Please sign here</div> : null}
                                                </div>
                                            </React.Fragment>
                                        :null}

                                        {!HAS_SIGNATURE ?
                                            <div className="mt-4">
                                                <button className="btn btn-primary w-100" onClick={() => validateForm()}>
                                                    <i className={`fs-16 bi ${action === "edit" ? 'bi-arrow-clockwise' : 'bi-send'} me-1`} />
                                                    <span>{action === "edit" ? 'Update' : 'Submit'}</span>
                                                </button>
                                            </div>
                                        :null}
                                    </div>
                                </div>
                            :null}

                            {view === "success" && action === "create" ?
                                <div className="lead m-auto col-12 text-center">
                                    <div className="fs-42">Thank you for submitting our job card!</div>
                                    <div className="mt-1">Please click <span role="button" className="link-primary" onClick={() => getJobDefault()}>here</span> to reset the form</div>
                                </div>
                            :null}
                        </React.Fragment>
                    :null}

                    <Back />
                </div>
            </div>
        : <Loader />
    );
};

export default Jobs;