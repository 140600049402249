import React from 'react';
import "../../../styles/modals/serviceReportPartsModal.scss";
import * as serviceReportModalConstants from "../../../utils/constants/serviceReportModal.constants";
import {useDispatch, useSelector} from "react-redux";
import {setServiceReport} from "../../../utils/helpers/action.helpers";
import Draggable from "react-draggable";

const PartsModal = ({setIsModalOpen, partsList, updateServiceReport, selectedInputIndex}) => {
    const dispatch = useDispatch();
    const serviceReportDetails = useSelector(state => state.serviceReport);

    function handleSelectedPartDecision(part) {
        let _fields = {
            parts_table: Object.assign([], {
                ...serviceReportDetails.parts_table,
                [selectedInputIndex]: {...serviceReportDetails.parts_table[selectedInputIndex],
                    'desc': part.description,
                    'part': part.part_number,
                    'total_price': part.rrp,
                    'price': part.rrp,
                    'qty': 1
                },
            }),
            totalmprice: parseFloat(serviceReportDetails.totalmprice) + parseFloat(part.rrp),
            exgst: parseFloat(serviceReportDetails.exgst) + parseFloat(part.rrp)
        }

        dispatch(setServiceReport(_fields));
        updateServiceReport(_fields);

        setIsModalOpen(false);
    }

    function handleCancel() {
        setIsModalOpen(false);
    }

    return (
        <Draggable>
            <div id="modal" className="modal d-block" tabIndex="-1" role="dialog" aria-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
                <div className="modal-dialog modal-xl modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1>Parts - "{serviceReportDetails.parts_table[selectedInputIndex]['desc']}"</h1>
                        </div>

                        <div className="modal-body" style={{overflowY: "scroll", maxHeight: "500px"}}>
                            <table className="w-100 modal-table">
                                <thead>
                                    <tr>
                                        <th>{serviceReportModalConstants.PART_NUMBER_LABEL}</th>
                                        <th>{serviceReportModalConstants.DESCRIPTION_LABEL}</th>
                                        <th>{serviceReportModalConstants.STOCK_TOTAL_LABEL}</th>
                                        <th>{serviceReportModalConstants.SELECT_PART_LABEL}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {partsList.map(list => (
                                    <tr key={list.id}>
                                        <td className="col-2">{list.part_number}</td>
                                        <td className="col-6">{list.description}</td>
                                        <td className="col-2">{list.stock_total}</td>
                                        <td className="col-2">
                                            <button className="btn btn-warning" onTouchStart={() => handleSelectedPartDecision(list)} onClick={() => handleSelectedPartDecision(list)}>Select this part</button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-danger" onTouchStart={() => handleCancel()} onClick={() => handleCancel()}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </Draggable>
    );
};

export default PartsModal;
