import React, {useState} from 'react';
import * as serviceReportModalConstants from "../../../utils/constants/serviceReportModal.constants";
import TextInput from "../../form/TextInput";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";
import {postAuthenticated} from "../../../utils/actions/post.actions";

const CustomerModal = (props) => {
    const [customerDetails, setCustomerDetails] = useState({
        name: "",
        email: "",
        company_name: "",
        address: "",
        phone: "",
    });

    function handleChange(event) {
        setCustomerDetails({...customerDetails, [event.target.name]: event.target.value});
    }

    function createUser() {
        let url = API_ENDPOINT + 'v1/Users/createBasicUser';
        let params = {
            name: customerDetails.name,
            email: customerDetails.email,
            company_name: customerDetails.company_name,
            address: customerDetails.address,
            phone: customerDetails.phone,
        }

        postAuthenticated(url, params).then(r => {}).catch();
        props.setOpenCustomerModal(false);
    }

    return (
        <div className="modal d-block" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1>Create New Customer</h1>
                    </div>

                    <div className="modal-body" style={{overflowY: "scroll", maxHeight: "400px"}}>
                        <div className="">
                            <label className="">Name</label>
                            <TextInput
                                name="name"
                                value={customerDetails.name}
                                onChange={e => handleChange(e)}
                                className="w-100 form-control"
                            />

                            <label className="mt-3">Email Address</label>
                            <TextInput
                                name="email"
                                value={customerDetails.email}
                                onChange={e => handleChange(e)}
                                className="w-100 form-control"
                            />

                            <label className="mt-3">Company Name</label>
                            <TextInput
                                name="company_name"
                                value={customerDetails.company_name}
                                onChange={e => handleChange(e)}
                                className="w-100 form-control"
                            />

                            <label className="mt-3">Phone No</label>
                            <TextInput
                                name="phone"
                                value={customerDetails.phone}
                                onChange={e => handleChange(e)}
                                className="w-100 form-control"
                            />

                            <label className="mt-3">Address</label>
                            <TextInput
                                name="address"
                                value={customerDetails.address}
                                onChange={e => handleChange(e)}
                                className="w-100 form-control"
                            />
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button className="btn btn-primary" onClick={() => createUser()}>Create</button>
                        <button className="btn btn-danger" onClick={() => props.setOpenCustomerModal(false)}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomerModal;
