import {configureStore} from "@reduxjs/toolkit";

// Slices
import serviceReportReducer from "./utils/slices/serviceReport.slice";
import fleetServiceReportReducer from "./utils/slices/fleetServiceReport.slice";
import fieldsReducer from "./utils/slices/fields.slice";
import userProfileReducer from "./utils/slices/userProfile.slice";
import userPermissionReducer from "./utils/slices/userPermission.slice";
import selectedArchivePageReducer from "./utils/slices/selectedArchivePage.slice";
import historyTrackerSlice from "./utils/slices/historyTracker.slice";
import serviceReportLogReducer from "./utils/slices/serviceLogTracker.slice";
import trackerReducer from "./utils/slices/tracker.slice";
import modalSlice from "./utils/slices/modal.slice";
import toastsSlice from "./utils/slices/toasts.slice";

export const store = configureStore({
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
    reducer: {
        serviceReport: serviceReportReducer,
        fleetServiceReports: fleetServiceReportReducer,
        fields: fieldsReducer,
        userProfile: userProfileReducer,
        userPermissions: userPermissionReducer,
        selectedArchivePage: selectedArchivePageReducer,
        historyTracker: historyTrackerSlice,
        serviceLogTracker: serviceReportLogReducer,
        tracker: trackerReducer,
        modal: modalSlice,
        toasts: toastsSlice,
    },
})
