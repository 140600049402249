import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import "../../../styles/serviceReport/basicDetails.scss";
import "../../../styles/serviceReport/serviceStatus.scss";

import Back from "../../common/Back";
import PartsTable from "./PartsTable";
import FormHeader from "./FormHeader";
import Loader from "../../common/Loader";
import BasicDetails from "./BasicDetails";
import ServiceStatus from "./ServiceStatus";
import RevisionReport from "./RevisionReport";
import TempRefrigerant from "./TempRefrigerant";
import CalculationList from "./CalculationList";
import LayoutView from "../../common/LayoutView";
import ServiceChecklist from "./ServiceChecklist";
import WorkInstructions from "./WorkInstructions";
import TechInitialsTable from "./TechInitialsTable";

import {isEmpty} from "../../../utils/helpers/app.helpers";
import {setServiceReport} from "../../../utils/helpers/action.helpers";
import {postAuthenticated} from "../../../utils/actions/post.actions";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";

const ServiceReport = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    let { service_report_id } = useParams();
    let { service_report_log_id } = useParams();

    const sub = !isEmpty(location.state) ? location.state.sub : null
    const actionType = props.actionType;

    const userProfile = useSelector(state => state.userProfile);
    const userPermissions = useSelector(state => state.userPermissions);
    const serviceReportDetails = useSelector(state => state.serviceReport);

    const [init, setInit] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const is_user_super_admin = userProfile.role == 4;
    const currentLayoutView = userProfile.layout_view_constant;

    const stateRef = useRef();
    stateRef.current = serviceReportDetails;

    useEffect(() => {
        if(props.actionType === "create") {
            getDefaultServiceReport();
        }
    }, [actionType]);

    useEffect(() => {
        if(!isEmpty(service_report_id) && (props.actionType === "view" || props.actionType === "edit")) {
            getServiceReportById()
        }
    }, [actionType, service_report_id]);

    useEffect(() => {
        if(!isEmpty(service_report_log_id) && props.actionType === "revision") {
            getServiceReportLogById();
        }
    }, [actionType, service_report_log_id]);

    useEffect(() => {
        setIsEditable(
            (serviceReportDetails.service_status == 5 && !is_user_super_admin) ||
            actionType === "view" || actionType === "revision" ||
            (!isEmpty(serviceReportDetails.signature) && (userProfile.role != 1 && userProfile.role != 4))
        );
    }, [serviceReportDetails.service_status, serviceReportDetails.signature, actionType, userProfile.role]);

    useEffect(() => {
        return () => {
            if(!isEmpty(stateRef.current.id) && (actionType !== "view" && actionType !== "revision")) {
                saveServiceReportLog();
            }
        }
    }, []);

    function getDefaultServiceReport() {
        let url = API_ENDPOINT + 'v1/ServiceReports/getDefaultServiceReport';
        let params = {}

        postAuthenticated(url, params).then(results => {
            dispatch(setServiceReport(results.data));
            setInit(true);
        });
    }

    function getServiceReportById() {
        let url = API_ENDPOINT + 'v1/ServiceReports/getServiceReportById';
        let params = {
            id: service_report_id
        }

        postAuthenticated(url, params).then(results => {
            if(results.data.id == 0) {
                navigate('/history');
            }else{
                dispatch(setServiceReport(results.data));
                setInit(true);
            }
        });
    }

    function getServiceReportLogById() {
        let url = API_ENDPOINT + 'v1/ServiceReports/getServiceReportLogById';
        let params = {
            service_report_log_id: service_report_log_id
        }

        postAuthenticated(url, params).then(results => {
            dispatch(setServiceReport(results.data.content));
            setInit(true);
        });
    }

    function saveServiceReport(_fields) {
        if(actionType !== "view" && actionType !== "revision") {
            let url = API_ENDPOINT + 'v1/ServiceReports/saveServiceReport';
            let params = {
                service_report_id: serviceReportDetails.id,
                fields: _fields
            }

            postAuthenticated(url, params).then(results => {});
        }
    }

    function saveServiceReportLog() {
        let url = API_ENDPOINT + 'v1/ServiceReports/saveServiceReportLog';
        let params = {
            service_report_id: stateRef.current.id,
            user_id: userProfile.id,
        }

        postAuthenticated(url, params).then(results => {});
    }

    function canViewSection(constant) {
        return userPermissions.service_report[constant] == "true" || userPermissions.service_report[constant] == true;
    }

    return (
        <div className={`m-auto ${currentLayoutView === "mobile_view" ? 'col-10' : 'col-11'}`}>
            <LayoutView />

            {init ?
                actionType === "revision" ?
                    <RevisionReport />
                :
                <div className={`border border-dark p-3 mb-3 p-0 mt-4`}>
                    <form id="service_report" encType="multipart/form-data">
                        <FormHeader
                            actionType={actionType}
                            currentLayoutView={currentLayoutView}
                            sub={sub}
                        />

                        {canViewSection('basic_details') &&
                            <BasicDetails
                                updateServiceReport={saveServiceReport}
                                actionType={actionType}
                                currentLayoutView={currentLayoutView}
                                isEditable={isEditable}
                            />
                        }

                        {currentLayoutView === "full_width_view" &&
                            <React.Fragment>
                                <div className={`d-flex ms-1 me-1`}>
                                    <div className={`col-6 me-1`}>
                                        {canViewSection('service_checklist') &&
                                            <ServiceChecklist
                                                updateServiceReport={saveServiceReport}
                                                actionType={actionType}
                                                isEditable={isEditable}
                                            />
                                        }

                                        {canViewSection('temp_refrigerant') &&
                                            <TempRefrigerant
                                                updateServiceReport={saveServiceReport}
                                                actionType={actionType}
                                                isEditable={isEditable}
                                            />
                                        }
                                    </div>

                                    <div className={`d-flex flex-column col-xl-6 ms-xl-1`}>
                                        {canViewSection('work_instructions') &&
                                            <WorkInstructions
                                                updateServiceReport={saveServiceReport}
                                                actionType={actionType}
                                                currentLayoutView={currentLayoutView}
                                                isEditable={isEditable}
                                            />
                                        }
                                    </div>
                                </div>

                                {canViewSection('parts_table') &&
                                    <PartsTable
                                        updateServiceReport={saveServiceReport}
                                        actionType={actionType}
                                        currentLayoutView={currentLayoutView}
                                        isEditable={isEditable}
                                    />
                                }

                                <div className={`d-flex ms-1 me-1 mt-3`}>
                                    {canViewSection('tech_initials') &&
                                        <TechInitialsTable
                                            updateServiceReport={saveServiceReport}
                                            actionType={actionType}
                                            currentLayoutView={currentLayoutView}
                                            isEditable={isEditable}
                                        />
                                    }

                                    {canViewSection('calculation_list') &&
                                        <CalculationList
                                            updateServiceReport={saveServiceReport}
                                            actionType={actionType}
                                            currentLayoutView={currentLayoutView}
                                            isEditable={isEditable}
                                        />
                                    }
                                </div>

                                {canViewSection('service_status') &&
                                    <ServiceStatus
                                        updateServiceReport={saveServiceReport}
                                        getServiceReportById={getServiceReportById}
                                        actionType={actionType}
                                        currentLayoutView={currentLayoutView}
                                        isEditable={isEditable}
                                    />
                                }
                            </React.Fragment>
                        }

                        {currentLayoutView !== "full_width_view" &&
                            <React.Fragment>
                                <div className={`d-lg-block mb-3 ms-1 me-1`}>
                                    <div className={`col-lg-12 mb-3 ms-xl-1`}>
                                        {canViewSection('work_instructions') &&
                                            <WorkInstructions
                                                updateServiceReport={saveServiceReport}
                                                actionType={actionType}
                                                currentLayoutView={currentLayoutView}
                                                isEditable={isEditable}
                                            />
                                        }
                                    </div>
                                    <div className={`col-12 me-xl-1 mb-3 mt-3`}>
                                        {canViewSection('service_checklist') &&
                                            <ServiceChecklist
                                                updateServiceReport={saveServiceReport}
                                                actionType={actionType}
                                                isEditable={isEditable}
                                            />
                                        }

                                        {canViewSection('temp_refrigerant') &&
                                            <TempRefrigerant
                                                updateServiceReport={saveServiceReport}
                                                actionType={actionType}
                                                isEditable={isEditable}
                                            />
                                        }
                                    </div>

                                    {canViewSection('parts_table') &&
                                        <PartsTable
                                            updateServiceReport={saveServiceReport}
                                            actionType={actionType}
                                            currentLayoutView={currentLayoutView}
                                            isEditable={isEditable}
                                        />
                                    }
                                    <div className={`d-block mb-3 ms-1 me-1 mt-3`}>
                                        {canViewSection('tech_initials') &&
                                            <TechInitialsTable
                                                updateServiceReport={saveServiceReport}
                                                actionType={actionType}
                                                currentLayoutView={currentLayoutView}
                                                isEditable={isEditable}
                                            />
                                        }

                                        {canViewSection('calculation_list') &&
                                            <CalculationList
                                                updateServiceReport={saveServiceReport}
                                                actionType={actionType}
                                                currentLayoutView={currentLayoutView}
                                                isEditable={isEditable}
                                            />
                                        }
                                    </div>

                                    {canViewSection('service_status') &&
                                        <ServiceStatus
                                            updateServiceReport={saveServiceReport}
                                            getServiceReportById={getServiceReportById}
                                            actionType={actionType}
                                            currentLayoutView={currentLayoutView}
                                            isEditable={isEditable}
                                        />
                                    }
                                </div>
                            </React.Fragment>
                        }

                        <Back
                            actionType={actionType}
                            isEditable={isEditable}
                            label="Save & Back"
                        />
                    </form>
                </div>
                :
                <Loader />
            }
        </div>
    );
};

export default ServiceReport;
