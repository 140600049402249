import React from 'react';
import {useSelector} from "react-redux";

import logo from "../../../assets/images/logo.jpg";

const FormHeader = (props) => {
    const serviceReportDetails = useSelector(state => state.serviceReport);
    const layoutView = props.currentLayoutView;

    return (
        layoutView !== "mobile_view" ?
            <div className="d-flex m-auto">
                <div className="col-3">
                    <img src={logo} alt="" width="100%" />
                </div>

                <div className="col-3 text-center">
                    <b className="reportHead">ABN: 97 169 797 353</b><br/>
                    <img src="https://acaps-parts-service.com/images/arc.png" alt="" width="30%" />
                    <b> AU35472</b>
                </div>

                <div className="col-3 m-auto text-left">
                    <b className="w-75 fs-16">15/11-19 Waler Crescent Smeaton Grange info@acaps.net.au | (02)4624-2800</b>
                </div>
                <div className="col-3 m-auto text-center">
                    <p className="fs-4 fw-bold">
                        <span className="">{serviceReportDetails.parent_id != null && props.sub != null && `SUB ${props.sub+1} - `}</span>
                        SERVICE REPORT: {`${serviceReportDetails.srno}`}
                    </p>
                </div>
            </div>
            :
            <div>
                <div className="col-12">
                    <img src={logo} alt="" width="100%" />
                </div>

                <div className="col-12 m-auto text-center mt-4">
                    <p className="fs-4 fw-bold">
                        <span className="fw-lighter lead">{serviceReportDetails.parent_id != null && 'SUB'} - </span>
                        SERVICE REPORT: {`${serviceReportDetails.srno}`}
                    </p>
                </div>
            </div>
    );
};

export default FormHeader;
