import React, {useEffect, useState} from 'react';

import "../../styles/modals/modal.custom.css";
import TextInput from "../form/TextInput";
import {postAuthenticated} from "../../utils/actions/post.actions";
import {API_ENDPOINT} from "../../utils/constants/app.constants";

const FiltersModal = (props) => {
    const [serviceStatuses, setServiceStatuses] = useState([]);
    const [filters, setFilters] = useState(props.appliedFilters || {});

    useEffect(() => {
        getServiceStatuses();
    }, []);

    function getServiceStatuses() {
        let url = API_ENDPOINT + "v1/ReportColors/getReportColors";
        let params = {};

        postAuthenticated(url, params).then(results => {
            setServiceStatuses(results.data);
        })
    }

    function handleFilters(event) {
        setFilters({...filters, [event.target.name]: event.target.value});
    }

    return (
        <div className="modal d-block bg-dark bg-opacity-50" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog mw-55 modal-dialog-scrollable modal-entrance">
                <div className="modal-content w-100 h-85 m-auto">
                    <div className="modal-header d-block p-3">
                        <div className="w-95 m-auto">
                            <div className="d-flex">
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Add Filters</h3>
                                <button onClick={() => props.close()}
                                        className="border border-0 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                    <i className="bi bi-x-lg text-secondary-emphasis" />
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className={`modal-body bg-white w-75 m-auto`} style={{overflowY: "scroll"}}>
                        <div>
                            <div className="d-flex">
                                <div className="col-5">
                                    <label className="lead text-secondary-emphasis fs-16">From date:</label>
                                    <input
                                        type="date"
                                        name="from_date"
                                        value={filters.from_date}
                                        onChange={(e) => handleFilters(e)}
                                        className="form-control w-100"
                                    />
                                </div>

                                <div className="col-5 ms-auto">
                                    <label className="lead text-secondary-emphasis fs-16">To date:</label>
                                    <input
                                        type="date"
                                        name="to_date"
                                        value={filters.to_date}
                                        onChange={(e) => handleFilters(e)}
                                        className="form-control w-100"
                                    />
                                </div>
                            </div>

                            <div className="mt-3 d-flex">
                                <div className="col-8">
                                    <TextInput
                                        label="Customer:"
                                        name="customer"
                                        value={filters.customer}
                                        onChange={(e) => handleFilters(e)}
                                        className=""
                                    />
                                </div>

                                <div className="col-3 ms-auto">
                                    <TextInput
                                        label="Fleet:"
                                        name="fleet"
                                        value={filters.fleet}
                                        onChange={(e) => handleFilters(e)}
                                        className=""
                                    />
                                </div>
                            </div>

                            <div className="mt-3">
                                <label className="lead text-secondary-emphasis fs-16">Service Status:</label>
                                <div>
                                    <select className="form-control" name="service_status" value={filters.service_status} onChange={(e) => handleFilters(e)}>
                                        <option value={null}>All</option>
                                        {serviceStatuses.map(service_status =>
                                            <option className="fs-14 text-secondary" value={service_status.id} defaultValue={filters.service_status}>{service_status.report_status}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer justify-content-start items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                        <button
                            className="btn btn-primary text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            onClick={() => props.onClose(filters)}>
                            Apply Filters
                        </button>

                        <button
                            className="ms-2 btn text-secondary-emphasis border border-secondary-subtle bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            onClick={() => props.close()}>Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FiltersModal;