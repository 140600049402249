import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import ReactQuill from "react-quill";

import '../../../styles/serviceReport/workPerformed.scss'
import * as serviceReportConstants from "../../../utils/constants/serviceReport.constants";

import 'react-quill/dist/quill.snow.css';
import TextAreaInput from "../../form/TextAreaInput";
import {setServiceReport} from "../../../utils/helpers/action.helpers";

const WorkPerformed = (props) => {
    const dispatch = useDispatch();
    const serviceReportDetails = useSelector(state => state.serviceReport);

    function handleChange(event) {
        let _fields = {
            [event.target.name]: event.target.value
        };

        dispatch(setServiceReport(_fields));
        props.updateServiceReport(_fields);
    }

    function handleQuillChange(event) {
        let _fields = {
            "workperformed": event
        };

        dispatch(setServiceReport(_fields));
        props.updateServiceReport(_fields);
    }

    function toggleNotes(event) {
        let value = event.target.value == 0 ? 1 : 0;
        let fields = {
            hide_notes: value
        }

        dispatch(setServiceReport(fields));
        props.updateServiceReport(fields);
    }

    return (
        <React.Fragment>
            <span className="mt-3 fw-bold">{serviceReportConstants.WORK_PERFORMED_LABEL}</span>
            <div className={`h-90 ${props.currentLayoutView !== "full_width_view" ? 'mb-3' : ''}`}>
                <ReactQuill
                    theme="snow"
                    value={serviceReportDetails.workperformed}
                    onChange={(e, delta, source) => { if (source === 'user') {handleQuillChange(e)} }}
                    className={`h-90 quill-dark-border increase-height`}
                    readOnly={props.isEditable}
                />
            </div>

            <div className="mt-2 fw-bold">
                <div className={`form-check form-switch mb-1`}>
                    <input type="checkbox" role="switch" className="form-check-input align-middle"
                           name="hide_notes" value={serviceReportDetails.hide_notes}
                           checked={serviceReportDetails.hide_notes == "true" || serviceReportDetails.hide_notes == true}
                           onChange={(e) => toggleNotes(e)}
                           disabled={props.isEditable} />
                    NOTES
                </div>
            </div>

            <div className="h-25">
                <TextAreaInput
                    name="notes"
                    value={serviceReportDetails.notes}
                    onChange={(e) => handleChange(e)}
                    className="w-100 h-100 rounded-0 border border-dark bg-white"
                    style={{ minHeight: `${props.currentLayoutView !== "full_width_view" ? '130px' : '0px'}`}}
                    disabled={props.isEditable}
                />
            </div>
        </React.Fragment>
    );
};

export default WorkPerformed;
