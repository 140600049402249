import React, {useState, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import ReactToPrint from "react-to-print";

import "../../../styles/printServiceReport/printServiceReport.css";
import logo from "../../../assets/images/logo.jpg";

import {isEmpty, isObjectEmpty} from "../../../utils/helpers/app.helpers";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";
import {postAuthenticated} from "../../../utils/actions/post.actions";
import {setServiceReport} from "../../../utils/helpers/action.helpers";

import * as serviceReportConstants from "../../../utils/constants/serviceReport.constants";
import { PRINT_PART_DETAILS_TABLE, SERVICE_CHECKLIST_MAINTENANCE, TECH_INITIALS_TABLE, WORK_INSTRUCTIONS_CHECKLIST} from "../../../utils/structures/serviceReport.structures";
import {
    AMBIENT_TEMP_LABEL,
    CALL_OUT_QUOTED_PRICE_LABEL,
    RECHARGED_LABEL,
    RECOVERED_LABEL,
    REFRIGERANT_LABEL,
    SERVICE_CHECKLIST_MAINTENANCE_DESCRIPTION_LABEL, SUB_TOTAL_EXT_GST_LABEL,
    SUPPLY_AIR_TEMP_LABEL,
    TOTAL_LABOUR_COSTS_LABEL,
    TOTAL_MATERIAL_PRICE_LABEL,
    WORK_INSTRUCTIONS_CUSTOMER_COMPLAINT_LABEL,
    WORK_PERFORMED_LABEL, WORKSHOP_SUPERVISOR_SIGNATURE_LABEL
} from "../../../utils/constants/serviceReport.constants";
import * as serviceReportConstant from "../../../utils/constants/serviceReport.constants";
import Back from "../../common/Back";
import Loader from "../../common/Loader";


const PrintServiceReport = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const componentRef = useRef();

    let { service_report_id } = useParams();
    const [init, setInit] = useState(false);
    const serviceReportDetails = useSelector(state => state.serviceReport);
    const [totalTimeSpent, setTotalTimeSpent] = useState(0);
    const [hideUnusedPartRows, setHideUnusedPartRows] = useState(false);

    useEffect(() => {
        if(!isEmpty(service_report_id)) {
            getServiceReportById();
        }else{
            //return navigate('/history')
        }
    }, [service_report_id])

    useEffect(() => {
        if(init) {
            calculateTotalTimeSpent(serviceReportDetails);
        }
    }, [init])

    function getServiceReportById() {
        setInit(false);
        let url = API_ENDPOINT + 'v1/ServiceReports/getServiceReportById';
        let params = {
            id: service_report_id
        }

        postAuthenticated(url, params).then(results => {
            dispatch(setServiceReport(results.data));
            setInit(true);
        });
    }

    function renderTechTR(start, end) {
        let tr = [];
        for (let i=start; i<end; i++) {
            tr.push(
                <tr key={i+1}>{renderTechTD(i)}</tr>
            )
        }
        return tr;
    }

    function renderTechTD(index) {
        let td = [];
        for (let i=1; i<TECH_INITIALS_TABLE.length+1; i++) {
            let techList = TECH_INITIALS_TABLE[i-1];
            td.push(
                <td key={i}>
                    <div style={{minHeight: "15px"}}>{serviceReportDetails.tech_initials_table[index][techList.name]}</div>
                </td>
            );
        }
        return td;
    }

    function renderTR() {
        let tr = [];
        serviceReportDetails.parts_table.map((row, index) =>
            row.has_value ?
                tr.push(
                    <tr key={index}>{renderTD(row.index)}</tr>
                )
                :null);


        serviceReportDetails.parts_table.map((row, index) =>
            !(row.has_value) && !hideUnusedPartRows ?
                tr.push(
                    <tr key={index}>{renderTD(row.index)}</tr>
                )
                :null);

        return tr;
    }

    function renderTD(index) {
        let td = [];
        for (let i=1; i<PRINT_PART_DETAILS_TABLE.length+1; i++) {
            let partList = PRINT_PART_DETAILS_TABLE[i-1];
            td.push(
                <React.Fragment key={i}>
                    <td style={{width: `${partList.width}`}} >
                        <div style={{minHeight: "10px"}}>
                            {!isEmpty(serviceReportDetails.parts_table[index][partList.object]) ?
                                partList.object === "total_price" || partList.object === "price" ? `$${parseFloat(serviceReportDetails.parts_table[index][partList.object]).toFixed(2)}`
                                    : serviceReportDetails.parts_table[index][partList.object]
                                : ''
                            }
                        </div>
                    </td>
                </React.Fragment>
            );
        }
        return td;
    }

    function calculateTotalTimeSpent(_serviceReportDetails) {
        setTotalTimeSpent(0);
        let totalTime = 0;
        for(let i=0; i<12; i++) {
            if(!isEmpty(_serviceReportDetails.tech_initials_table[i].time_spent)) {
                let parsedValue = parseFloat(_serviceReportDetails.tech_initials_table[i].time_spent);
                totalTime += parsedValue;
            }
        }
        if(!isNaN(totalTime)) {
            setTotalTimeSpent(totalTime);
        }
    }

    return (
        <React.Fragment>
            <div className="border border-1 border-top-0 border-start-0 border-end-0 rounded-0 w-100 m-auto p-3 bg-light">
                <div className="w-70 m-auto">
                    <div className="form-check form-switch mb-1">
                        <input type="checkbox" role="switch" className="form-check-input align-middle"
                               value={hideUnusedPartRows}
                               checked={hideUnusedPartRows}
                               onChange={(e) => setHideUnusedPartRows(!hideUnusedPartRows)}/>
                        <span className="form-check-label">Hide unused part rows</span>
                    </div>

                    <hr />

                    <div role="button" className="mt-3">
                        <ReactToPrint
                            trigger={() =>
                                <div>
                                    <button className="btn btn-warning rounded-0 py-2 px-2 w-100">
                                        <i className="fa fa-print me-2" />Print this report</button>
                                </div>
                            }
                            content={() => componentRef.current}
                        />
                    </div>
                </div>
            </div>

            <div ref={componentRef} className="m-auto col-12 px-2 h-100 w-100" style={{fontSize: "10px"}}>
                {!isObjectEmpty(serviceReportDetails) && init ?
                    <React.Fragment>
                        <table className="report-container table table-borderless">
                            <thead className="report-header">
                                <tr>
                                    <th className="report-header-cell p-0">
                                        <div className="header-info">
                                            <div className="d-flex m-auto py-1">
                                                <div style={{width: "10%"}}>
                                                    <img src={logo} alt="" width={120} style={{position: "relative", top: "7px"}} />
                                                </div>

                                                <div className="col-3 text-center">
                                                    <b className="reportHead ms-5" style={{position: "relative", top: "2px"}}>ABN: 97 169 797 353</b><br/>
                                                    <img className="ms-5" src="https://acaps-parts-service.com/images/arc.png" alt="" width="30%" style={{position: "relative", top: "2px"}} />
                                                    <b className="mt-3" style={{position: "relative", top: "5px"}}> AU35472</b>
                                                </div>

                                                <div className="col-4 m-auto text-left">
                                                    <b className="w-100">15/11-19 Waler Crescent Smeaton Grange info@acaps.net.au | (02)4624-2800</b>
                                                </div>
                                                <div className="col-3 m-auto text-end" style={{position: "relative", top: "8px"}}>
                                                    <p className="fw-bold" style={{fontSize: "14px"}}>
                                                        SERVICE REPORT: {serviceReportDetails.srno}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>

                            <tfoot className="report-footer">
                                <tr className="border border-0">
                                    <td className="report-footer-cell border border-0">
                                        <div className="footer-info">
                                            <div className={"page-footer"}></div>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>

                            <tbody className="report-content border-top-0">
                                <tr>
                                    <td className="report-content-cell p-0">
                                        <div className="main">
                                            <div id="print-body">
                                                <div className="border border-dark border-top-0">
                                                    <div className="d-flex w-100">
                                                        <div className="col-4 border border-dark border-start-0 border-top-0">
                                                            <label><span className="fw-bold ps-1">CUSTOMER:</span> {serviceReportDetails.customer}</label>
                                                        </div>

                                                        <div className="col-4">
                                                            <div className="border border-dark border-top-0 border-start-0 border-end-0 p-1">
                                                                <span className="fw-bold">START DATE:</span> {serviceReportDetails.start_date}</div>
                                                            <div className="d-flex border border-dark border-top-0 border-start-0 border-end-0 p-1">
                                                                <div className="col-6"><span className="fw-bold">REGO NO:</span> {serviceReportDetails.rego_no}</div>
                                                                <div className="col-6 text-end"><span className="fw-bold">FLEET NO:</span> {serviceReportDetails.fleet_no}</div>
                                                            </div>
                                                        </div>

                                                        <div className="col-4">
                                                            <div className="border border-dark border-top-0 border-end-0 p-1">
                                                                <span className="fw-bold">FINISH DATE:</span> {serviceReportDetails.end_date}</div>
                                                            <div className="border border-dark border-top-0 border-end-0 p-1">
                                                                <span className="fw-bold">KM/HRS:</span> {serviceReportDetails.distance_covered}</div>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex w-100 ">
                                                        <div className="col-4 border border-dark border-start-0 border-top-0 border-bottom-0">
                                                            <label><span className="fw-bold ps-1">ADDRESS:</span> {serviceReportDetails.address}</label>
                                                        </div>

                                                        <div className="col-4">
                                                            <div className="border border-dark border-top-0 border-start-0 border-end-0 p-1">
                                                                <span className="fw-bold">P/O NO:</span> {serviceReportDetails.po_no} </div>
                                                            <div className="border border-dark border-top-0 border-bottom-0 border-start-0 border-end-0 p-1">
                                                                <span className="fw-bold">UNIT MODEL:</span> {serviceReportDetails.unit_model}</div>
                                                        </div>

                                                        <div className="col-4">
                                                            <div className="border border-dark border-top-0 border-end-0 p-1">
                                                                <span className="fw-bold">W/O NO:</span> {serviceReportDetails.wo_no}</div>
                                                            <div className="border border-dark border-top-0 border-bottom-0 border-end-0 p-1">
                                                                <span className="fw-bold">SR COMPLETE:</span>
                                                                <input type="checkbox" className="ms-2 disabled" checked={serviceReportDetails.sr === "yes"} style={{verticalAlign: "sub"}} /></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-flex">
                                                    <div className="col-6 border border-dark border-top-0 border-bottom-0">
                                                        <div className="fw-bold p-1">{SERVICE_CHECKLIST_MAINTENANCE_DESCRIPTION_LABEL}</div>
                                                        <div>
                                                            {SERVICE_CHECKLIST_MAINTENANCE.map(list =>
                                                                <div key={list.constant} className={`d-flex border border-dark border-start-0 border-end-0 ${list.constant === serviceReportConstant.FUNCTION_TEST_AC_COOL_HEAT_MODE_CONSTANT ? '' : 'border-bottom-0'}`}>
                                                                    <div className="col-11 m-1">
                                                                        <span>{list.label}</span>
                                                                    </div>

                                                                    <div className="col-1 text-center">
                                                                        <input type="checkbox" className="disabled mt-1 mb-1 me-1" name={list.constant} value={list.constant} checked={serviceReportDetails.service_checklist_maintenance[list.constant] == true || serviceReportDetails.service_checklist_maintenance[list.constant] == 'true'} />
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>

                                                        <div className="border border-dark border-top-0 border-end-0 border-bottom-0 border-start-0">
                                                            <div className="d-flex text-center">
                                                                <div className="col-6 border border-dark border-top-0 border-start-0">
                                                                    <div className="border border-dark border-top-0 border-end-0 border-start-0"><b>{AMBIENT_TEMP_LABEL}</b></div>
                                                                    <div style={{minHeight: "1rem"}}>{serviceReportDetails.ambTemprature}</div>
                                                                </div>

                                                                <div className="col-6 border border-dark border-top-0 border-start-0 border-end-0">
                                                                    <div className="border border-dark border-top-0 border-end-0 border-start-0"><b>{SUPPLY_AIR_TEMP_LABEL}</b></div>
                                                                    <div style={{minHeight: "1rem"}}>{serviceReportDetails.supplyTemprature}</div>
                                                                </div>
                                                            </div>

                                                            <div className="d-flex text-center">
                                                                <div className="col-4">
                                                                    <div className="border border-dark border-top-0 border-start-0"><b>{REFRIGERANT_LABEL}</b></div>
                                                                    <div style={{minHeight: "1rem"}} className="border border-dark border-top-0 border-bottom-0 border-start-0">{serviceReportDetails.refrigerant}</div>
                                                                </div>

                                                                <div className="col-4">
                                                                    <div className="border border-dark border-top-0 border-start-0"><b>{RECOVERED_LABEL}</b></div>
                                                                    <div style={{minHeight: "1rem"}} className="border border-dark border-top-0 border-bottom-0 border-start-0">{serviceReportDetails.recovered}</div>
                                                                </div>

                                                                <div className="col-4">
                                                                    <div className="border border-dark border-top-0 border-start-0 border-end-0"><b>{RECHARGED_LABEL}</b></div>
                                                                    <div style={{minHeight: "1rem"}}>{serviceReportDetails.recharged}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 border border-dark border-start-0 border-top-0 border-bottom-0">
                                                        <div>
                                                            <div className="border border-dark border-top-0 border-start-0 border-bottom-0 border-end-0"><div className="fw-bold p-1">{WORK_INSTRUCTIONS_CUSTOMER_COMPLAINT_LABEL}</div></div>
                                                            <div className="d-flex flex-wrap">
                                                                {WORK_INSTRUCTIONS_CHECKLIST.map((list, index) =>
                                                                    <div key={list.constant} className={`col-6 border border-dark border-start-0 ${index % 2 !== 0 && 'border-end-0'} ${list.constant === serviceReportConstant.CHANGED_FILTER_DRIER_CONSTANT ? '' : 'border-bottom-0'} p-0`}>
                                                                        <input type="checkbox" className="ms-1 me-1 align-middle disabled mt-1 mb-1" name={list.constant} value={list.constant} checked={serviceReportDetails.work_instructions[list.constant]} />
                                                                        <span className="m-1">{list.label}</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="border border-dark border-start-0 border-end-0"><div className="fw-bold p-1">{WORK_PERFORMED_LABEL}</div></div>
                                                            <div className="work-performed-print" style={{minHeight: "280px", maxheight: "300px", overflow: "hidden", clear: "both"}} dangerouslySetInnerHTML={{__html: serviceReportDetails.workperformed}} />
                                                        </div>

                                                        {serviceReportDetails.hide_notes == 1 &&
                                                            <div>
                                                                <div className="border border-dark border-start-0 border-end-0"><div className="fw-bold p-1">NOTES</div></div>
                                                                <div className="p-1" style={{overflow: "hidden", clear: "both"}} dangerouslySetInnerHTML={{__html: serviceReportDetails.notes}} />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                                <table className="print-service-report-table border-top-0 text-center w-100">
                                                    <thead className="p-0">
                                                    <tr>
                                                        <th>{serviceReportConstants.QTY_LABEL}</th>
                                                        <th>{serviceReportConstants.PART_NO_LABEL}</th>
                                                        <th>{serviceReportConstants.DESCRIPTION_LABEL}</th>
                                                        <th>{serviceReportConstants.LOCATION_LABEL}</th>
                                                        <th>{serviceReportConstants.DISCOUNT_LABEL}</th>
                                                        <th>{serviceReportConstants.PRICE_EA_LABEL}</th>
                                                        <th>{serviceReportConstants.TOTAL_PRICE_LABEL}</th>
                                                    </tr>
                                                    </thead>

                                                    <tbody>
                                                        {renderTR()}
                                                    </tbody>
                                                </table>

                                                <div className="d-flex border border-dark border-top-0 border-bottom-0">
                                                    <div className="col-4 border border-dark border-bottom-0 border-start-0 border-top-0 border-end-0">
                                                        <table className="w-100 text-center">
                                                            <thead>
                                                            <tr>
                                                                <th className="w-25">{serviceReportConstants.TECH_INITIALS_LABEL}</th>
                                                                <th className="w-25">{serviceReportConstants.DATE_LABEL}</th>
                                                                <th className="w-25">{serviceReportConstants.HOURS_LABEL}</th>
                                                            </tr>
                                                            </thead>

                                                            <tbody>
                                                                {renderTechTR(0, 6)}
                                                            </tbody>
                                                        </table>
                                                        <p className="p-0 px-1 fs-6 text-end">Total time spent:</p>
                                                    </div>

                                                    <div className="col-4 border border-dark border-top-0 border-bottom-0">
                                                        <table className="w-100 text-center border-start-0">
                                                            <thead>
                                                            <tr>
                                                                <th className="w-25">{serviceReportConstants.TECH_INITIALS_LABEL}</th>
                                                                <th className="w-25">{serviceReportConstants.DATE_LABEL}</th>
                                                                <th className="w-25">{serviceReportConstants.HOURS_LABEL}</th>
                                                            </tr>
                                                            </thead>

                                                            <tbody>
                                                                {renderTechTR(6, 12)}
                                                            </tbody>
                                                        </table>

                                                        <p className="p-0 px-1 fs-6"><span className="fw-normal">{totalTimeSpent}</span></p>
                                                    </div>

                                                    <div className="col-4">
                                                        <div className="d-flex">
                                                            <div className="col-6 p-1 text-end">{TOTAL_MATERIAL_PRICE_LABEL}</div>
                                                            <div className="col-6 p-1 border border-dark border-top-0 border-end-0 border-bottom-0 align-middle">
                                                                <span className="align-self-center">${parseFloat(serviceReportDetails.totalmprice).toFixed(2)}</span>
                                                            </div>
                                                        </div>

                                                        <div className="d-flex">
                                                            <div className="col-6 p-1 border border-dark border-end-0 border-bottom-0 border-start-0 text-end">{CALL_OUT_QUOTED_PRICE_LABEL}</div>
                                                            <div className="col-6 p-1 border border-dark border-end-0 border-bottom-0 align-middle">
                                                                <span className="align-self-center">${parseFloat(serviceReportDetails.quotedprice).toFixed(2)}</span>
                                                            </div>
                                                        </div>

                                                        <div className="d-flex">
                                                            <div className="col-6 p-1 border border-dark border-end-0 border-start-0 text-end">{TOTAL_LABOUR_COSTS_LABEL}</div>
                                                            <div className="col-6 p-1 border border-dark border-end-0">
                                                                <span className="align-self-center">${parseFloat(serviceReportDetails.labourcost).toFixed(2)}</span>
                                                            </div>
                                                        </div>

                                                        <div className="d-flex">
                                                            <div className="col-6 p-1 border border-dark border-end-0 border-start-0 text-end text-end border-top-0">{SUB_TOTAL_EXT_GST_LABEL}</div>
                                                            <div className="col-6 p-1 border border-dark border-end-0 border-top-0">
                                                                <span className="align-self-center">${parseFloat(serviceReportDetails.exgst).toFixed(2)}</span>
                                                            </div>
                                                        </div>

                                                        <div className="d-flex">
                                                            <div className="col-12">
                                                                <div className="text-center border border-dark border-top-0 border-end-0 border-bottom-0 border-start-0 align-self-center align-middle">{WORKSHOP_SUPERVISOR_SIGNATURE_LABEL}</div>
                                                                <div className="m-auto text-center">
                                                                    {!isEmpty(serviceReportDetails.signature) ?
                                                                        <img
                                                                            src={`${API_ENDPOINT}/assets/images/signatures/${serviceReportDetails.signature}`}
                                                                            alt="Signature"
                                                                            width="50%"
                                                                        />
                                                                        : <div className="border border-dark border-start-0 border-top-0 h-100"></div> }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </React.Fragment>
                    : <Loader />
                }
            </div>

            <div className="w-95 m-auto">
                <Back />
            </div>
        </React.Fragment>
    );
};

export default PrintServiceReport;
