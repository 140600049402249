import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {closeModal} from "../../utils/slices/modal.slice";

const Modal = () => {
    const dispatch = useDispatch();
    const modal = useSelector(state => state.modal);

    useEffect(() => {
        document.body.style.overflowY = modal.config && modal.config.component
            ? 'hidden'
            : 'scroll';
    }, [modal]);

    function close() {
        modal.close && modal.close();
        dispatch(closeModal({}));
    }

    function onClose(prop) {
        modal.onClose && modal.onClose(prop);
        dispatch(closeModal({}));
    }

    return modal.config && modal.config.component && <modal.config.component {...modal.config.props} title={modal.config.title} close={close} onClose={onClose} />
};

export default Modal;