import React, {useState} from 'react';

import TextInput from "../../form/TextInput";
import {isEmpty} from "../../../utils/helpers/app.helpers";
import {postAuthenticated} from "../../../utils/actions/post.actions";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";

import "./../../../styles/modals/modal.custom.css";

const UserPasswordModal = (props) => {
    const selectedUser = props.selectedUser;
    const [errors, setErrors] = useState({});
    const [passwordDetails, setPasswordDetails] = useState({
        password: "",
        confirm_password: "",
    });

    function handleChange(event) {
        setPasswordDetails({...passwordDetails, [event.target.name]: event.target.value});
    }

    function validatePasswords() {
        let isValid = true;
        let _errors = {};

        if(isEmpty(passwordDetails.password)) {
            _errors['password'] = "This field cannot be empty";
            isValid = false;
        }

        if(isEmpty(passwordDetails.confirm_password)) {
            _errors['confirm_password'] = "This field cannot be empty";
            isValid = false;
        }

        if(!isEmpty(passwordDetails.password) && !isEmpty(passwordDetails.confirm_password) && passwordDetails.password !== passwordDetails.confirm_password) {
            _errors['password'] = "Passwords do not match";
            _errors['confirm_password'] = "Passwords do not match";

            isValid = false;
        }

        setErrors(_errors);

        if(isValid) {
            resetPassword();
        }
    }

    function resetPassword() {
        let url = API_ENDPOINT + 'v1/Users/resetPassword';
        let params = {
            id: props.selectedUser.id,
            password: passwordDetails.password
        }

        postAuthenticated(url, params).then(r => {
            props.onClose();
        });
    }

    return (
        <div className="modal d-block bg-dark bg-opacity-50" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog mw-80 modal-dialog-scrollable modal-entrance d-flex">
                <div className="modal-content w-80 h-80 m-auto">
                    <div className="modal-header d-block">
                        <h1>Reset Password</h1>
                        <div>for <span className="text-decoration-underline fw-light">{selectedUser.name} | {selectedUser.email}</span></div>
                    </div>

                    <div className="modal-body bg-light" style={{overflowY: "scroll"}}>
                        <div className="w-50 m-auto">
                            <label>Password:</label>
                            <TextInput
                                type="password"
                                name="password"
                                value={passwordDetails.password}
                                onChange={(e) => handleChange(e)}
                                className="form-control rounded"
                                error={errors.password}
                            />
                        </div>

                        <div className="mt-4 w-50 m-auto">
                            <label>Confirm Password:</label>
                            <TextInput
                                type="password"
                                name="confirm_password"
                                value={passwordDetails.confirm_password}
                                onChange={(e) => handleChange(e)}
                                className="form-control rounded"
                                error={errors.confirm_password}
                            />
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button className="btn btn-primary" onClick={() => validatePasswords()}>Reset Password</button>
                        <button className="btn btn-danger" onClick={() => props.onClose()}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserPasswordModal;
