import {updateServiceReportSlice} from "../slices/serviceReport.slice";
import {updateFleetServiceReportSlice} from "../slices/fleetServiceReport.slice";
import {updateFieldSlice} from "../slices/fields.slice";
import {logoutUserProfile, updateUserProfileSlice} from "../slices/userProfile.slice";
import {removeUserPermissionSlice, updateUserPermissionSlice} from "../slices/userPermission.slice";
import {updateSelectedArchivePageSlice} from "../slices/selectedArchivePage.slice";
import {setHistoryTracker} from "../slices/historyTracker.slice";
import {updateServiceLogTrackerSlice} from "../slices/serviceLogTracker.slice";


// SERVICE REPORT SLICES
export function setServiceReport(serviceReport){
    return function(dispatch, getState) {
        dispatch(updateServiceReportSlice(serviceReport));
    }
}

// FLEET SERVICE REPORTS SLICES
export function setFleetServiceReports(fleetServiceReport){
    return function(dispatch, getState) {
        dispatch(updateFleetServiceReportSlice(fleetServiceReport));
    }
}

// FIELD SLICES
export function setFields(fleets){
    return function(dispatch, getState) {
        dispatch(updateFieldSlice(fleets));
    }
}

// USER SLICES
export function setUserProfile(userProfile) {
    return function(dispatch, getState) {
        dispatch(updateUserProfileSlice(userProfile))
    }
}

export function logoutUser(userProfile){
    return function(dispatch, getState) {
        dispatch(logoutUserProfile(userProfile))
    }
}

// USER PERMISSION SLICES
export function setUserPermission(userPermission) {
    return function(dispatch, getState) {
        dispatch(updateUserPermissionSlice(userPermission))
    }
}

export function removeUserPermission(userPermission) {
    return function(dispatch, getState) {
        dispatch(removeUserPermissionSlice(userPermission))
    }
}


export function setArchivedPageSelection(archivePageSelection) {
    return function(dispatch, getState) {
        dispatch(updateSelectedArchivePageSlice(archivePageSelection))
    }
}


export function setHistoryPageNumber(historyTracker) {
    return function(dispatch, getState) {
        dispatch(setHistoryTracker(historyTracker))
    }
}

export function setServiceLogTracker(serviceLogTracker) {
    return function(dispatch, getState) {
        dispatch(updateServiceLogTrackerSlice(serviceLogTracker))
    }
}