import $ from 'jquery';
import * as appConstants from '../constants/app.constants';

export function postAuthenticated(url, params) {
    let userToken = localStorage.getItem('user');
    return new Promise((resolve, reject) => {
        $.ajax({
            type: "POST",
            url: url,
            headers: {'app-key': appConstants.API_KEY, 'user-token': userToken},
            data: JSON.stringify(params),
            dataType: "json",
            contentType: "application/json",
            success: function (data) {resolve(data)},
            error: function (error) {
                window.dispatchEvent((new CustomEvent("exception", {
                    detail: {error: error},
                })));
            }
        });
    })
}

export function postUnauthenticated(url, params) {
    return new Promise((resolve, reject) => {
        $.ajax({
            type: "POST",
            url: url,
            headers: {'app-key': appConstants.API_KEY},
            data: JSON.stringify(params),
            dataType: "json",
            contentType: "application/json",
            success: function (data) {resolve(data)},
            error: function (error) {
                window.dispatchEvent((new CustomEvent("exception", {
                    detail: {error: error},
                })));
            }
        });
    })
}