import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import * as serviceReportConstants from "../../../utils/constants/serviceReport.constants";

import JobDetailsModal from "../../modals/serviceReportModals/JobDetailsModal";
import WorkPerformed from "./WorkPerformed";

import {setModal} from "../../../utils/slices/modal.slice";
import {setServiceReport} from "../../../utils/helpers/action.helpers";
import {WORK_INSTRUCTIONS_CHECKLIST} from "../../../utils/structures/serviceReport.structures";

const WorkInstructions = (props) => {
    const dispatch = useDispatch();
    const serviceReportDetails = useSelector(state => state.serviceReport);

    function handleToggle(event) {
        let _fields = {
           work_instructions: {
               ...serviceReportDetails.work_instructions,
                [event.target.name]: !serviceReportDetails.work_instructions[event.target.name]
           }
        }

        if(event.target.name === "rcf" && !serviceReportDetails.work_instructions[event.target.name]) {
            _fields.work_instructions['warranty_repairs'] = false;
            _fields['service_status'] = 2;
        }

        if(event.target.name === "warranty_repairs" && !serviceReportDetails.work_instructions[event.target.name]) {
            _fields.work_instructions['rcf'] = false;
            _fields['service_status'] = 12;
        }

        if(event.target.name === "service") {
            let _serviceToggleFields = handleServiceToggle(event);
            _fields = { ..._fields, ..._serviceToggleFields };
        }

        dispatch(setServiceReport(_fields));
        props.updateServiceReport(_fields);
    }

    function handleServiceToggle(event) {
        let value = !serviceReportDetails.work_instructions['service'];

        return setChecklists(value);
    }

    function setChecklists(value) {
        return {
            work_instructions: {
                ...serviceReportDetails.work_instructions,
                service: value
            },
            service_checklist_maintenance: {
                check_bolts_clamps_unit_security: value,
                check_case_lids: value,
                check_clean_drain_hoses: value,
                check_clutch_idler_pulley_bearing: value,
                check_compressor: value,
                check_compressor_oil: value,
                check_condenser_evaporator_coils: value,
                check_electrical_connections: value,
                check_electrical_thermostat: value,
                check_evaporator_condenser_fan_motors: value,
                check_filter_drier: value,
                check_heater_boost_pumps: value,
                check_heater_valve_operations: value,
                check_hoses_chafing_damage: value,
                check_hp_lp_pressure_switch: value,
                check_recorded_faults: value,
                check_refrigerant_leaks_gas_charge: value,
                check_suction_discharge_pressure: value,
                clean_filter: value,
                fully_clean_inside_unit: value,
                function_test_ac_cool_heat_mode: value
            }
        };
    }

    function launchViewJobCardModal() {
        let modalSettings = {
            config: {
                component: JobDetailsModal,
                props: {
                    job: serviceReportDetails.job
                }
            },
            onClose: () => {}
        }

        dispatch(setModal(modalSettings));
    }

    return (
        <React.Fragment>
            <div className="h-25 mb-3">
                <div className="h-95">
                    <div className="border border-dark p-2">
                        <b>{serviceReportConstants.WORK_INSTRUCTIONS_CUSTOMER_COMPLAINT_LABEL}</b>
                    </div>

                    <div className="border border-dark ">
                        <div className="d-flex flex-wrap py-2 h-90">
                            {WORK_INSTRUCTIONS_CHECKLIST.map(list =>
                                <div key={list.constant} className="col-6 p-1">
                                    <input type="checkbox"
                                           name={list.constant}
                                           value={serviceReportDetails.work_instructions[list.constant]}
                                           checked={serviceReportDetails.work_instructions[list.constant]}
                                           onClick={(e) => handleToggle(e)}
                                           className="form-check-input ms-1 me-1 mb-2 align-middle"
                                           disabled={props.isEditable}
                                    />
                                    <span>{list.label}</span>
                                </div>
                            )}
                        </div>

                        {serviceReportDetails.job ?
                            <div role="button" className="ms-3 mb-2 link-primary" onClick={() => launchViewJobCardModal()}>
                                View Customer Complaint
                            </div>
                        :null}
                    </div>
                </div>
            </div>

            <WorkPerformed
                serviceReportDetails={serviceReportDetails}
                updateServiceReport={props.updateServiceReport}
                actionType={props.actionType}
                isSaving={props.isSaving}
                isEditable={props.isEditable}
                currentLayoutView={props.currentLayoutView}
            />
        </React.Fragment>
    );
};

export default WorkInstructions;
