import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import {postAuthenticated} from "../../../utils/actions/post.actions";
import {isObjectEmpty} from "../../../utils/helpers/app.helpers";
import {setUserPermission} from "../../../utils/helpers/action.helpers";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";
import {SERVICE_REPORT_SECTIONS, USER_PERMISSION_PAGES} from "../../../utils/structures/users.structures";
import {PARTS_DETAILS_TABLE, SERVICE_REPORT_ACTIONS} from "../../../utils/structures/serviceReport.structures";

import "./../../../styles/modals/modal.custom.css";

const UserPermissionsModal = (props) => {
    const dispatch = useDispatch();
    const userProfile = useSelector(state => state.userProfile);
    const [userPermissions, setUserPermissions] = useState({});
    const [reportColors, setReportColors] = useState([]);
    const selectedUser = props.selectedUser;

    useEffect(() => {
        getUserPermissionsByUserId();
        getReportColors();
    }, []);

    function getReportColors() {
        let url = API_ENDPOINT + 'v1/ReportColors/getReportColors';
        let params = {}

        postAuthenticated(url, params).then(results => {
            setReportColors(Object.assign([], results.data));
        });
    }

    function getUserPermissionsByUserId() {
        let url = API_ENDPOINT + 'v1/UserPermissions/getUserPermissionByUserId';
        let params = {
            user_id: props.selectedUser.id
        }

        postAuthenticated(url, params).then(results => {
            setUserPermissions(results.data);
        });
    }

    function updateUserPermissions(_fields) {
        let url = API_ENDPOINT + "v1/UserPermissions/updateUserPermissions"
        let params = {
            fields: _fields,
            user_id: props.selectedUser.id
        }

        postAuthenticated(url, params).then(results => {});
    }

    function handleMainNavigationPerms(list, event) {
        let value = event.target.value == 'true' ? false : true;
        let _fields = {
            [list.type]: {...userPermissions[list.type], [list.constant]: value}
        }
        let _userPermissions = {
            ...userPermissions,
            [list.type]: {
                ...userPermissions[list.type],
                [list.constant]: value
            }
        };

        if(userProfile.id == props.selectedUser.id) {
            dispatch(setUserPermission(_userPermissions));
        }

        setUserPermissions(_userPermissions);
        updateUserPermissions(_fields);
    }

    function handleServiceReportPerms(list, event) {
        let value = event.target.value == 'true' ? false : true;

        let _fields = {
            [list.type]: {...userPermissions[list.type], [list.constant]: value}
        }
        let _userPermissions = {
            ...userPermissions,
            [list.type]: {
                ...userPermissions[list.type],
                [list.constant]: value
            }
        };

        if(userProfile.id == props.selectedUser.id) {
            dispatch(setUserPermission(_userPermissions));
        }

        setUserPermissions(_userPermissions);
        updateUserPermissions(_fields);
    }

    function handlePartsTableColumns(list, event) {
        let value = !userPermissions[list.type][list.constant];

        let _fields = {
            [list.type]: {...userPermissions[list.type], [list.constant]: value}
        }
        let _userPermissions = {
            ...userPermissions,
            [list.type]: {
                ...userPermissions[list.type],
                [list.constant]: value
            }
        };

        if(userProfile.id == props.selectedUser.id) {
            dispatch(setUserPermission(_userPermissions));
        }

        setUserPermissions(_userPermissions);
        updateUserPermissions(_fields);
    }

    function handleServiceReportActions(list, event) {
        let value = !userPermissions[list.type][list.constant];

        let _fields = {
            [list.type]: {...userPermissions[list.type], [list.constant]: value}
        }
        let _userPermissions = {
            ...userPermissions,
            [list.type]: {
                ...userPermissions[list.type],
                [list.constant]: value
            }
        };

        if(userProfile.id == props.selectedUser.id) {
            dispatch(setUserPermission(_userPermissions));
        }

        setUserPermissions(_userPermissions);
        updateUserPermissions(_fields);
    }

    return (
        <div className="modal d-block bg-dark bg-opacity-50" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog mw-80 modal-dialog-scrollable modal-entrance">
                <div className="modal-content w-90 h-90 m-auto bg-light">
                    <div className="modal-header d-block p-3">
                        <div className="d-flex">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">User Permissions</h3>
                            <button
                                onClick={() => props.onClose()}
                                className="border border-0 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                <i className="bi bi-x-lg text-secondary-emphasis" />
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <h4 className="lead fs-16 fst-italic pb-0 text-secondary">for <span className="text-decoration-underline fw-light">{selectedUser.name} | {selectedUser.email}</span></h4>
                    </div>

                    <div className="modal-body w-95 m-auto" style={{overflowY: "scroll"}}>
                        {!isObjectEmpty(userPermissions.main_navigation) && !isObjectEmpty(userPermissions.service_report) && !isObjectEmpty(userPermissions.service_status) && !isObjectEmpty(reportColors) ?
                            <React.Fragment>
                                <div className="d-flex">
                                    <div className="col-6">
                                        <div className="fw-bold mb-1">View Navigation:</div>
                                        <div className="d-flex flex-column">
                                            {USER_PERMISSION_PAGES.map(list =>
                                                <React.Fragment>
                                                    <div key={list.constant} className={`form-check form-switch mb-1`}>
                                                        <input type="checkbox" role="switch" className="form-check-input align-middle"
                                                               name={list.constant} value={userPermissions[list.type][list.constant]}
                                                               checked={userPermissions[list.type][list.constant] == "true" || userPermissions[list.type][list.constant] == true}
                                                               onChange={(e) => handleMainNavigationPerms(list, e)}/>
                                                        <span className="form-check-label">{list.label}</span>
                                                    </div>

                                                    <div className="">
                                                        {list.sub_pages && userPermissions[list.type][list.constant] && list.sub_pages.map(sub_list =>
                                                            <div key={sub_list.constant} className={`form-check form-switch mb-1 ms-5`}>
                                                                <input type="checkbox" role="switch" className="form-check-input align-middle"
                                                                       name={sub_list.constant} value={userPermissions[sub_list.type][sub_list.constant]}
                                                                       checked={userPermissions[sub_list.type][sub_list.constant] == "true" || userPermissions[sub_list.type][sub_list.constant] == true}
                                                                       onChange={(e) => handleMainNavigationPerms(sub_list, e)}/>
                                                                <span className="form-check-label">{sub_list.label}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="fw-bold mb-1">View Service Report Sections:</div>
                                        <div className="d-flex flex-column">
                                            {SERVICE_REPORT_SECTIONS.map(list =>
                                                <div key={list.constant} className="form-check form-switch mb-1">
                                                    <input type="checkbox" className="form-check-input align-middle"
                                                           name={list.constant} value={userPermissions[list.type][list.constant]}
                                                           checked={userPermissions[list.type][list.constant] == "true" || userPermissions[list.type][list.constant] == true}
                                                           onChange={(e) => handleServiceReportPerms(list, e)}/>
                                                    <span className="form-check-label">{list.label}</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <hr />

                                <div className="d-flex">
                                    <div className="col-6">
                                        <div className="fw-bold mb-1">View Service Statuses:</div>
                                        <div className="d-flex flex-column">
                                            {reportColors.map(list =>
                                                list.id != 5 &&
                                                <div key={list.constant} className="form-check form-switch">
                                                    <input type="checkbox" className="form-check-input align-middle"
                                                           name={list.constant} value={userPermissions.service_status[list.constant]}
                                                           checked={userPermissions.service_status[list.constant] == "true" || userPermissions.service_status[list.constant] == true}
                                                           onChange={(e) => handleServiceReportPerms({
                                                               constant: list.constant,
                                                               label: list.report_status,
                                                               type: "service_status"
                                                           }, e)}/>
                                                    <span className="form-check-label">{list.report_status}</span>
                                                </div>
                                            )}
                                            <div className="form-check form-switch mb-1">
                                                <input type="checkbox" className="form-check-input align-middle"
                                                       name="add" value={userPermissions.service_status.add}
                                                       checked={userPermissions.service_status["add"]}
                                                       onChange={(e) => handleServiceReportPerms({
                                                           constant: "add",
                                                           label: "Add",
                                                           type: "service_status"
                                                       }, e)}/>
                                                <span className="form-check-label">Add</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <div className="fw-bold mb-1">View Part Table Columns:</div>
                                        <div className="d-flex flex-column">
                                            {PARTS_DETAILS_TABLE.map(list =>
                                                <div key={list.object} className="form-check form-switch mb-1">
                                                    <input type="checkbox" className="form-check-input align-middle"
                                                           name={list.object} value={userPermissions.parts_table[list.object]}
                                                           checked={userPermissions.parts_table[list.object]}
                                                           onChange={(e) => handlePartsTableColumns(list, e)}/>
                                                    <span className="form-check-label">{list.name}</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <hr />

                                <div className="d-flex">
                                    <div className="col-6">
                                        <div className="fw-bold mb-1">View Service Report Actions:</div>
                                        <div className="d-flex flex-column">
                                            {SERVICE_REPORT_ACTIONS.map(list =>
                                                <div key={list.constant} className="form-check form-switch mb-1">
                                                    <input type="checkbox" className="form-check-input align-middle"
                                                           name={list.constant} value={userPermissions.service_report_actions[list.constant]}
                                                           checked={userPermissions.service_report_actions[list.constant]}
                                                           onChange={(e) => handleServiceReportActions(list, e)}/>
                                                    <span className="form-check-label">{list.label}</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        : null}
                    </div>

                    <div className="modal-footer justify-content-start items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                        <button className="btn btn-primary bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={() => props.onClose()}>Done</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserPermissionsModal;
