import React, {useEffect, useState} from 'react';
import {API_ENDPOINT} from "../../../utils/constants/app.constants";
import {postAuthenticated} from "../../../utils/actions/post.actions";

import "../../../styles/materialise/materialize.css";

import Loader from "../../common/Loader";

const UserDevicesModal = (props) => {
    const [init, setInit] = useState(false);
    const [devices, setDevices] = useState([]);

    useEffect(() => {
        getDevicesByUserId();
    }, []);

    function getDevicesByUserId() {
        let url = API_ENDPOINT + "v1/UserDevices/getByUserId";
        let params = {
            user_id: props.user.id
        }

        postAuthenticated(url, params).then(results => {
            setDevices(results.data);
            setInit(true);
        })
    }

    return (
        init ?
            <div className="modal d-block bg-dark bg-opacity-50" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog mw-80 modal-dialog-scrollable modal-entrance">
                    <div className="modal-content w-90 h-90 m-auto bg-light">
                        <div className="modal-header d-block p-3">
                            <div className="d-flex">
                                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">User Devices</h3>
                                <button
                                    onClick={() => props.onClose()}
                                    className="border border-0 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                    <i className="bi bi-x-lg text-secondary-emphasis" />
                                    <span className="sr-only">Close modal</span>
                                </button>
                            </div>
                            <h4 className="lead fs-16 fst-italic pb-0 text-secondary">for <span className="text-decoration-underline fw-light">{props.user.name}</span></h4>
                        </div>

                        <div className="modal-body w-95 m-auto" style={{overflowY: "scroll"}}>
                            <table id="use-materialise" className="w-90 m-auto mb-3 lead fs-14">
                                <thead>
                                    <th>Access</th>
                                    <th>Brand</th>
                                    <th>Operating System / Phone</th>
                                    <th>Browser</th>
                                </thead>

                                <tbody>
                                    {devices.map((device, index) =>
                                        <tr>
                                            <td>
                                                {device.enabled
                                                    ? <i className="bi bi-check text-success fs-24" />
                                                    : <i className="bi bi-x text-danger fs-24" />}
                                            </td>
                                            <td>{device.brand}</td>
                                            <td>{device.os}</td>
                                            <td>{device.browser}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                        <div className="modal-footer justify-content-start items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button className="btn btn-primary bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={() => props.onClose()}>Done</button>
                        </div>
                    </div>
                </div>
            </div>
        : <Loader />
    );
};

export default UserDevicesModal;