export const SERVICE_ID_LABEL = "Service ID";
export const FLEET_NO_LABEL = "Fleet NO#";
export const SRNO_LABEL = "SRNO";
export const START_DATE_LABEL = "Start Date";
export const USE_THIS_LABEL = "Use This";

export const PART_NUMBER_LABEL = "Part Number";
export const DESCRIPTION_LABEL = "Description";
export const STOCK_TOTAL_LABEL = "Stock Total";
export const SELECT_PART_LABEL = "Select Part";

export const FIELD_NAME_LABEL = "Field Name";