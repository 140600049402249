import React from 'react';
import {isEmpty} from "../../../utils/helpers/app.helpers";
import moment from "moment";

const WorkInstructionModal = (props) => {
    const sr = props.sr

    return (
        <div className="modal d-block bg-dark bg-opacity-50 modal-backdrop" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog mw-80 modal-dialog-scrollable">
                <div className="modal-content w-100 h-95">
                    <div className="modal-header d-block">
                        <h1>Work Instruction | Customer Complaints</h1>
                        <div className="d-block">
                            <div className="text pe-1">for <span className="text-decoration-underline">{sr.srno}</span> - {moment(sr.date_changed).format("DD/MM/YYYY")}</div>
                            <div className="text-decoration-underline">Fleet No: {!isEmpty(sr.fleet_no) ? sr.fleet_no : ''}</div>
                        </div>
                    </div>

                    <div className="modal-body" style={{overflowY: "scroll"}}>
                        <div>
                            <div className="fs-3 lead bg-white rounded text-decoration-underline mb-2">Work Performed</div>
                            <div className="" dangerouslySetInnerHTML={{__html: sr.workperformed}} />
                        </div>

                        <div className="mt-5">
                            <div className="fs-3 lead bg-white rounded text-decoration-underline mb-2">RFC Notes</div>
                            <div dangerouslySetInnerHTML={{__html: sr.notes}} />
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button className="btn btn-warning" onClick={() => props.onClose()}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WorkInstructionModal;
