import React from 'react';
import {useNavigate} from "react-router-dom";
import Draggable from "react-draggable";

const Back = (props) => {
    const navigate = useNavigate();

    function goBack() {
        if(props.function) {
            props.function();
        }else{
            navigate(-1);
        }
    }

    return (
        <div style={{position: "fixed", bottom: "10px"}}>
            <Draggable>
                <button type="button" className="btn btn-warning bg-warning back-hover opacity-50 me-3 border border-dark text-dark" onTouchStart={() => goBack()} onClick={() => goBack()} style={{width: "150px"}}>
                    {props.label ? props.label : 'Back'}
                </button>
            </Draggable>
        </div>
    );
};

export default Back;