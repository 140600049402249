import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import "../../../styles/serviceReport/partsTable.scss";
import * as serviceReportConstants from "../../../utils/constants/serviceReport.constants";

import Loader from "../../common/Loader";
import PartsTableCard from "./PartsTableCard";
import TextInput from "../../form/TextInput";
import PartsModal from "../../modals/serviceReportModals/PartsModal";
import FieldModal from "../../modals/serviceReportModals/FieldModal";
import PartRowDetailsModal from "../../modals/serviceReportModals/PartRowDetailsModal";

import {API_ENDPOINT} from "../../../utils/constants/app.constants";
import {PARTS_DETAILS_TABLE} from "../../../utils/structures/serviceReport.structures";
import {postAuthenticated} from "../../../utils/actions/post.actions";
import {isEmpty, isObjectEmpty} from "../../../utils/helpers/app.helpers";
import {setFields, setServiceReport} from "../../../utils/helpers/action.helpers";

const PartsTable = (props) => {
    const dispatch = useDispatch();
    const userProfile = useSelector(state => state.userProfile);
    const userPermissions = useSelector(state => state.userPermissions);
    const fields = useSelector(state => state.fields);
    const serviceReportDetails = useSelector(state => state.serviceReport);
    const [init, setInit] = useState(false);
    const [isPartModalOpen, setIsPartModalOpen] = useState(false);
    const [isFieldModelOpen, setIsFieldModalOpen] = useState(false);
    const [isPartRowDetailsOpen, setIsPartRowDetailsOpen] = useState(false);

    const [tableRows, setTableRows] = useState(3);
    const [retrievedParts, setRetrievedParts] = useState({});
    const [selectedInputIndex, setSelectedInputIndex] = useState();
    const [selectedPartRowIndex, setSelectedPartRowIndex] = useState();

    const is_user_super_admin = userProfile.role == 4;

    useEffect(() => {
        getAllFields();
    }, []);


    function handleChange(event, index) {
        let value = event.target.value;
        if(event.target.value === "...") {
            value = null;
        }

        let _fields = {
            parts_table: Object.assign([], {
                ...serviceReportDetails.parts_table,
                [index]: {
                    ...serviceReportDetails.parts_table[index],
                    [event.target.name]: value,
                }
            })
        }

        if((event.target.name === "desc" && !isEmpty(event.target.value)) && isEmpty(serviceReportDetails.parts_table[index].qty) && isEmpty(serviceReportDetails.parts_table[index].part)) {
            setTimeout(function() {
                getInventoryPartDetailsByDescriptionAndModel(event, index);
            }, 3000);
        }

        props.updateServiceReport(_fields);
        dispatch(setServiceReport(_fields));

        calculateTotalPrice(_fields, index);
    }

    function clearRow(index) {
        let _fields = {
            parts_table: Object.assign([], {
                ...serviceReportDetails.parts_table,
                [index]: {
                    ...serviceReportDetails.parts_table[index],
                    'qty': "",
                    'part': "",
                    'desc': "",
                    'location': "",
                    'discount': "",
                    'price': "",
                    'total_price': "",
                },
            }),
        }

        if(!isEmpty(serviceReportDetails.parts_table[index]['total_price'])) {
            _fields['totalmprice'] = parseFloat(serviceReportDetails.totalmprice) - parseFloat(serviceReportDetails.parts_table[index]['total_price'])
        }

        if(!isEmpty(serviceReportDetails.parts_table[index]['total_price']) && (serviceReportDetails.exgst - serviceReportDetails.parts_table[index]['total_price']) >= 0) {
            _fields['exgst'] = parseFloat(serviceReportDetails.exgst) - parseFloat(serviceReportDetails.parts_table[index]['total_price']);
        }

        props.updateServiceReport(_fields);
        dispatch(setServiceReport(_fields));
    }

    function getInventoryPartDetailsByDescriptionAndModel(event, index) {
        let url = API_ENDPOINT + 'v1/Inventories/getInventoryPartDetailsByDescriptionAndModel';
        let params = {
            desc: event.target.value,
            model: serviceReportDetails.unit_model || "",
        }

        setInit(true);
        postAuthenticated(url, params).then(results => {
            setTimeout(function() {
                if(!isObjectEmpty(results.data)) {
                    setRetrievedParts(results.data);
                    setSelectedInputIndex(index);
                    setIsPartModalOpen(true);
                }
                setInit(false);
            }, 3000);
        });
    }

    function getAllFields() {
        let url = API_ENDPOINT + 'v1/Fields/getAllFields';
        let params = {}

        postAuthenticated(url, params).then(results => {
            dispatch(setFields(results.data));
        });
    }

    function calculateTotalPrice(_fields, passed_index) {
        let totalMaterialPrice = 0;
        let partsTable = _fields.parts_table;

        for(let i=0; i<Object.keys(_fields.parts_table).length; i++) {
            let qty = isEmpty(parseFloat(partsTable[i]["qty"])) ? 0 : parseFloat(partsTable[i]["qty"]);
            let partPrice = isEmpty(parseFloat(partsTable[i]["price"])) ? 0 : parseFloat(partsTable[i]["price"]).toFixed(2);
            let discountAmount = isEmpty(parseFloat(partsTable[i]["discount"])) || parseFloat(partsTable[i]["discount"]) >= 100 ? 0 : parseFloat(partsTable[i]["discount"] / 100);
            let discountedPrice = (qty * partPrice) - ((qty * partPrice) * discountAmount);

            if(!isNaN(discountedPrice) && i !== passed_index) {
                let _partsTableField = {
                    parts_table: Object.assign([], {
                        ..._fields.parts_table,
                        [i]: {
                            ..._fields.parts_table[i],
                            ['total_price']: parseFloat(discountedPrice.toFixed(2)),
                        },
                    })
                };
                dispatch(setServiceReport(_partsTableField));
            }
            totalMaterialPrice += !isNaN(parseFloat(discountedPrice.toFixed(2))) && parseFloat(discountedPrice.toFixed(2));
        }

        let qty = isEmpty(parseFloat(partsTable[passed_index]["qty"])) ? 0 : parseFloat(partsTable[passed_index]["qty"]);
        let partPrice = isEmpty(parseFloat(partsTable[passed_index]["price"])) ? 0 : parseFloat(partsTable[passed_index]["price"]).toFixed(2);
        let discountAmount = isEmpty(parseFloat(partsTable[passed_index]["discount"])) || parseFloat(partsTable[passed_index]["discount"]) >= 100 ? 0 : parseFloat(partsTable[passed_index]["discount"] / 100);
        let discountedPrice = (qty * partPrice) - ((qty * partPrice) * discountAmount);

        if(!isNaN(discountedPrice)) {
            let _partsTableField = {
                parts_table: Object.assign([], {
                    ..._fields.parts_table,
                    [passed_index]: {
                        ..._fields.parts_table[passed_index],
                        ['total_price']: parseFloat(discountedPrice.toFixed(2)),
                    },
                })
            };
            dispatch(setServiceReport(_partsTableField));
        }

        if(!isNaN(totalMaterialPrice)) {
            let _finalFields = {
                'totalmprice': totalMaterialPrice.toFixed(2),
            };

            if(!isEmpty(_fields['exgst'])) {
                _finalFields['exgst'] = (totalMaterialPrice + parseFloat(serviceReportDetails.exgst)).toFixed(2)
            }else{
                _finalFields['exgst'] = totalMaterialPrice.toFixed(2)
            }

            dispatch(setServiceReport(_finalFields));
        }

        let fields_totalmprice = {
            totalmprice: totalMaterialPrice
        }
        props.updateServiceReport(fields_totalmprice);
    }

    function handleDoubleClick(event, index) {
        if (event.detail === 2) {
            setSelectedPartRowIndex(index);
            setIsPartRowDetailsOpen(true);
        }
    }

    function renderTD(index) {
        let td = [];
        PARTS_DETAILS_TABLE.map((part, partIndex) => {
            td.push(
                <React.Fragment key={partIndex}>
                    {userPermissions.parts_table[part.object] &&
                        <td key={partIndex} data-label={part.name} style={{width: `1%`, background: `${props.isEditable && '#e9ecef'}`}}
                            onClick={(e) => handleDoubleClick(e, index)}>
                            {part.object === "location" ?
                                <React.Fragment>
                                    <div
                                        className={`d-xl-block d-none ${!props.isEditable ? 'location-btn' : ""}`}
                                        onClick={!props.isEditable ? () => {
                                            setIsFieldModalOpen(true);
                                            setSelectedInputIndex(index)
                                        } : null}
                                        style={{
                                            backgroundColor: `${props.isEditable ? '#e9ecef' : '#fff'}`,
                                            padding: "0.4em"
                                        }}
                                    >
                                        <span>
                                            {!isObjectEmpty(serviceReportDetails) && !isEmpty(serviceReportDetails.parts_table[index][part.object]) ?
                                                serviceReportDetails.parts_table[index][part.object]
                                                : '...'}
                                        </span>
                                    </div>
                                    <select
                                        id={`${part.object}`}
                                        name={`${part.object}`}
                                        value={!isObjectEmpty(serviceReportDetails.parts_table) && serviceReportDetails.parts_table[index][part.object]}
                                        onChange={(e) => handleChange(e, index)}
                                        className="d-xl-none d-block form-control"
                                        disabled={props.isEditable}
                                    >
                                        <option className="text-center">...</option>

                                        {!isObjectEmpty(fields) && Object.assign([], fields).map(list => (
                                            <option key={list.id} value={list.field_name}>{list.field_name}</option>
                                        ))}
                                    </select>
                                </React.Fragment>
                                : part.object === "price" ?
                                    <React.Fragment>
                                        <TextInput
                                            name={`${part.object}`}
                                            value={!isObjectEmpty(serviceReportDetails) && serviceReportDetails.parts_table[index][part.object]}
                                            onChange={(e) => handleChange(e, index)}
                                            disabled={!is_user_super_admin || props.isEditable}
                                            className="border-0 text-center"
                                        />
                                    </React.Fragment>
                                : part.object === "total_price" ?
                                        <TextInput
                                            name={`${part.object}`}
                                            value={!isObjectEmpty(serviceReportDetails) && serviceReportDetails.parts_table[index][part.object]}
                                            onChange={(e) => handleChange(e, index)}
                                            disabled={true}
                                            className="border-0 text-center"
                                        />
                                : part.object === "discount" ?
                                    <TextInput
                                        name={`${part.object}`}
                                        value={!isObjectEmpty(serviceReportDetails) && serviceReportDetails.parts_table[index][part.object]}
                                        onChange={(e) => handleChange(e, index)}
                                        disabled={props.isEditable || part.disabled}
                                        className="border-0 text-center d-xl-block d-none"
                                    />
                                : part.object === "clear" ?
                                    <i className={`fa fa-remove`} role={props.isEditable ? '' : 'button'} onClick={() => props.isEditable ? null : clearRow(index)}/>
                                :
                                    <TextInput
                                        name={`${part.object}`}
                                        value={serviceReportDetails.parts_table[index][part.object]}
                                        onChange={(e) => handleChange(e, index)}
                                        disabled={props.isEditable || part.disabled}
                                        className="border-0 text-center"
                                    />
                            }
                        </td>
                    }
                </React.Fragment>
            );
        })
        return td;
    }

    function renderTR() {
        let tr = [];
        serviceReportDetails.parts_table.map((row, index) =>
            row.has_value ?
                tr.push(
                    <tr key={index}>{renderTD(row.index)}</tr>
                )
            :null);


        serviceReportDetails.parts_table.map((row, index) =>
            !(row.has_value) && index < tableRows ?
                tr.push(
                    <tr key={index}>{renderTD(row.index)}</tr>
                )
            :null);

        return tr;
    }

    function addRow() {
        let _fields = {
            parts_table: Object.assign([], {
                ...serviceReportDetails.parts_table,
                [serviceReportDetails.parts_table.length]: {
                    ...serviceReportDetails.parts_table[serviceReportDetails.parts_table.length],
                    'qty': null,
                    'part': null,
                    'desc': null,
                    'location': null,
                    'discount': null,
                    'price': null,
                    'total_price': null,
                    "index": serviceReportDetails.parts_table.length,
                    "has_value": true
                },
            }),
        }

        props.updateServiceReport(_fields);
        dispatch(setServiceReport(_fields));
    }

    return (
        <div className="mt-3">
            {init && <Loader />}

            {isPartModalOpen &&
                <PartsModal
                    setIsModalOpen={setIsPartModalOpen}
                    partsList={retrievedParts}
                    updateServiceReport={props.updateServiceReport}
                    selectedInputIndex={selectedInputIndex}
                />
            }
            {isFieldModelOpen &&
                <FieldModal
                    setIsFieldModalOpen={setIsFieldModalOpen}
                    updateServiceReport={props.updateServiceReport}
                    selectedInputIndex={selectedInputIndex}
                />
            }
            {isPartRowDetailsOpen &&
                <PartRowDetailsModal
                    setIsPartRowModalOpen={setIsPartRowDetailsOpen}
                    selectedPartRowIndex={selectedPartRowIndex}
                />
            }

            {props.currentLayoutView !== "mobile_view" ?
                <React.Fragment>
                    <table className="service-report-tables w-auto table-responsive text-center">
                        <thead>
                            <tr>
                                {userPermissions.parts_table.qty && <th>{serviceReportConstants.QTY_LABEL}</th>}
                                {userPermissions.parts_table.part && <th>{serviceReportConstants.PART_NO_LABEL}</th>}
                                {userPermissions.parts_table.desc && <th>{serviceReportConstants.DESCRIPTION_LABEL}</th>}
                                {userPermissions.parts_table.location && <th>{serviceReportConstants.LOCATION_LABEL}</th>}
                                {userPermissions.parts_table.discount && <th>{serviceReportConstants.DISCOUNT_LABEL}</th>}
                                {userPermissions.parts_table.price && <th>{serviceReportConstants.PRICE_EA_LABEL}</th>}
                                {userPermissions.parts_table.total_price && <th>{serviceReportConstants.TOTAL_PRICE_LABEL}</th>}
                                {userPermissions.parts_table.clear && <th />}
                            </tr>
                        </thead>
                        <tbody>
                            {renderTR()}
                        </tbody>
                    </table>

                    {(props.actionType !== "view" || props.actionType !== "revision") &&
                        <div>
                            <button type="button" className="btn btn-light w-100 border border-1 mt-2" onClick={() => addRow()}>Add Table Row</button>
                        </div> }
                </React.Fragment>
            :
                <PartsTableCard
                    isEditable={props.isEditable}
                    setIsFieldModalOpen={setIsFieldModalOpen}
                    setSelectedInputIndex={setSelectedInputIndex}
                    handleChange={handleChange}
                    clearRow={clearRow}
                />
            }
        </div>
    );
};

export default PartsTable;
