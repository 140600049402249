import * as serviceReportConstant from "../constants/serviceReport.constants";

export const SERVICE_CHECKLIST_MAINTENANCE = [
    {
        constant: serviceReportConstant.CLEAN_FILTER_CONSTANT,
        label: serviceReportConstant.CLEAN_FILTER_LABEL,
        checked: false,
    },
    {
        constant: serviceReportConstant.CHECK_COMPRESSOR_CONSTANT,
        label: serviceReportConstant.CHECK_COMPRESSOR_LABEL,
        checked: false,
    },
    {
        constant: serviceReportConstant.CHECK_COMPRESSOR_OIL_CONSTANT,
        label: serviceReportConstant.CHECK_COMPRESSOR_OIL_LABEL,
        checked: false,
    },
    {
        constant: serviceReportConstant.CHECK_FILTER_DRIER_CONSTANT,
        label: serviceReportConstant.CHECK_FILTER_DRIER_LABEL,
        checked: false,
    },
    {
        constant: serviceReportConstant.CHECK_RECORDED_FAULTS_CONSTANT,
        label: serviceReportConstant.CHECK_RECORDED_FAULTS_LABEL,
        checked: false,
    },
    {
        constant: serviceReportConstant.CHECK_HEATER_VALVE_OPERATIONS_CONSTANT,
        label: serviceReportConstant.CHECK_HEATER_VALVE_OPERATIONS_LABEL,
        checked: false,
    },
    {
        constant: serviceReportConstant.CHECK_BOLTS_CLAMPS_UNIT_SECURITY_CONSTANT,
        label: serviceReportConstant.CHECK_BOLTS_CLAMPS_UNIT_SECURITY_LABEL,
        checked: false,
    },
    {
        constant: serviceReportConstant.CHECK_CONDENSER_EVAPORATOR_COILS_CONSTANT,
        label: serviceReportConstant.CHECK_CONDENSER_EVAPORATOR_COILS_LABEL,
        checked: false,
    },
    {
        constant: serviceReportConstant.CHECK_CASE_LIDS_CONSTANT,
        label: serviceReportConstant.CHECK_CASE_LIDS_LABEL,
        checked: false,
    },
    {
        constant: serviceReportConstant.FULLY_CLEAN_INSIDE_UNIT_CONSTANT,
        label: serviceReportConstant.FULLY_CLEAN_INSIDE_UNIT_LABEL,
        checked: false,
    },
    {
        constant: serviceReportConstant.CHECK_REFRIGERANT_LEAKS_GAS_CHARGE_CONSTANT,
        label: serviceReportConstant.CHECK_REFRIGERANT_LEAKS_GAS_CHARGE_LABEL,
        checked: false,
    },
    {
        constant: serviceReportConstant.CHECK_CLEAN_DRAIN_HOSES_CONSTANT,
        label: serviceReportConstant.CHECK_CLEAN_DRAIN_HOSES_LABEL,
        checked: false,
    },
    {
        constant: serviceReportConstant.CHECK_HOSES_CHAFING_DAMAGE_CONSTANT,
        label: serviceReportConstant.CHECK_HOSES_CHAFING_DAMAGE_LABEL,
        checked: false,
    },
    {
        constant: serviceReportConstant.CHECK_SUCTION_DISCHARGE_PRESSURE_CONSTANT,
        label: serviceReportConstant.CHECK_SUCTION_DISCHARGE_PRESSURE_LABEL,
        checked: false,
    },
    {
        constant: serviceReportConstant.CHECK_ELECTRICAL_CONNECTIONS_CONSTANT,
        label: serviceReportConstant.CHECK_ELECTRICAL_CONNECTIONS_LABEL,
        checked: false,
    },
    {
        constant: serviceReportConstant.CHECK_HP_LP_PRESSURE_SWITCH_CONSTANT,
        label: serviceReportConstant.CHECK_HP_LP_PRESSURE_SWITCH_LABEL,
        checked: false,
    },
    {
        constant: serviceReportConstant.CHECK_ELECTRICAL_THERMOSTAT_CONSTANT,
        label: serviceReportConstant.CHECK_ELECTRICAL_THERMOSTAT_LABEL,
        checked: false,
    },
    {
        constant: serviceReportConstant.CHECK_HEATER_BOOST_PUMPS_CONSTANT,
        label: serviceReportConstant.CHECK_HEATER_BOOST_PUMPS_LABEL,
        checked: false,
    },
    {
        constant: serviceReportConstant.CHECK_CLUTCH_IDLER_PULLEY_BEARING_CONSTANT,
        label: serviceReportConstant.CHECK_CLUTCH_IDLER_PULLEY_BEARING_LABEL,
        checked: false,
    },
    {
        constant: serviceReportConstant.CHECK_EVAPORATOR_CONDENSER_FAN_MOTORS_CONSTANT,
        label: serviceReportConstant.CHECK_EVAPORATOR_CONDENSER_FAN_MOTORS_LABEL,
        checked: false,
    },
    {
        constant: serviceReportConstant.FUNCTION_TEST_AC_COOL_HEAT_MODE_CONSTANT,
        label: serviceReportConstant.FUNCTION_TEST_AC_COOL_HEAT_MODE_LABEL,
        checked: false,
    },
];

export const WORK_INSTRUCTIONS_CHECKLIST = [
    {
        constant: serviceReportConstant.SERVICE_CONSTANT,
        label: serviceReportConstant.SERVICE_LABEL
    },
    {
        constant: serviceReportConstant.AC_FAULTY_CONSTANT,
        label: serviceReportConstant.AC_FAULTY_LABEL
    },
    {
        constant: serviceReportConstant.AC_REPAIRS_CONSTANT,
        label: serviceReportConstant.AC_REPAIRS_LABEL
    },
    {
        constant: serviceReportConstant.RCF_CONSTANT,
        label: serviceReportConstant.RCF_LABEL
    },
    {
        constant: serviceReportConstant.WARRANTY_REPAIRS_CONSTANT,
        label: serviceReportConstant.WARRANTY_REPAIRS_LABEL
    },
    {
        constant: serviceReportConstant.PREVENTATIVE_MAINTENANCE_CONSTANT,
        label: serviceReportConstant.PREVENTATIVE_MAINTENANCE_LABEL
    },
    {
        constant: serviceReportConstant.OIL_CHANGED_CONSTANT,
        label: serviceReportConstant.OIL_CHANGED_LABEL
    },
    {
        constant: serviceReportConstant.CHANGED_FILTER_DRIER_CONSTANT,
        label: serviceReportConstant.CHANGED_FILTER_DRIER_LABEL
    },
    {
        constant: serviceReportConstant.CHANGED_SHAFT_SEAL_CONSTANT,
        label: serviceReportConstant.CHANGED_SHAFT_SEAL_LABEL
    },

];


export const PARTS_DETAILS_TABLE = [
    {
        name: "Quantity",
        object: "qty",
        constant: "qty",
        type: "parts_table",
        disabled: false,
        width: "5%",
    },
    {
        name: "Part No",
        object: "part",
        constant: "part",
        type: "parts_table",
        disabled: false,
        width: "10%",
    },
    {
        name: "Description",
        object: "desc",
        constant: "desc",
        type: "parts_table",
        disabled: false,
        width: "35%",
    },
    {
        name: "Location",
        object: "location",
        constant: "location",
        type: "parts_table",
        disabled: false,
        width: "15%",
    },
    {
        name: "Discount",
        object: "discount",
        constant: "discount",
        type: "parts_table",
        disabled: false,
        width: "10%",
    },
    {
        name: "Price",
        object: "price",
        constant: "price",
        type: "parts_table",
        disabled: false,
        width: "10%",
    },
    {
        name: "Total Price",
        object: "total_price",
        constant: "total_price",
        type: "parts_table",
        disabled: true,
        width: "10%",
    },
    {
        name: "Clear",
        object: "clear",
        constant: "clear",
        type: "parts_table",
        disabled: true,
        width: "10%",
    }
];

export const PRINT_PART_DETAILS_TABLE = [
    {
        name: "qty",
        object: "qty",
        disabled: false,
        width: "5%",
    },
    {
        name: "parts",
        object: "part",
        disabled: false,
        width: "10%",
    },
    {
        name: "desc",
        object: "desc",
        disabled: false,
        width: "35%",
    },
    {
        name: "loc",
        object: "location",
        disabled: false,
        width: "15%",
    },
    {
        name: "discount",
        object: "discount",
        disabled: false,
        width: "10%",
    },
    {
        name: "price",
        object: "price",
        disabled: false,
        width: "10%",
    },
    {
        name: "total_price",
        object: "total_price",
        disabled: true,
        width: "10%",
    },
];

export const TECH_INITIALS_TABLE = [
    {
        name: "tech",
        disabled: false,
        width: "33%",
    },
    {
        name: "worked_on",
        disabled: false,
        width: "33%",
    },
    {
        name: "time_spent",
        disabled: false,
        width: "33%",
    },
];

export const LAYOUT_VIEW = [
    {
        constant: "full_width_view",
        label: "Desktop",
        minimum_width: 1400
    },
    {
        constant: "responsive_view",
        label: "Tablet",
        minimum_width: 961
    },
    {
        constant: "mobile_view",
        label: "Mobile",
        minimum_width: 850
    }
];

export const SERVICE_REPORT_ACTIONS = [
    {   constant: "edit",
        label: "Edit",
        type: "service_report_actions" },

    {   constant: "view",
        label: "View",
        type: "service_report_actions" },

    {   constant: "print",
        label: "Print",
        type: "service_report_actions" },

    {   constant: "invoice",
        label: "Invoice",
        type: "service_report_actions" },

    {   constant: "archive",
        label: "Archive",
        type: "service_report_actions" },

    {   constant: "create_sub_report",
        label: "Create Sub Report",
        type: "service_report_actions" }
]