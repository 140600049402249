import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import TextInput from "../../form/TextInput";
import {isObjectEmpty} from "../../../utils/helpers/app.helpers";
import {setServiceReport} from "../../../utils/helpers/action.helpers";
import {PARTS_DETAILS_TABLE} from "../../../utils/structures/serviceReport.structures";

const PartsTableCard = (props) => {
    const dispatch = useDispatch();
    const [rows, setRows] = useState([0]);
    const fields = useSelector(state => state.fields);
    const serviceReportDetails = useSelector(state => state.serviceReport);
    const userPermissions = useSelector(state => state.userPermissions);
    const isEditable = props.isEditable;

    function addRow() {
        if(rows.length <= 9) {
            let _rows = [...rows];
            _rows[_rows.length] = _rows.length;

            setRows(_rows);
        }

        if(rows.length > serviceReportDetails.parts_table.length) {
            let _fields = {
                parts_table: Object.assign([], {
                    ...serviceReportDetails.parts_table,
                    [serviceReportDetails.parts_table.length]: {
                        ...serviceReportDetails.parts_table[serviceReportDetails.parts_table.length],
                        'qty': null,
                        'part': null,
                        'desc': null,
                        'location': null,
                        'discount': null,
                        'price': null,
                        'total_price': null,
                        "index": serviceReportDetails.parts_table.length
                    },
                }),
            }

            props.updateServiceReport(_fields);
            dispatch(setServiceReport(_fields));
        }
    }

    return (
        <div className="mb-3">
            {rows.map((index) =>
                <div className="border border-dark p-2 mb-3">
                    <h4 className="mb-3">Row {index+1}:</h4>
                    <hr/>
                    {PARTS_DETAILS_TABLE.map((part, partIndex) =>
                        <div className="d-flex pb-1">
                            {userPermissions.parts_table[part.constant] && <div className="col-3">{part.constant !== "clear" && `${part.name}:`}</div> }
                            <div className="col-9 mb-1">
                                {part.object === "location" && userPermissions.parts_table.location ?
                                    <React.Fragment>
                                        <select
                                            id={`${part.object}`}
                                            name={`${part.object}`}
                                            value={!isObjectEmpty(serviceReportDetails) && serviceReportDetails.parts_table[index][part.object]}
                                            onChange={(e) => props.handleChange(e, index)}
                                            className="form-control"
                                            disabled={isEditable}
                                        >
                                            <option className="text-center">...</option>

                                            {!isObjectEmpty(fields) && Object.assign([], fields).map(list => (
                                                <option key={list.id} value={list.field_name}>{list.field_name}</option>
                                            ))}
                                        </select>
                                    </React.Fragment>
                                : part.object === "price" && userPermissions.parts_table.price ?
                                    <React.Fragment>
                                        <TextInput
                                            name={`${part.object}`}
                                            value={!isObjectEmpty(serviceReportDetails) && serviceReportDetails.parts_table[index][part.object]}
                                            onChange={(e) => props.handleChange(e, index)}
                                            disabled={true}
                                            className=""
                                        />
                                    </React.Fragment>
                                : part.object === "discount" && userPermissions.parts_table.discount ?
                                    <TextInput
                                        name={`${part.object}`}
                                        value={!isObjectEmpty(serviceReportDetails) && serviceReportDetails.parts_table[index][part.object]}
                                        onChange={(e) => props.handleChange(e, index)}
                                        disabled={isEditable || part.disabled}
                                        className=""
                                    />
                                : part.object === "clear" && userPermissions.parts_table.clear ?
                                    <button type="button" className={`btn btn-warning w-100 mt-2 p-1 rounded-0`} role={isEditable ? '' : 'button'} onClick={() => isEditable ? null : props.clearRow(index)}>Clear Row</button>
                                : userPermissions.parts_table[part.object] &&
                                    <TextInput
                                        name={`${part.object}`}
                                        value={!isObjectEmpty(serviceReportDetails) && serviceReportDetails.parts_table[index][part.object]}
                                        onChange={(e) => props.handleChange(e, index)}
                                        disabled={isEditable || part.disabled}
                                        className=""
                                    />
                                }
                            </div>
                        </div>
                    )}
                </div>
            )}

            {(props.actionType !== "view" && props.actionType !== "revision") &&
                <div>
                    <button type="button" className="btn btn-light w-100 border border-1" onClick={() => addRow()}>Add Row</button>
                </div>
            }
        </div>
    );
};

export default PartsTableCard;
