import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import TextInput from "../../form/TextInput";
import {postAuthenticated} from "../../../utils/actions/post.actions";
import {setUserProfile} from "../../../utils/helpers/action.helpers";
import {isEmpty, isObjectEmpty} from "../../../utils/helpers/app.helpers";
import {USER_ROLES} from "../../../utils/structures/users.structures";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";

import "./../../../styles/modals/modal.custom.css";
import {setModal} from "../../../utils/slices/modal.slice";
import FleetBatchModal from "../fleetModals/FleetBatchModal";
import Confirmation from "../../common/Confirmation";

const UserModal = (props) => {
    const dispatch = useDispatch();
    const userProfile = useSelector(state => state.userProfile);

    const [init, setInit] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [openEdit, setOpenEdit] = useState(true);
    const [errors, setErrors] = useState({});
    const [userFleetRecords, setUserFleetRecords] = useState({});
    const [user, setUser] = useState({});

    useEffect(() => {
        if(props.selectedUser && props.selectedUser.id > 0) {
            setUser(props.selectedUser);
            getFleetBatchByUserId();
        }else{
            getDefaultUser();
        }
    }, [props.selectedUser]);

    function handleChange(event) {
        setUser({...user, [event.target.name]: event.target.value});
    }

    function determineSelectedRole(role) {
        if(props.modalAction === "create") {
            if(role.id === null) {
                return true
            }
        }else{
            if(user.role == role.id) {
                return true;
            }
        }
    }

    function validateForm() {
        let _errors = {};
        let requiredFields = ['name', 'username', 'email', 'role'];
        if(props.modalAction === "create") {
            requiredFields.push('password');
        }

        Object.keys(user).forEach(field => {
            if(requiredFields.includes(field) && isEmpty(user[field])) _errors[field] = "Field cannot be empty";
        });

        let validateUsername = /^[a-zA-Z0-9_]+$/;
        if(!validateUsername.test(user.username)){ _errors["username"] = "Only characters A-Z, a-z, '_' and numbers are acceptable"; }

        setErrors(_errors);
        if(isObjectEmpty(_errors)) {
            props.modalAction === "create" ? createUser() : updateUser();
        }else{
            let ele = document.getElementById(Object.keys(_errors)[0]);
            if(ele) {
                ele.focus();
            }
        }
    }

    function getDefaultUser() {
        let url = API_ENDPOINT + "v1/Users/getDefaultUser";
        let params = {}

        postAuthenticated(url, params).then(results => {
            setUser(results.data);
            setInit(true);
        }).catch();
    }

    function createUser() {
        let url = API_ENDPOINT + 'v1/Users/createUser';
        let params = {
            user: user
        }

        postAuthenticated(url, params).then(r => {
            props.onClose();
        }).catch();
    }

    function updateUser() {
        let url = API_ENDPOINT + 'v1/Users/updateUser';
        let params = {
            user: user
        }

        postAuthenticated(url, params).then(r => {
            if(userProfile.id == props.selectedUser.id) {
                const _userProfile = {...userProfile, ...user};
                dispatch(setUserProfile(_userProfile));
            }
            props.onClose();
        }).catch();
    }

    function getFleetBatchByUserId() {
        let url = API_ENDPOINT + "v1/Fleets/getFleetBatchByUserId";
        let params = {
            user_id: props.selectedUser.id,
            search_term: ""
        }

        postAuthenticated(url, params).then(results => {
            setUserFleetRecords(results.data);
            setInit(true);
        })
    }

    function confirmDeactivation() {
        let _user = {...user};
        _user.is_active = 0;

        let url = API_ENDPOINT + 'v1/Users/updateUser';
        let params = {
            user: _user
        }

        postAuthenticated(url, params).then(r => {
            props.onClose();
        }).catch();
    }

    function launchFleetModal() {
        let modalSettings = {
            config: {
                component: FleetBatchModal,
                props: {
                    action: "edit",
                    user_id: userFleetRecords.user_id
                }
            },
            onClose: () => {}
        }
        dispatch(setModal(modalSettings));
    }

    function launchUserDeactivationConfirmation() {
        let modalSettings = {
            config: {
                title: `Are you sure you would like to deactivate user ${user.name}?`,
                component: Confirmation,
                props: {
                    confirmMessage: "Deactivate",
                    declineMessage: "Cancel",
                    onConfirm: () => confirmDeactivation(),
                    onDecline: () => {}
                }
            },
            onClose: () => {}
        }
        dispatch(setModal(modalSettings));
    }

    return (
        init &&
        <div className="modal d-block bg-dark bg-opacity-50" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog mw-80 modal-dialog-scrollable modal-entrance">
                <div className="modal-content w-80 h-80 m-auto">
                    <div className="modal-header d-block p-3">
                        <div className="d-flex">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                {props.modalAction == "view" && "Profile Details"}
                                {props.modalAction == "create" && "Create New User"}
                            </h3>
                            <button onClick={() => props.onClose()}
                                    className="border border-0 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                <i className="bi bi-x-lg text-secondary-emphasis" />
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <h4 className="lead fs-16 fst-italic pb-0 text-secondary">
                            {props.modalAction == "view" &&
                                <React.Fragment>
                                    for <span className="text-decoration-underline fw-light">{props.selectedUser.name} | {props.selectedUser.email}</span>
                                </React.Fragment>}
                        </h4>
                    </div>

                    <div className={`modal-body w-95 m-auto`} style={{overflowY: "scroll"}}>
                        <div className="w-95 m-auto">
                            <div className="d-flex">
                                <div className="col-6 m-auto">
                                    <TextInput
                                        name="name"
                                        label="Full name:"
                                        placeholder="Full Name"
                                        value={user.name}
                                        onChange={e => handleChange(e)}
                                        error={errors.name}
                                        disabled={!openEdit}
                                        className="w-95 form-control mb-0"
                                        required={true}
                                    />
                                </div>

                                <div className="col-6 m-auto">
                                    <TextInput
                                        name="username"
                                        label="Username:"
                                        placeholder="Username"
                                        value={user.username}
                                        onChange={e => handleChange(e)}
                                        error={errors.username}
                                        disabled={!openEdit}
                                        className="w-100 form-control"
                                        required={true}
                                    />
                                </div>
                            </div>

                            <TextInput
                                name="email"
                                label="Email address:"
                                placeholder="Email address"
                                value={user.email}
                                onChange={e => handleChange(e)}
                                error={errors.email}
                                disabled={!openEdit}
                                labelClassName="mt-3"
                                className="w-100 form-control"
                                required={true}
                            />

                            {isObjectEmpty(props.selectedUser) &&
                                <React.Fragment>
                                    <TextInput
                                        type={showPassword ? 'text' : 'password'}
                                        name="password"
                                        label="Password:"
                                        placeholder="Password"
                                        value={user.password}
                                        onChange={e => handleChange(e)}
                                        error={errors.password}
                                        disabled={!openEdit}
                                        className="w-100 form-control"
                                        labelClassName="mt-3"
                                        required={true}
                                    />
                                    <i className={`position-relative float-end ${!showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'} align-self-center mt-1 fs-5`} onClick={() => setShowPassword(!showPassword)} style={{top: "-37px", right: "10px"}} />
                                </React.Fragment>
                            }

                            <TextInput
                                name="company_name"
                                label="Company name:"
                                placeholder="Company name"
                                value={user.company_name}
                                onChange={e => handleChange(e)}
                                disabled={!openEdit}
                                className="w-100 form-control"
                                labelClassName="mt-3"
                                required={true}
                            />

                            <TextInput
                                name="phone"
                                label="Phone No:"
                                placeholder="Phone No"
                                value={user.phone}
                                onChange={e => handleChange(e)}
                                disabled={!openEdit}
                                className="w-100 form-control"
                                labelClassName="mt-3"
                            />

                            <TextInput
                                name="address"
                                label="Address:"
                                placeholder="Address"
                                value={user.address}
                                onChange={e => handleChange(e)}
                                disabled={!openEdit}
                                className="w-100 form-control"
                                labelClassName="mt-3"
                            />

                            <TextInput
                                name="account_number"
                                label="Account Number:"
                                placeholder="Account Number"
                                value={user.account_number}
                                onChange={e => handleChange(e)}
                                disabled={!openEdit}
                                className="w-100 form-control"
                                labelClassName="mt-3"
                            />

                            <label className="mt-3"></label>
                            <TextInput
                                name="rta_no"
                                label="RTA No:"
                                placeholder="RTA No"
                                value={user.rta_no}
                                onChange={e => handleChange(e)}
                                disabled={!openEdit}
                                className="w-100 form-control"
                                labelClassName="mt-3"
                            />

                            <TextInput
                                name="trading_terms"
                                label="Trading Terms:"
                                placeholder="Trading Terms"
                                value={user.trading_terms}
                                onChange={e => handleChange(e)}
                                disabled={!openEdit}
                                className="w-100 form-control"
                                labelClassName="mt-3"
                            />

                            {userProfile.id != user.id &&
                                <React.Fragment>
                                    <label className="mt-3 lead form-label fs-16 p-0 m-0 mb-1">Roles:</label><span className="text-danger"> *</span>
                                    <select name="role" onChange={(e) => handleChange(e)} disabled={!openEdit} className={`form-control ${errors['role'] ? 'border border-danger' : ''}`} required={true}>
                                        {USER_ROLES.map((role,index) =>
                                            role.id === 4 && (userProfile.role != 4 && props.selectedUser.role != 4) ? null :
                                            <option key={index} value={role.id} selected={determineSelectedRole(role)}>{role.label}</option>
                                        )}
                                    </select>
                                    {errors['role'] && <p className="text-danger lead fs-6">{errors['role']}</p> }
                                </React.Fragment>
                            }
                        </div>

                        {user.id > 0 && userProfile.id != user.id &&
                            <div className="text-danger text-center mt-4">
                                <span role="button" onClick={() => launchUserDeactivationConfirmation()}>Deactivate this user</span>
                            </div>
                        }
                    </div>

                    <div className="modal-footer flex-nowrap justify-content-start items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                        <div className="col-6">
                            <button
                                className="btn btn-primary bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                onClick={() => validateForm()}>{props.modalAction == "create" ? 'Create' : props.modalAction == "view" ? 'Update' : null}
                            </button>

                            <button
                                className="ms-2 btn text-secondary-emphasis border border-secondary-subtle bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                onClick={() => props.onClose()}>Close
                            </button>
                        </div>

                        <div className="col-6 text-end">
                            {props.modalAction == "view" && props.selectedUser.role == 0 &&
                                <button
                                    className="me-2 btn btn-primary bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    onClick={() => launchFleetModal()}>View fleet records
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserModal;
