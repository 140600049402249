import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";

import "../../../styles/modals/serviceReportFieldModal.scss";
import * as serviceReportModalConstants from "../../../utils/constants/serviceReportModal.constants";

import TextInput from "../../form/TextInput";
import {postAuthenticated} from "../../../utils/actions/post.actions";
import {isEmpty} from "../../../utils/helpers/app.helpers";
import {setFields, setServiceReport} from "../../../utils/helpers/action.helpers";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";

const FieldModal = ({setIsFieldModalOpen, updateServiceReport, selectedInputIndex}, ...props) => {
    const dispatch = useDispatch();
    const fields = useSelector(state => state.fields);
    const serviceReportDetails = useSelector(state => state.serviceReport);

    const newFieldInput = React.createRef();
    const [newFieldNameDetails, setNewFieldNameDetails] = useState({});
    const [isFieldEditEnabled, setIsFieldEditEnabled] = useState(false);
    const [isAddingNewField, setIsAddingNewField] = useState(false);

    useEffect(() => {
        if(isAddingNewField) {
            newFieldInput.current.scrollIntoView();
        }
    }, [isAddingNewField])

    function handleNewChange(event) {
        setNewFieldNameDetails({...newFieldNameDetails, [event.target.name]: event.target.value})
    }

    function handleChange(list, index, event) {
        if(!isEmpty(event.target.value)) {
            updateFieldById(list, event.target.value);
        }

        let _locationFields = {
            [index]: {...fields[index], ["field_name"]: event.target.value}
        }
        dispatch(setFields(_locationFields));

        if(!isEmpty(serviceReportDetails.parts_table[selectedInputIndex]["location"])) {
            let _fields = {
                parts_table: Object.assign([], {
                    ...serviceReportDetails.parts_table,
                        [selectedInputIndex]: {
                            ...serviceReportDetails.parts_table[selectedInputIndex],
                                ['location']: event.target.value
                    },
                })
            }
            dispatch(setServiceReport(_fields));
        }
    }

    function handleAddField(list) {
        let _fields = {
            parts_table: Object.assign([], {
                ...serviceReportDetails.parts_table,
                [selectedInputIndex]: {
                    ...serviceReportDetails.parts_table[selectedInputIndex],
                    ['location']: list.field_name
                }
            })
        }

        dispatch(setServiceReport(_fields));
        updateServiceReport(_fields);
        setIsFieldModalOpen(false)
    }

    function removeCurrentField() {
        let _fields = {
            parts_table: Object.assign([], {
                ...serviceReportDetails.parts_table,
                [selectedInputIndex]: {
                    ...serviceReportDetails.parts_table[selectedInputIndex],
                    ['location']: ""
                }
            })
        }

        dispatch(setServiceReport(_fields));
        updateServiceReport(_fields);
        setIsFieldModalOpen(false)
    }

    function onSaveField() {
        if(!isEmpty(newFieldNameDetails.field_name)) {
            setIsAddingNewField(!isAddingNewField);
            createField();
        }
    }

    function createField() {
        let url = API_ENDPOINT + 'v1/Fields/createField';
        let params = {
            field_name: newFieldNameDetails.field_name
        }

        postAuthenticated(url, params).then(r => {}).catch();
    }

    function updateFieldById(field, value) {
        let url = API_ENDPOINT + 'v1/Fields/updateFieldById';
        let params = {
            field_id: field.id,
            field_name: value
        }

        postAuthenticated(url, params).then(r => {}).catch();
    }

    function deleteFieldById(field) {
        let url = API_ENDPOINT + 'v1/Fields/deleteFieldById';
        let params = {
            field_id: field.id
        }

        postAuthenticated(url, params).then(r => {}).catch();
    }

    return (
        <div className="modal d-block in" tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-xl modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="col-12 d-flex">
                            <div className="col-8">
                                <h1>Fields</h1>
                            </div>
                            <div className="col-4 d-flex justify-content-end align-self-center">
                                {!isAddingNewField ?
                                    !isFieldEditEnabled
                                        ? <button type="button" className="btn btn-primary me-3" onClick={() => setIsFieldEditEnabled(true)}>Open Edit</button>
                                        : <button type="button" className="btn btn-primary me-3"  onClick={() => setIsFieldEditEnabled(false)}>Close edit</button>
                                : null}

                                {!isFieldEditEnabled
                                    ? isAddingNewField
                                        ? <button type="button" className="btn btn-warning me-3" onClick={() => setIsAddingNewField(!isAddingNewField)}>Cancel</button>
                                        : <button type="button" className="btn btn-warning me-3" onClick={() => setIsAddingNewField(!isAddingNewField)}>Add New</button>
                                : null}

                                {isAddingNewField &&
                                    <button type="button" className="btn btn-warning" onClick={() => onSaveField()} disabled={isEmpty(newFieldNameDetails.field_name)}>
                                        Save field
                                    </button>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="modal-body" style={{overflowY: "scroll", maxHeight: "300px"}}>
                        <table className="w-100 modal-table col-12">
                            <thead>
                                <tr style={{borderBottom: "1px solid black"}}>
                                    <th>{serviceReportModalConstants.FIELD_NAME_LABEL}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.assign([], fields).map((list, index) => (
                                    <tr key={list.id}>
                                        <td className="col-5">
                                            {!isFieldEditEnabled
                                                ? list.field_name
                                                : <TextInput
                                                    name="field_name"
                                                    value={list.field_name}
                                                    onChange={(e) => handleChange(list, index, e)}
                                                    className="col-5"
                                                    style={{width: "97%"}}
                                                  />
                                            }
                                        </td>
                                        <td className="col-1">
                                            <button type="button" className="w-75 btn btn-success" disabled={isEmpty(list.field_name)} onClick={!isEmpty(list.field_name) ? () => handleAddField(list) : null}>Add</button>
                                        </td>
                                        {/*
                                        <td className="col-1">
                                            <button className="w-75 btn btn-danger" onClick={() => deleteFieldById(list)}>Delete</button>
                                        </td>
                                        */}
                                    </tr>
                                ))}
                                {isAddingNewField &&
                                    <tr><td ref={newFieldInput}>
                                        <TextInput
                                            name="field_name"
                                            value={newFieldNameDetails.field_name}
                                            onChange={(e) => handleNewChange(e)}
                                            autoFocus={isAddingNewField}
                                            className="w-50"
                                        />
                                    </td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer">
                        {!isEmpty(serviceReportDetails.parts_table[selectedInputIndex]['locations']) &&
                            <button type="button" className="btn btn-outline-dark" data-dismiss="modal" onClick={() => removeCurrentField()}>Remove current field</button>
                        }
                        <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={() => setIsFieldModalOpen(false)}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FieldModal;
