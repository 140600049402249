import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import * as serviceReportConstants from "../../../utils/constants/serviceReport.constants";
import CheckboxInput from "../../form/CheckboxInput";
import {isObjectEmpty} from "../../../utils/helpers/app.helpers";
import {setServiceReport} from "../../../utils/helpers/action.helpers";
import {SERVICE_CHECKLIST_MAINTENANCE} from "../../../utils/structures/serviceReport.structures";

const ServiceChecklist = (props) => {
    const dispatch = useDispatch();
    const serviceReportDetails = useSelector(state => state.serviceReport);
    const [serviceChecklistMaintenance, setServiceChecklistMaintenance] = useState(SERVICE_CHECKLIST_MAINTENANCE);

    function handleToggle(event, fieldName) {
        let _fields = {
            "service_checklist_maintenance": {...serviceReportDetails.service_checklist_maintenance, [fieldName]: !serviceReportDetails.service_checklist_maintenance[fieldName]}
        }

        dispatch(setServiceReport(_fields));
        props.updateServiceReport(_fields);
    }

    return (
        <div>
            <div className="border border-dark p-2">
                <b>{serviceReportConstants.SERVICE_CHECKLIST_MAINTENANCE_DESCRIPTION_LABEL}</b>
            </div>
            {!isObjectEmpty(serviceReportDetails.service_checklist_maintenance) && serviceChecklistMaintenance.map(list =>
                <div key={list.constant} className="d-flex border border-dark p-2">
                    <div className="col-11">
                        <span>{list.label}</span>
                    </div>

                    <div className="col-1 text-center">
                        <CheckboxInput
                            name={list.constant}
                            value={serviceReportDetails.service_checklist_maintenance[list.constant]}
                            checked={serviceReportDetails.service_checklist_maintenance[list.constant]}
                            onChange={(e) => handleToggle(e, [list.constant])}
                            disabled={props.isEditable}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ServiceChecklist;
