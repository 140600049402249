import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import "../../../styles/users/users.scss";

import Back from "../../common/Back";
import Loader from "../../common/Loader";
import UserModal from "../../modals/userModals/UserModal";
import UserDevicesModal from "../../modals/userModals/UserDevicesModal";
import UserPasswordModal from "../../modals/userModals/UserPasswordModal";
import UserPermissionsModal from "../../modals/userModals/UserPermissionsModal";

import {setModal} from "../../../utils/slices/modal.slice";
import {postAuthenticated} from "../../../utils/actions/post.actions";
import {USER_ROLES} from "../../../utils/structures/users.structures";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";

const Users = () => {
    const dispatch = useDispatch();
    const userProfile = useSelector(state => state.userProfile);

    const [init, setInit] = useState(false);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        getUsers();
    }, []);

    function getUsers() {
        let url = API_ENDPOINT + "v1/Users/getUsers"
        let params = {}

        postAuthenticated(url, params).then(results => {
            setUsers(results.data);
            setInit(true);
        })
    }

    function getUserRoleById(user_role_id) {
        let role = USER_ROLES.find(x => x.id == user_role_id);
        return role.label;
    }

    function launchUserPasswordModal(user) {
        let modalSettings = {
            config: {
                component: UserPasswordModal,
                props: {
                    selectedUser: user,
                }
            },
            onClose: () => {}
        }

        dispatch(setModal(modalSettings));
    }

    function launchUserPermissionsModal(user) {
        let modalSettings = {
            config: {
                component: UserPermissionsModal,
                props: {
                    selectedUser: user,
                }
            },
            onClose: () => {}
        }

        dispatch(setModal(modalSettings));
    }

    function launchUserModal(modalAction, user) {
        let modalSettings = {
            config: {
                component: UserModal,
                props: {
                    modalAction: modalAction,
                    selectedUser: user,
                }
            },
            onClose: () => {
                getUsers();
            }
        }

        dispatch(setModal(modalSettings));
    }

    function launchUserDevicesModal(user) {
        let modalSettings = {
            config: {
                component: UserDevicesModal,
                props: {
                    user: user
                }
            },
            onClose: () => {
                getUsers();
            }
        }

        dispatch(setModal(modalSettings));
    }

    return (
        init ?
            <div className="w-80 m-auto mt-4">
                <h2 id="filters" className="lead fs-32">Users</h2>

                <div className="d-flex mb-2">
                    <div className="col-6 text-secondary-emphasis fs-14 align-self-end">Results: {users ? users.length : 0}</div>
                    <div className="col-6 text-end">
                        {userProfile.role == 4 &&
                            <div className="text-end">
                                <button className="btn btn-primary rounded-0" onClick={() => launchUserModal('create', {})}>Create User</button>
                            </div>
                        }
                    </div>
                </div>

                <table className="user-table table table-striped m-auto mb-3 lead fs-14">
                    <thead className="border border-start-0 border-end-0" style={{border: "1px solid blue"}}>
                        <tr className="border border-1">
                            <th scope="col">Role</th>
                            <th scope="col">Customer</th>
                            <th scope="col">Address</th>
                            <th scope="col">Full Name</th>
                            <th scope="col">Email Address</th>
                            <th scope="col" />
                        </tr>
                    </thead>
                    <tbody className="border border-0">
                        {users && users.map((user, index) =>
                            <tr className="lead text-secondary-emphasis fs-14 align-middle" key={index}>
                                <td>{getUserRoleById(user.role)}</td>
                                <td>{user.company_name}</td>
                                <td>{user.address}</td>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>
                                    {userProfile.role == 4 &&
                                        <i title="Permissions" role="button" className="bg-warning rounded fs-5 p-1 me-2 fa fa-key icon-white" onClick={() => launchUserPermissionsModal(user)}/>
                                    }
                                    {userProfile.role != 4 && user.role == 4 ? null :
                                        <i title="View" role="button" className="bg-success rounded fs-5 p-1 me-2 fa fa-eye icon-white" onClick={() => launchUserModal("view", user)}/>
                                    }
                                    {userProfile.role != 4 && user.role == 4 ? null :
                                        <i title="Password" role="button" className="bg-primary rounded fs-5 px-2 py-1 me-2 fa fa-lock icon-white" onClick={() => launchUserPasswordModal(user)}/>
                                    }

                                    {userProfile.role == 4 ?
                                        <i title="Devices" role="button" className="bg-dark rounded fs-5 p-1 px-2 me-2 fa fa-mobile icon-white" onClick={() => launchUserDevicesModal(user)} />
                                    :null}

                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>

                <Back />
            </div>
        : <Loader />
    );
};

export default Users;
