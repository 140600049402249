import React, {useEffect, useState} from 'react';

import "../../../styles/materialise/materialize.css";

import Back from "../../common/Back";

import {postAuthenticated} from "../../../utils/actions/post.actions";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";
import Loader from "../../common/Loader";

const Timesheet = () => {
    const [init, setInit] = useState(false);
    const [userTimesheets, setUserTimesheets] = useState([]);

    useEffect(() => {
        GetTimesheets();
    }, []);

    function GetTimesheets() {
        let url = API_ENDPOINT + "v1/UserTimesheets/GetTimesheets";
        let params = {};

        postAuthenticated(url, params).then(results => {
            setUserTimesheets(results.data);
            setInit(true);
        });
    }

    return (
        init ?
        <div className="w-90 m-auto">
            <div id="use-materialise" className="w-90 m-auto mt-4 pb-5">
                <h2 id="filters" className="lead fs-32 m-0">Timesheet</h2>
            </div>


            <Back />
        </div>
        : <Loader />
    );
};

export default Timesheet;