import React from 'react';

import "../../styles/materialise/materialize.css";

const MaterializeLoader = () => {
    return (
        <div id="use-materialise" className="preloader-wrapper small active top-5">
            <div className="spinner-layer spinner-white-only">
                <div className="circle-clipper left">
                    <div className="circle"></div>
                </div>
                <div className="gap-patch">
                    <div className="circle"></div>
                </div>
                <div className="circle-clipper right">
                    <div className="circle"></div>
                </div>
            </div>
        </div>
    );
};

export default MaterializeLoader;