import React from 'react';
import "../../../styles/modals/serviceReportRecordsModal.scss";
import * as serviceReportModalConstants from "../../../utils/constants/serviceReportModal.constants";
import {useNavigate} from "react-router-dom";

const RecordsModal = (props) => {
    const navigate = useNavigate();

    function selectReport(list) {
        props.setOpenModal(false);
        //navigate(`/service-report/edit/${list.id}`);
    }

    function handleCancelDecision() {
        props.setOpenModal(false);
    }

    return (
        <div className="modal d-block" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1>Last RCF Saved</h1>
                    </div>

                    <div className="modal-body" style={{overflowY: "scroll", minHeight: "500px", maxHeight: "500px"}}>
                        <table className="w-100 text-start modal-table">
                            <thead>
                                <tr className="">
                                    <th>{serviceReportModalConstants.SERVICE_ID_LABEL}</th>
                                    <th>{serviceReportModalConstants.FLEET_NO_LABEL}</th>
                                    <th>{serviceReportModalConstants.SRNO_LABEL}</th>
                                    <th>{serviceReportModalConstants.START_DATE_LABEL}</th>
                                    <th>{serviceReportModalConstants.USE_THIS_LABEL}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.serviceRecordsList.map(list =>
                                    <tr key={list.id}>
                                        <td>{list.id}</td>
                                        <td>{list.fleet_no}</td>
                                        <td>{list.srno}</td>
                                        <td>{list.start_date}</td>
                                        <td>
                                            <button className="w-75 btn btn-danger" onClick={() => selectReport(list)}>Edit this report</button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-primary" onClick={() => handleCancelDecision()}>Continue with New</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecordsModal;
