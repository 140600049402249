import {createSlice} from "@reduxjs/toolkit";

const serviceLogTrackerSlice = createSlice({
    name: "serviceLogTracker",
    initialState: {
        lastSelectedLog: {},
        lastSelectedRevision: {},
        search_term: ""
    },
    reducers: {
        updateServiceLogTrackerSlice: (state, {payload}) => {
            return {
                ...state,
                ...payload
            };
        },
    }
})

export const {updateServiceLogTrackerSlice} = serviceLogTrackerSlice.actions;
export default serviceLogTrackerSlice.reducer;
