import $ from "jquery"

export function isObjectEmpty(obj) {
    return obj === null || obj === {} || obj === undefined || $.isEmptyObject(obj);
}

export function isEmpty(val) {
    return val === "" || val === undefined || val === null;
}

export function removeTags(str) {
    if ((str == null) || (str == ''))
        return false;
    else
        str = str.toString();

    return str.replace( /(<([^>]+)>)/ig, '');
}