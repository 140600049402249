import React, {useEffect, useState} from 'react';
import TextInput from "../../form/TextInput";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";
import {postAuthenticated} from "../../../utils/actions/post.actions";

const InvoiceModal = (props) => {
    const [init, setInit] = useState(false);
    const [reportColors, setReportColors] = useState([]);
    const [selectedReportColor, setSelectedReportColor] = useState({});
    const [invoiceDetails, setInvoiceDetails] = useState({
        invoice_no: props.sr.invoice_no,
        invoice_date: props.sr.invoice_date
    });

    useEffect(() => {
        getReportColors();
    }, []);

    function handleChange(event) {
        setInvoiceDetails({...invoiceDetails, [event.target.name]: event.target.value});
    }

    function updateSelectedReportColor(event) {
        let _selectedReportColor = reportColors.find(x => x.constant === event.target.value);
        setSelectedReportColor(_selectedReportColor);
    }

    function saveInvoiceDetails() {
        let fields = {
            invoice_no: invoiceDetails.invoice_no,
            invoice_date: invoiceDetails.invoice_date,
            service_status: selectedReportColor.id
        };
        saveServiceReport(fields);
    }

    function saveServiceReport(_fields) {
        let url = API_ENDPOINT + "v1/ServiceReports/saveServiceReport"
        let params = {
            service_report_id: props.sr.id,
            fields: _fields
        };

        postAuthenticated(url, params).then(results => {
            props.onClose();
        });
    }

    function getReportColors() {
        setInit(false);
        let url = API_ENDPOINT + 'v1/ReportColors/getReportColors';
        let params = {}

        postAuthenticated(url, params).then(results => {
            setReportColors(results.data);

            let _reportColors = results.data;
            if (props.sr.service_status == 2) {
                let rcf = _reportColors.find(x => x.constant == "rcf");
                setSelectedReportColor(rcf);
            }else{
                let invoiced = _reportColors.find(x => x.constant == "invoiced");
                setSelectedReportColor(invoiced);
            }
            setInit(true);
        });
    }

    return (
        init &&
        <div className="modal d-block bg-dark bg-opacity-50" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog mw-80 modal-dialog-scrollable modal-entrance">
                <div className="modal-content w-80 m-auto bg-light">
                    <div className="modal-header d-block p-3">
                        <div className="d-flex">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Invoice Details</h3>
                            <button onClick={() => props.onClose()}
                                    className="border border-0 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                <i className="bi bi-x-lg text-secondary-emphasis" />
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <h4 className="lead fs-16 fst-italic pb-0 text-secondary">for <span className="text-decoration-underline fw-light">{props.sr.srno}</span></h4>
                    </div>

                    <div className="modal-body w-95 m-auto" style={{overflowY: "scroll", minHeight: "400px", maxHeight: "600px"}}>
                        <div className="w-50 m-auto">
                            <TextInput
                                label="Invoice No:"
                                name="invoice_no"
                                value={invoiceDetails.invoice_no}
                                onChange={(e) => handleChange(e)}
                                className="form-control"
                            />
                        </div>

                        <div className="mt-4 w-50 m-auto">
                            <label className="lead form-label fs-16 p-0 m-0 mb-1">Invoice date:</label>
                            <input
                                type="date"
                                name="invoice_date"
                                value={invoiceDetails.invoice_date}
                                onChange={(e) => handleChange(e)}
                                className="form-control"
                            />
                        </div>

                        <div className="w-50 m-auto mt-4">
                            <label className="lead form-label fs-16 p-0 m-0 mb-1">Report status:</label>
                            <select className="form-control" onChange={(e) => updateSelectedReportColor(e)} style={{border: `1.8px solid ${selectedReportColor.color}`}}>
                                {reportColors.map((status, index) =>
                                    <option value={status.constant} selected={status.constant == selectedReportColor.constant}>{status.report_status}</option> )}
                            </select>
                        </div>
                    </div>

                    <div className="modal-footer justify-content-start items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                        <button className="btn btn-primary bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={() => saveInvoiceDetails()}>Save</button>
                        <button
                            className="btn text-secondary-emphasis border border-secondary-subtle bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            onClick={() => props.onClose()}>Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InvoiceModal;
