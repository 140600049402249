import React from 'react';
import * as serviceReportModalConstants from "../../../utils/constants/serviceReportModal.constants";
import {useSelector} from "react-redux";
import TextInput from "../../form/TextInput";

const PartRowDetailsModal = (props) => {
    const serviceReportDetails = useSelector(state => state.serviceReport);

    return (
        <div className="modal d-block" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1>Part Row - {props.selectedPartRowIndex+1}</h1>
                    </div>

                    <div className="modal-body" style={{overflowY: "scroll", maxHeight: "500px"}}>
                        <div className="m-auto">
                            <label className="fw-bold">Qty: </label>
                            <span> {serviceReportDetails.parts_table[props.selectedPartRowIndex]['qty']}</span>
                        </div>

                        <div className="mt-3">
                            <label className="fw-bold">Part No: </label>
                            <span> {serviceReportDetails.parts_table[props.selectedPartRowIndex]['part']}</span>
                        </div>

                        <div className="mt-3">
                            <label className="fw-bold">Description: </label>
                            <span> {serviceReportDetails.parts_table[props.selectedPartRowIndex]['desc']}</span>
                        </div>

                        <div className="mt-3">
                            <label className="fw-bold">Location: </label>
                            <span> {serviceReportDetails.parts_table[props.selectedPartRowIndex]['location']}</span>
                        </div>

                        <div className="mt-3">
                            <label className="fw-bold">Discount: </label>
                            <span> {serviceReportDetails.parts_table[props.selectedPartRowIndex]['discount']}</span>
                        </div>

                        <div className="mt-3">
                            <label className="fw-bold">Price: </label>
                            <span> {serviceReportDetails.parts_table[props.selectedPartRowIndex]['price']}</span>
                        </div>

                        <div className="mt-3">
                            <label className="fw-bold">Total Price: </label>
                            <span> {serviceReportDetails.parts_table[props.selectedPartRowIndex]['total_price']}</span>
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button className="btn btn-warning" onClick={() => props.setIsPartRowModalOpen()}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PartRowDetailsModal;
