import {createSlice} from "@reduxjs/toolkit";

const trackerSlice = createSlice({
    name: "tracker",
    initialState: {
        service_report_logs: {
            search_term: ""
        },
        fleet_logs: {
            search_term: ""
        },
        user_logs: {
            search_term: ""
        }
    },
    reducers: {
        setTracker: (state, {payload}) => {
            return {
                ...state,
                ...payload,
            };
        },
    }
})

export const {setTracker} = trackerSlice.actions;
export default trackerSlice.reducer;
