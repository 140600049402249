import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import moment from "moment/moment";

import Loader from "../../../common/Loader";
import TextInput from "../../../form/TextInput";
import {setTracker} from "../../../../utils/slices/tracker.slice";
import {postAuthenticated} from "../../../../utils/actions/post.actions";
import {USER_ROLES} from "../../../../utils/structures/users.structures";
import {API_ENDPOINT} from "../../../../utils/constants/app.constants";

const UserLogs = () => {
    const tracker = useSelector(state => state.tracker);

    const dispatch = useDispatch();
    const [init, setInit] = useState(false);
    const [userLogs, setUserLogs] = useState([]);

    useEffect(() => {
        searchUserLogs();
    }, []);

    useEffect(() => {
        searchUserLogs();
    }, [tracker.user_logs.search_term]);

    function searchUserLogs() {
        let url = API_ENDPOINT + "v1/Users/searchUserLogs";
        let params = {
            search_term: tracker.user_logs.search_term
        }

        postAuthenticated(url, params).then(results => {
            setUserLogs(results.data);
            setInit(true);
        });
    }

    function getCreatedDate(log) {
        let fromNow = moment(log.created).fromNow();
        let dateFormat = moment(log.created).format("DD/MM/YYYY");
        let timeFormat = moment(log.created).format("hh:mm:ss A");

        return `${fromNow} - ${dateFormat} ${timeFormat}`;
    }

    function updateTracker(event) {
        let _tracker = {
            ...tracker,
            user_logs: {
                ...tracker.user_logs,
                search_term: event.target.value
            }
        };
        dispatch(setTracker(_tracker));
    }

    return (
        <div className='main-container w-100'>
            <div>
                <hr />
                <h2 className="lead fs-1 p-2">User Logs</h2>

                <div className="w-100 m-auto input-group mb-2 mt-2">
                    <TextInput
                        name="search_term"
                        value={tracker.user_logs.search_term}
                        placeholder="Search..."
                        onChange={(e) => updateTracker(e)}
                    />
                </div>

                <div className="lead text-decoration-underline mt-3">Results: {userLogs.length}</div>

                {init ?
                    userLogs.length != 0 ?
                        <div>
                            {userLogs.map((log, index) =>
                                <div className="p-3 mt-3 mb-3 rounded" style={{border: "1px solid #CFCFCF"}}>
                                    <span className="fw-bold">{log.user.name}</span> has:
                                    <div>
                                        {log.user_id != 0 ?
                                            log.content.is_active == 0
                                                ? <div><span className="text-danger fw-bold">Deleted</span> this user record {getCreatedDate(log)}</div>
                                                : <div><span className="text-primary fw-bold">Updated</span> this user record {getCreatedDate(log)}</div>
                                            : <div><span className="text-success fw-bold">Created</span> this user record {getCreatedDate(log)}</div> }
                                    </div>
                                    <table className="mt-3 table table-bordered w-100 border border-1">
                                        <thead>
                                        <tr className="border border-1">
                                            <th>Name</th>
                                            <th>Username</th>
                                            <th>Email address</th>
                                            <th>Password</th>
                                            <th>Company</th>
                                            <th>Address</th>
                                            <th>Phone</th>
                                            <th>Account Number</th>
                                            <th>RTA Number</th>
                                            <th>Trading Terms</th>
                                            <th>Role</th>
                                            <th>Status</th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        <tr className="border border-1">
                                            <td>{log.content.name}</td>
                                            <td>{log.content.username}</td>
                                            <td>{log.content.email}</td>
                                            <td>****</td>
                                            <td>{log.content.company_name}</td>
                                            <td>{log.content.address}</td>
                                            <td>{log.content.phone}</td>
                                            <td>{log.content.account_number}</td>
                                            <td>{log.content.rta_no}</td>
                                            <td>{log.content.trading_terms}</td>
                                            <td>{USER_ROLES.find(x => x.id == log.content.role).label}</td>
                                            <td>{log.content.is_active == 0 ? 'Deleted' : 'Active'}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                        : <div className="mt-2">No user logs were found.</div>
                    : <Loader />
                }
            </div>
        </div>
    );
};

export default UserLogs;