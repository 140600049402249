import React from 'react';

const JobDetailsModal = (props) => {
    const job = props.job;

    return (
        <div className="modal d-block bg-dark bg-opacity-50" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog mw-80 modal-dialog-scrollable modal-entrance">
                <div className="modal-content w-80 m-auto bg-light">
                    <div className="modal-header d-block p-3">
                        <div className="d-flex">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Job Details</h3>
                            <button onClick={() => props.onClose()}
                                    className="border border-0 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                <i className="bi bi-x-lg text-secondary-emphasis" />
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                    </div>

                    <div className="modal-body w-95 m-auto" style={{overflowY: "scroll", minHeight: "400px", maxHeight: "600px"}}>
                        <div className="lead fs-24">Complaint:</div>
                        <p className="lead fs-14 text-secondary-emphasis">{job.content.description}</p>
                    </div>

                    <div className="modal-footer justify-content-start items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                        <button className="btn btn-primary bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={() => props.onClose()}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobDetailsModal;