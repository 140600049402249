import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import { debounce } from "lodash";

import "react-datepicker/dist/react-datepicker.css";

import Loader from "../../common/Loader";
import TextInput from "../../form/TextInput";
import TextAreaInput from "../../form/TextAreaInput";
import RecordsModal from "../../modals/serviceReportModals/RecordsModal";
import CustomerModal from "../../modals/serviceReportModals/CustomerModal";

import {isObjectEmpty} from "../../../utils/helpers/app.helpers";
import {postAuthenticated} from "../../../utils/actions/post.actions";
import {setServiceReport, setFleetServiceReports} from "../../../utils/helpers/action.helpers";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";

const BasicDetails = (props) => {
    const dispatch = useDispatch();

    const serviceReportDetails = useSelector(state => state.serviceReport);
    const [basicReportDetails, setBasicReportDetails] = useState({});
    const [allServiceReports, setAllServiceReports] = useState({});

    const [selectedCustomer, setSelectedCustomer] = useState({});
    const [showUsers, setShowUsers] = useState(false);
    const [userList, setUserList] = useState({});

    const [init, setInit] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openCustomerModal, setOpenCustomerModal] = useState(false);
    const layoutView = props.currentLayoutView;

    const inputFocusOrder = ["customer", "address", "start_date", "rego_no", "fleet_no", "po_no", "unit_model", "end_date", "distance_covered", "wo_no", "sr"];
    const [inputFocusDetails, setInputFocusDetails] = useState({
        current_element: "customer",
        next_element: "address",
        is_focused: false,
        date_now: Date.now()
    })

    const debouncedFleetNoSearch = useCallback(debounce(getFleetsByFleetNo, 2000), []);
    const debouncedServiceReportSearch = useCallback(debounce(getServiceReportDetailsByFleetNumber, 2000), []);

    useEffect(() => {
        const interval = setInterval(() => {
            checkFocusDuration(inputFocusDetails.current_element);
        }, 1000);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [inputFocusDetails])

    useEffect(() => {
        if(serviceReportDetails.s_status == 1 && !isObjectEmpty(basicReportDetails) && isObjectEmpty(selectedCustomer)){
            let _fields = {
                customer: basicReportDetails.company_name,
                address: basicReportDetails.address,
                rego_no: basicReportDetails.rego_no
            }

            dispatch(setServiceReport(_fields));
            props.updateServiceReport(_fields);
        }
    }, [basicReportDetails]);

    function hideUsersMenu(e) {
        setShowUsers(false);
        removeFocus();
    }

    function removeFocus() {
        setInputFocusDetails({...inputFocusDetails, is_focused: false});
    }

    function handleChange(event) {
        // reset timer
        setInputFocusDetails({...inputFocusDetails, 'date_now': Date.now()});

        let _fields = {
            [event.target.name]: event.target.value
        };

        if(event.target.name === "customer") {
            event.target.value.length === 0 ? setShowUsers(false) : setShowUsers(true);
            getCustomersBySearch(event.target.value);
        }

        if(event.target.name === "fleet_no") {
            debouncedServiceReportSearch(_fields);
            debouncedFleetNoSearch(_fields);
        }

        event.target.name === "rego_no" && setTimeout(function () {
            getServiceReportDataByRegoNumber(_fields);
        }, 0);

        dispatch(setServiceReport(_fields));
        props.updateServiceReport(_fields);
    }

    function handleToggle(event) {
        let value = event.target.value === "yes" ? "no" : "yes";
        let _fields = {
            [event.target.name]: value,
        }

        dispatch(setServiceReport(_fields));
        props.updateServiceReport(_fields);
    }

    function selectCustomer(user) {
        let _fields = {
            'customer': user.company_name,
            'address': user.address,
            'rego_no': user.rego_no
        }

        dispatch(setServiceReport(_fields));
        props.updateServiceReport(_fields);

        setSelectedCustomer(_fields);
        setShowUsers(false);
    }

    function getCustomersBySearch(field_value) {
        let url = API_ENDPOINT + 'v1/Users/searchUsers';
        let params = {
            search_term: field_value
        }

        postAuthenticated(url, params).then(results => {
            setUserList(results.data);
        });
    }

    function getFleetsByFleetNo(_fields) {
        let url = API_ENDPOINT + 'v1/Fleets/getFleetsByFleetNo';
        let params = {
            fleet_no: _fields.fleet_no,
        }

        postAuthenticated(url, params).then(results => {
            if(!isObjectEmpty(results.data)) {
                if(results.data.length == 1) {
                    setBasicReportDetails({...results.data[0].user, rego_no: results.data[0].rego_no});
                }else if(results.data.length > 1){
                    let users = results.data.map((a) => {
                        let data = a.user;
                        data = {...data, rego_no: a.rego_no}
                        return data;
                    });
                    setUserList(users);
                    setShowUsers(true);
                }
            }
        });
    }

    function getServiceReportDetailsByFleetNumber(_fields) {
        let url = API_ENDPOINT + 'v1/ServiceReports/getServiceReportsByFleetNumber';
        let params = {
            fleet_no: _fields.fleet_no,
        }

        setInit(true);
        postAuthenticated(url, params).then(results => {
            setTimeout(function() {
                if(!isObjectEmpty(results.data)) {
                    let serviceReports = [];
                    for(let key in results.data) key !== "basic_details" && serviceReports.push(results.data[key]);

                    let rcfReports = serviceReports.filter(x => x.service_status == 2);

                    dispatch(setFleetServiceReports(results.data));
                    setAllServiceReports(rcfReports);

                    if(rcfReports.length > 0) {
                        setOpenModal(true);
                    }
                }
                setInit(false);
            }, 4000);
        });
    }

    function getServiceReportDataByRegoNumber(_fields) {
        let url = API_ENDPOINT + 'v1/ServiceReports/getServiceReportsByRegoNumber';
        let params = {
            rego_no: _fields.rego_no,
        }

        postAuthenticated(url, params).then(results => {
            setTimeout(function () {
                if(!isObjectEmpty(results.data)) {
                    let serviceReports = [];
                    for(let key in results.data) {
                        if(key !== "basic_details"){
                            serviceReports.push(results.data[key]);
                        }else{
                            let _fields = {
                                recharged: results.data.basic_details.recharged,
                                unit_model: results.data.basic_details.unit_model,
                                fleet_no: results.data.basic_details.fleet_no
                            }

                            dispatch(setServiceReport(_fields));
                            props.updateServiceReport(_fields);
                        }
                    }

                    setAllServiceReports(serviceReports);

                    if(results.data.length > 1) {
                        setOpenModal(true);
                    }
                }
                setInit(false);
            }, 0);
        });
    }

    function setFocusDuration(event_name) {
        let current_event = event_name;
        let current_event_index = inputFocusOrder.findIndex(x => x === current_event);
        let next_event = current_event_index !== inputFocusOrder.length ? inputFocusOrder[current_event_index + 1] : null;

        setInputFocusDetails({
            current_element: current_event,
            next_element: next_event,
            is_focused: true,
            date_now: Date.now()
        });
    }

    function checkFocusDuration(event_name) {
        const millis = Date.now() - inputFocusDetails.date_now;
        let seconds_elapsed = Math.floor(millis / 1000);

        if(seconds_elapsed > 5 && inputFocusDetails.is_focused && document.getElementById(event_name).value.length > 3) {
            let current_event = event_name;
            let current_event_index = inputFocusOrder.findIndex(x => x === current_event);
            let next_event = current_event_index !== inputFocusOrder.length ? inputFocusOrder[current_event_index + 1] : null;

            setInputFocusDetails({...inputFocusDetails,
                current_element: inputFocusDetails.next_element,
                next_element: next_event,
                is_focused: false,
                date_now: Date.now()
            });

            document.getElementById(next_event).focus();
        }
    }

    return (
        <div className={`${layoutView === "mobile_view" ? '' : 'row'} mb-5`}>
            {openModal &&
                <RecordsModal
                    setOpenModal={setOpenModal}
                    serviceRecordsList={allServiceReports}
                    updateServiceReport={props.updateServiceReport}/> }

            {openCustomerModal &&
                <CustomerModal setOpenCustomerModal={setOpenCustomerModal}/> }

            {init && <Loader/> }

            <div className={`${layoutView === "mobile_view" ? 'col-12' : 'col-4'}`}>
                <div className={`${layoutView === "mobile_view" ? 'mb-3' : 'mb-2'}`}>
                    <label htmlFor="customer">Customer:</label>
                    <TextInput
                        name="customer"
                        value={serviceReportDetails.customer}
                        onChange={(e) => handleChange(e)}
                        onFocus={(e) => setFocusDuration(e.target.name)}
                        onBlur={(e) => hideUsersMenu(e.target.name)}
                        autoComplete="off"
                        className="w-100"
                        disabled={props.isEditable}
                    />

                    {showUsers &&
                        <div className="customer-autocomplete">
                            {Object.assign([], userList).map((user, index) =>
                                <option key={index} id={`customer_option_${index+1}`} className="form-control customer-options" role="button" tabIndex={1} onMouseDown={() => selectCustomer(user)}>{user.company_name}</option>
                            )}
                        </div>
                    }
                </div>

                <div className={`${layoutView === "mobile_view" ? 'mb-3' : 'mb-2'}`}>
                    <label htmlFor="address">Address:</label>
                    <TextAreaInput
                        name="address"
                        value={serviceReportDetails.address}
                        onChange={e => handleChange(e)}
                        onFocus={(e) => setFocusDuration(e.target.name)}
                        onBlur={(e) => removeFocus()}
                        className="w-100 text-break"
                        disabled={props.isEditable}
                    />
                </div>
            </div>

            <div className={`${layoutView === "mobile_view" ? 'col-12' : 'col-4'}`}>
                <div className={`${layoutView === "mobile_view" ? 'mb-3' : 'mb-2'}`}>
                    <label htmlFor="start_date">Start Date:</label>
                    <input
                        type="date"
                        id="start_date"
                        name="start_date"
                        value={serviceReportDetails.start_date}
                        onChange={(e) => handleChange(e)}
                        onFocus={(e) => setFocusDuration(e.target.name)}
                        onBlur={(e) => removeFocus()}
                        className="form-control w-100"
                        disabled={props.isEditable}
                    />
                </div>

                <div className={`${layoutView === "mobile_view" ? 'mb-5' : 'row'}`}>
                    <div className={`${layoutView === "mobile_view" ? 'col-12 mb-3' : 'col-6 mb-2'}`}>
                        <label htmlFor="rego_no">Rego No:</label>
                        <TextInput
                            name="rego_no"
                            value={serviceReportDetails.rego_no}
                            onChange={(e) => handleChange(e)}
                            onFocus={(e) => setFocusDuration(e.target.name)}
                            onBlur={(e) => removeFocus()}
                            disabled={props.isEditable}
                            className="w-100"
                        />
                    </div>
                    <div className={`${layoutView === "mobile_view" ? 'col-12 mb-3' : 'col-6'}`}>
                        <label htmlFor="fleet_no">Fleet No:</label>
                        <TextInput
                            name="fleet_no"
                            value={serviceReportDetails.fleet_no}
                            onChange={e => handleChange(e)}
                            onFocus={(e) => setFocusDuration(e.target.name)}
                            onBlur={(e) => removeFocus()}
                            disabled={props.isEditable}
                            className="w-100"
                        />
                    </div>
                </div>

                <div className={`${layoutView === "mobile_view" ? 'mb-3' : 'mb-2'}`}>
                    <label htmlFor="po_no">P/O No:</label>
                    <TextInput
                        name="po_no"
                        value={serviceReportDetails.po_no}
                        onChange={e => handleChange(e)}
                        onFocus={(e) => setFocusDuration(e.target.name)}
                        onBlur={(e) => removeFocus()}
                        disabled={props.isEditable}
                        className="w-100"
                    />
                </div>

                <div className={`${layoutView === "mobile_view" ? 'mb-3' : 'mb-2'}`}>
                    <label htmlFor="unit_model">A/C Unit Model:</label>
                    <TextInput
                        name="unit_model"
                        value={serviceReportDetails.unit_model}
                        onChange={e => handleChange(e)}
                        onFocus={(e) => setFocusDuration(e.target.name)}
                        onBlur={(e) => removeFocus()}
                        disabled={props.isEditable}
                        className="w-100"
                    />
                </div>
            </div>

            <div className={`${layoutView === "mobile_view" ? 'col-12' : 'col-4'}`}>
                <div className={`${layoutView === "mobile_view" ? 'mb-3' : 'mb-2'}`}>
                    <label htmlFor="end_date">End Date:</label>
                    <input
                        type="date"
                        id="end_date"
                        name="end_date"
                        value={serviceReportDetails.end_date}
                        onChange={(e) => handleChange(e)}
                        onFocus={(e) => setFocusDuration(e.target.name)}
                        onBlur={(e) => removeFocus()}
                        className="form-control w-100"
                        disabled={props.isEditable}
                    />
                </div>

                <div className={`${layoutView === "mobile_view" ? 'mb-3' : 'mb-2'}`}>
                    <label htmlFor="distance_covered">KM/HRS:</label>
                    <TextInput
                        name="distance_covered"
                        value={serviceReportDetails.distance_covered}
                        onChange={e => handleChange(e)}
                        onFocus={(e) => setFocusDuration(e.target.name)}
                        onBlur={(e) => removeFocus()}
                        className="w-100"
                        disabled={props.isEditable}
                    />
                </div>

                <div className={`${layoutView === "mobile_view" ? 'mb-3' : 'mb-2'}`}>
                    <label htmlFor="wo_no">W/O No:</label>
                    <TextInput
                        name="wo_no"
                        value={serviceReportDetails.wo_no}
                        onChange={e => handleChange(e)}
                        onFocus={(e) => setFocusDuration(e.target.name)}
                        onBlur={(e) => removeFocus()}
                        className="w-100"
                        disabled={props.isEditable}
                    />
                </div>

                <div className="mt-3">
                    <div className="d-flex me-2">
                        <div className="me-4">
                            <div className="form-check form-switch">
                                <input
                                    type="checkbox"
                                    id="sr"
                                    name="sr"
                                    value={serviceReportDetails.sr}
                                    checked={serviceReportDetails.sr == "yes"}
                                    onChange={e => handleToggle(e)}
                                    disabled={props.isEditable}
                                    className="form-check-input"
                                />
                                <span className="form-check-label align-middle">SR Complete:</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BasicDetails;
