import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, useLocation, useNavigate} from "react-router-dom";

import "../../styles/navigations/mainNavigation.scss";
import "../../styles/materialise/materialize.css";

import {MAIN_NAVIGATION} from "../../utils/structures/navigation.structures";
import {logoutUser, removeUserPermission} from "../../utils/helpers/action.helpers";

const Header = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const userPermissions = useSelector(state => state.userPermissions);
    const userProfile = useSelector(state => state.userProfile);

    function canViewNavigation(nav) {
        if(nav.type) {
            if(userPermissions) {
                let navPerms = userPermissions[nav.type];
                return navPerms[nav.constant] == 'true' || navPerms[nav.constant] == true || nav.constant == "changelog";
            }
        }else if(nav.allow){
            return userProfile.role == nav.allow;
        }
        return false;
    }

    function logoutUserProfile() {
        dispatch(logoutUser({}));
        dispatch(removeUserPermission({}));
        navigate('/');
    }

    return (
        <div>
            {userProfile && userProfile.id ?
                <div className="p-3 bg-light lead fs-16 text-secondary-emphasis">
                    <span>Hi </span>
                    <span className="text-decoration-underline">{userProfile.username}</span> -
                    <span className="pointer link-primary p-0 m-0" onClick={() => logoutUserProfile()}> Logout</span>
                </div>
            :null}

            <nav id="use-materialise" className="nav-extended">
                <div className="nav-content text-center">
                    <ul className="tabs bg-dark h-auto">
                        {MAIN_NAVIGATION.map((nav, index) =>
                            canViewNavigation(nav) &&
                            <Link to={nav.link} key={index} className="tab h-auto lh-1 p-3 text-center" style={{ background: `${location.pathname == nav.link ? 'rgb(149 145 145 / 50%)' : ''}`}}>
                                <div>
                                    <i className={`${nav.icon} fs-36`} />
                                    <div className="text-danger text-capitalize mt-1">{nav.label}</div>
                                </div>
                            </Link>
                        )}
                    </ul>
                </div>
            </nav>
        </div>
    );
};

export default Header;
