import {USER_ROLES} from "./users.structures";

export const MAIN_NAVIGATION = [
    { constant: "changelog",
      label: "Changelog",
      link: "/changelog",
      icon: "fa fa-file-text icon-white",
      type: "main_navigation",
      onClick: ""},

    { constant: "timesheet",
      label: "Timesheet",
      link: "/timesheet",
      icon: "fa fa-clock-o icon-white fs-40",
      type: "main_navigation",
      onClick: ""},

    { constant: "users",
      label: "Users",
      link: "/users",
      icon: "fa fa-users icon-white",
      type: "main_navigation",
      onClick: ""},

    { constant: "fleets",
      label: "Fleets",
      link: "/fleets",
      icon: "fa fa-bus icon-white",
      type: "main_navigation",
      onClick: ""},

    { constant: "new_sr",
      label: "New SR",
      link: "/service-report/new",
      icon: "fa fa-book icon-white",
      type: "main_navigation",
      onClick: "clearForm"},

    { constant: "history",
      label: "History",
      link: "/history",
      icon: "fa fa-bookmark icon-white",
      type: "main_navigation",
      onClick: ""},

    { constant: "jobs",
      label: "Jobs",
      link: "/jobs",
      icon: "fa fa-wrench icon-white",
      type: "main_navigation",
      onClick: ""},

    { constant: "analytics",
      label: "Analytics",
      link: "/analytics",
      icon: "fa fa-bar-chart icon-white",
      type: "",
      onClick: "",
      allow: USER_ROLES[1].id},

    { constant: "archived",
      label: "Archived",
      link: "/archived",
      icon: "fa fa-archive icon-white",
      type: "main_navigation",
      onClick: ""},
];
