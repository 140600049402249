import React from 'react';
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {isEmpty} from "../../../utils/helpers/app.helpers";
import {PAGE_SELECTIONS} from "../../../utils/structures/archived.structures";
import {setArchivedPageSelection} from "../../../utils/helpers/action.helpers";
import Back from "../../common/Back";

const Archived = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userPermissions = useSelector(state => state.userPermissions);
    const selectedArchivedPage = useSelector(state => state.selectedArchivePage);

    function changeSelectedPage(event) {
        let selectedEventPage = PAGE_SELECTIONS.find(x => x.constant === event.target.value);
        dispatch(setArchivedPageSelection(selectedEventPage));
    }

    function canAccessPage(constant) {
        return userPermissions.main_navigation[constant]
    }

    function focusMenu() {
        if(selectedArchivedPage.constant != "none") {
            dispatch(setArchivedPageSelection(PAGE_SELECTIONS[0]));
            let page_selection = document.getElementById('page_selection');
            if(page_selection) {
                page_selection.focus();
            }
        }else{
            navigate(-1)
        }
    }

    return (
        <div className="mt-2 w-95 m-auto">
            <div className="pt-2">
                <label className="form-label pb-0 mb-0">Select Page:</label>
                <div className="">
                    <select
                        id="page_selection"
                        name="page_selection"
                        value={selectedArchivedPage.constant}
                        onChange={(e) => changeSelectedPage(e)}
                        className="form-control w-100"
                    >
                        <option value={null}>...</option>
                        {PAGE_SELECTIONS.map((page, index) =>
                            canAccessPage(page.constant) &&
                                <option className="p-2" key={index} value={page.constant}>{page.label}</option>
                        )}
                    </select>
                </div>
            </div>

            {!isEmpty(selectedArchivedPage.component) &&
                <selectedArchivedPage.component/> }

            <Back function={focusMenu} />
        </div>
    );
};

export default Archived;
