import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";
import {postAuthenticated, postUnauthenticated} from "../../../utils/actions/post.actions";

const Verify = (props) => {
    let { verification_url } = useParams();

    useEffect(() => {
        if(verification_url !== null) {
            getUserDeviceByUrl();
        }
    }, [verification_url]);

    function getUserDeviceByUrl() {
        let url = API_ENDPOINT + 'v1/UserDevices/verifyUserDevice';
        let params = {
            user_verification_url: verification_url
        }

        postAuthenticated(url, params).then(results => {});
    }

    return (
        <div className="lead text-center mt-3">
            User has been verified. You can close this page
        </div>
    );
};

export default Verify;