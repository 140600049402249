import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

import TextInput from "../../form/TextInput";

import {isEmpty, isObjectEmpty} from "../../../utils/helpers/app.helpers";
import {postAuthenticated} from "../../../utils/actions/post.actions";
import {setUserPermission, setUserProfile} from "../../../utils/helpers/action.helpers";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";

import logo from "../../../assets/images/logo.jpg";

const Login = props => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [loggingIn, setLoggingIn] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [loginDetails, setLoginDetails] = useState({
        email_address: "",
        password: ""
    });

    function handleLoginDetails(event) {
        setLoginDetails({...loginDetails, [event.target.name]: event.target.value});
    }

    function validateForm(event) {
        event.preventDefault();
        let _errors = {};

        if(isEmpty(loginDetails.email_address)){
            _errors['email_address'] = "Email address field is empty";
        }
        if(isEmpty(loginDetails.password)){
            _errors['password'] = "Password field is empty";
        }

        setErrors(_errors);
        if(isObjectEmpty(_errors)) {
            authenticateUser();
        }
    }

    function authenticateUser() {
        setLoggingIn(true);
        let url = API_ENDPOINT + 'v1/Users/AuthenticateUser';
        let params = {
            email_address: loginDetails.email_address,
            password: loginDetails.password
        }

        postAuthenticated(url, params).then(results => {
            if(results.success) {
                setErrors({});
                dispatch(setUserProfile(results.data.user));
                dispatch(setUserPermission(results.data.user_permissions));

                navigate('/');
            }else{
                let _errors = {};
                _errors['authentication'] = "Access Denied";

                setErrors(_errors);
                setLoggingIn(false);
            }
        });
    }

    return (
        <div>
            <div className="bg-black" style={{height: "5rem"}} />

            <div className="text-center m-auto mb-4 mt-3">
                <img src={logo} alt="" width={400} height={100} />
            </div>

            <div className="w-50 m-auto mt-5">
                <form autoComplete="on">
                    {errors.authentication && <div className="text-danger text-center fst-italic mb-2"><small>{errors.authentication}</small></div> }
                    <TextInput
                        name="email_address"
                        placeholder="Username or Email"
                        value={loginDetails.email_address}
                        autoComplete="email"
                        autoFocus={true}
                        onChange={(e) => handleLoginDetails(e)}
                        error={errors.email_address}
                        className="form-control"
                    />

                    <TextInput
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        placeholder="Password"
                        value={loginDetails.password}
                        autoComplete="current-password"
                        onChange={(e) => handleLoginDetails(e)}
                        error={errors.password}
                        className="form-control mt-3"
                    />
                    <i className={`position-relative float-end ${!showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'} align-self-center mt-2 fs-5`} onClick={() => setShowPassword(!showPassword)} style={{top: "-37px", right: "10px"}} />

                    <div className="mt-3">
                        <button type="submit" className="btn btn-warning w-100 fs-6" onClick={(e) => validateForm(e)} disabled={loggingIn}>
                            {loggingIn ?
                                <div className="spinner-border spinner-grow-sm" role="status" />
                            : 'Login'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;
