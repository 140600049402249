import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {BrowserRouter, Route, Routes} from "react-router-dom";

import Header from "./components/common/Header";
import Jobs from "./components/pages/jobs/Jobs";
import Users from "./components/pages/users/Users";
import Login from "./components/pages/public/Login";
import Verify from "./components/pages/public/Verify";
import Fleets from "./components/pages/fleets/Fleets";
import History from "./components/pages/history/History";
import Archived from "./components/pages/archived/Archived";
import Analytics from "./components/pages/analytics/Analytics";
import Changelog from "./components/pages/changelog/Changelog";
import Timesheet from "./components/pages/timesheet/Timesheet";
import ServiceReport from "./components/pages/serviceReport/ServiceReport";
import PrintServiceReport from "./components/pages/print/PrintServiceReport";

import {isObjectEmpty} from "./utils/helpers/app.helpers";
import {postAuthenticated} from "./utils/actions/post.actions";
import {setUserPermission, setUserProfile} from "./utils/helpers/action.helpers";
import {USER_ROLES} from "./utils/structures/users.structures";
import {API_ENDPOINT} from "./utils/constants/app.constants";

const AppRoutes = () => {
    const dispatch = useDispatch();
    const [status, setStatus] = useState(true);
    const userPermissions = useSelector(state => state.userPermissions);
    const userProfile = useSelector(state => state.userProfile);
    const userExists = !isObjectEmpty(userProfile) && !isObjectEmpty(userPermissions);

    useEffect(() => {
        function changeStatus() {
            setStatus(navigator.onLine);
        }
        window.addEventListener("online", changeStatus);
        window.addEventListener("offline", changeStatus);
        return () => {
            window.removeEventListener("online", changeStatus);
            window.removeEventListener("offline", changeStatus);
        };
    }, [navigator.onLine]);

    useEffect(() => {
        if(userExists) {
            getUserById();
        }
    }, []);

    useEffect(() => {
        userExists && getUserPermissionsByUserId();
    }, [userExists]);

    function canAccessPages(constant) {
        return userPermissions.main_navigation[constant];
    }

    function canAccessPagesViaAction(constant) {
        return userPermissions.service_report_actions[constant];
    }

    function getUserById() {
        let url = API_ENDPOINT + 'v1/Users/getUserById';
        let params = {
            user_id: userProfile.id
        }

        postAuthenticated(url, params).then(results => {
            dispatch(setUserProfile(results.data));
        });
    }

    function getUserPermissionsByUserId() {
        let url = API_ENDPOINT + 'v1/UserPermissions/getUserPermissionByUserId';
        let params = {
            user_id: userProfile.id
        }

        postAuthenticated(url, params).then(results => {
            dispatch(setUserPermission(results.data));
        });
    }

    function checkPermissionsUpToDate(constant) {
        if(isObjectEmpty(userPermissions[constant])) {
            getUserPermissionsByUserId();
        }else{
            return true;
        }
    }

    return (
        <BrowserRouter>
            {userExists && <Header /> }

            {status ?
                <Routes>
                    <Route path="/verify/:verification_url" element={<Verify />} />
                    {userExists ?
                        <React.Fragment>
                            <Route path="/" element={<History />} />

                            {checkPermissionsUpToDate("service_report_actions") &&
                                <React.Fragment>
                                    {canAccessPagesViaAction("view") &&
                                        <Route path="/service-report/view/:service_report_id" element={<ServiceReport actionType="view" />} /> }

                                    {canAccessPagesViaAction("edit") &&
                                        <Route path="/service-report/edit/:service_report_id" element={<ServiceReport actionType="edit" />} /> }

                                    {canAccessPagesViaAction('print') &&
                                        <Route path="/print-report/:service_report_id" element={<PrintServiceReport />} /> }
                                </React.Fragment>
                            }

                            {canAccessPages('timesheet') &&
                                <Route path="/timesheet" element={<Timesheet />} /> }

                            {canAccessPages('users') &&
                                <Route path="/users" element={<Users />} /> }

                            {canAccessPages('new_sr') &&
                                <Route path="/service-report/new" element={<ServiceReport actionType="create" />} /> }

                            {canAccessPages('history') &&
                                <Route path="*" element={<History />} /> }

                            {canAccessPages('fleets') &&
                                <Route path="/fleets" element={<Fleets />} /> }

                            {canAccessPages('history') &&
                                <Route path="/history" element={<History />} /> }

                            {canAccessPages('archived') &&
                                <Route path="/service-report/revision/:service_report_log_id" element={<ServiceReport actionType="revision" />} /> }

                            {canAccessPages('archived') &&
                                <Route path="/archived" element={<Archived />} /> }

                            {canAccessPages('jobs') &&
                                <Route path="/jobs" element={<Jobs action="view" />} /> }

                            {canAccessPages('jobs') &&
                                <Route path="/jobs/create" element={<Jobs action="create" />} /> }

                            {canAccessPages('jobs') &&
                                <Route path="/jobs/edit/:job_id" element={<Jobs action="edit" />} /> }

                            {userProfile.role == USER_ROLES[1].id &&
                                <Route path="/analytics" element={<Analytics />} /> }

                            <Route path="/changelog" element={<Changelog />} />

                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Route path="*" element={<Login />} />
                            <Route path="/login" element={<Login />} />
                        </React.Fragment>
                    }
                </Routes>
                :
                <h2 className="text-center lead fs-1 mt-5">No network has been detected</h2>
            }
        </BrowserRouter>
    )
};

export default AppRoutes;