import {createSlice} from "@reduxjs/toolkit";

const historyTrackerSlice = createSlice({
    name: "historyTracker",
    initialState: {
        page: 1,
        historyReportCount: 0,
        lastSelectedReport: 0,
        lastSelectedFilter: "",
        search: ""
    },
    reducers: {
        setHistoryTracker: (state, {payload}) => {
            return {
                ...state,
                ...payload
            };
        },
    }
})

export const {setHistoryTracker} = historyTrackerSlice.actions;
export default historyTrackerSlice.reducer;
