import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import Back from "../../common/Back";
import Loader from "../../common/Loader";
import FleetBatchModal from "../../modals/fleetModals/FleetBatchModal";
import ServiceReportsModal from "../../modals/fleetModals/ServiceReportsModal";

import {setModal} from "../../../utils/slices/modal.slice";
import {postAuthenticated} from "../../../utils/actions/post.actions";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";

const Fleets = () => {
    const dispatch = useDispatch();
    const userProfile = useSelector(state => state.userProfile);
    const [init, setInit] = useState(false);
    const [fleetBatches, setFleetBatches] = useState([]);

    useEffect(() => {
        getFleets();
    }, []);

    function getFleets() {
        let url = API_ENDPOINT + "v1/Fleets/getFleetBatches";
        let params = {
            search_term: ""
        };

        postAuthenticated(url, params).then(results => {
            setFleetBatches(results.data);
            setInit(true);
        });
    }

    function launchFleetBatchModal(event, modalAction, fleetBatch) {
        event.stopPropagation();
        let modalSettings = {
            config: {
                component: FleetBatchModal,
                props: {
                    action: modalAction,
                    user_id: fleetBatch.user_id
                }
            },
            onClose: () => {
                getFleets();
            }
        }

        dispatch(setModal(modalSettings));
    }

    function toggleAccordion(event, fleetBatch, index) {
        event.stopPropagation();
        let prefleetBatches = Object.assign([], {...fleetBatches, [index]: {
            ...fleetBatches[index],
            is_open: !fleetBatches[index].is_open
        }});

        setFleetBatches(prefleetBatches);
    }

    function isAdmin() {
        return userProfile.role == 4 || userProfile.role == 1;
    }

    function launchServiceReportsModal(event, fleetBatch, fleet) {
        event.stopPropagation();
        let modalSettings = {
            config: {
                component: ServiceReportsModal,
                props: {
                    fleetBatch: fleetBatch,
                    fleet: fleet
                }
            },
            onClose: () => {}
        }

        dispatch(setModal(modalSettings));
    }

    return (
        init ?
            <React.Fragment>
                <div className="w-80 m-auto mt-4">
                    <h2 id="filters" className="lead fs-32">Fleets</h2>

                    <div className="d-flex mb-2">
                        <div className="col-6 text-secondary-emphasis fs-14 align-self-end">Results: {fleetBatches ? fleetBatches.length : 0}</div>
                        <div className="col-6 text-end">
                            {isAdmin() ?
                                <div className="text-end">
                                    <button className="ms-3 btn btn-primary rounded-0" onClick={(e) => launchFleetBatchModal(e, 'create', {})}>Create Fleet</button>
                                </div>
                            :null}
                        </div>
                    </div>

                    <div className="mb-3 py-3">
                        <div className="d-flex">
                            <div className="ps-4 pe-2" />
                            <div className="col-5 fw-bold mb-2 lead fs-16">Customer</div>
                            <div className="col-5 fw-bold mb-2 lead fs-16">Address</div>
                        </div>
                        {fleetBatches.map((fleetBatch, index) =>
                            <div key={index} role="button" className={`bg-light-subtle m-auto ${index !== 0 ? 'mt-3' : ''} ${fleetBatch.is_open ? 'text-secondary-emphasis fs-14 border border-1 border-top-0 pb-0 mb-3' : 'mb-3'}`} onClick={(e) => toggleAccordion(e, fleetBatch, index)}>
                                <div key={index} className="d-flex border border-2 p-2 text-secondary-emphasis fs-14">
                                    <div className="ps-2 pe-3 align-self-center">
                                        <i className={`${fleetBatch.is_open ? 'fa fa-angle-down' : 'fa fa-angle-right'}`} />
                                    </div>
                                    <div className="col-5 align-self-center">{fleetBatch.company_name} <span className="fw-bold">({fleetBatch.fleets.length} fleets)</span></div>
                                    <div className="col-5 align-self-center">{fleetBatch.address}</div>
                                    <div className="w-30 align-self-center text-end">
                                        {isAdmin() ? <i title="EditJob" role="button" className="bg-warning rounded fs-5 p-1 me-2 fa fa-edit icon-white" onClick={(e) => launchFleetBatchModal(e,"edit", fleetBatch)}/> : null }
                                    </div>
                                </div>

                                {fleetBatch.is_open &&
                                    <div className="d-flex ms-3">
                                        <div className="col-4 fw-bold py-2 ms-1">Fleet No</div>
                                        <div className="col-4 fw-bold py-2">Rego No</div>
                                        <div className="col-4 fw-bold py-2 text-end pe-5">View Reports</div>
                                    </div> }

                                {fleetBatch.is_open && fleetBatch.fleets.map((fleet, fleet_index) =>
                                    <div key={fleet_index} className="d-flex border border-start-0 border-end-0 border-bottom-0 p-2 text-secondary">
                                        <div className="col-4 ms-3">{fleet.fleet_no}</div>
                                        <div className="col-4">{fleet.rego_no}</div>
                                        <div className="col-4 text-end pe-5">
                                            <span role="button" className="fa fa-cog fs-20" onClick={(e) => launchServiceReportsModal(e, fleetBatch, fleet)} />
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>

                    <Back />
                </div>
            </React.Fragment>
        : <Loader />
    );
};

export default Fleets;