import React, {useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import SignaturePad from 'react-signature-pad-wrapper'

import {postAuthenticated} from "../../../utils/actions/post.actions";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";
import {setServiceReport} from "../../../utils/helpers/action.helpers";

const SignaturePadModal = (props) => {
    const ref = useRef();
    const dispatch = useDispatch();
    //const navigate = useNavigate();
    const serviceReportDetails = useSelector(state => state.serviceReport);

    function captureSignature() {
        props.setOpenSignaturePad(false);
        if (ref.current) {
            const image_url = ref.current.toDataURL();
            saveSignature(image_url)
            //navigate("/history");
        }
    }

    function saveSignature(image_url) {
        let url = API_ENDPOINT + 'v1/ServiceReports/saveSignature';
        let params = {
            id: serviceReportDetails.id,
            srno: serviceReportDetails.srno,
            signature_blob: image_url
        }

        postAuthenticated(url, params).then(results => {
            let _fields = {
                signature: results.data
            }
            dispatch(setServiceReport(_fields));
        }).catch();
    }

    return (
        <div className="modal d-block in" tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-xl modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h2>Signature</h2>
                    </div>

                    <div className="modal-body" style={{overflowY: "scroll", minHeight: "400px"}}>
                        <label className="lead">Sign here:</label>
                        <div className="border border-2 m-auto">
                            <SignaturePad ref={ref} options={{minWidth: 5}} height={300} />
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={() => ref.current.clear()}>Clear</button>
                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => props.setOpenSignaturePad(false)}>Close</button>
                        <button type="button" className="btn btn-success" data-dismiss="modal" onClick={() => captureSignature()}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignaturePadModal;
