import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";

import * as serviceReportConstants from "../../../utils/constants/serviceReport.constants";

import TextInput from "../../form/TextInput";
import {postAuthenticated} from "../../../utils/actions/post.actions";
import {setServiceReport} from "../../../utils/helpers/action.helpers";
import {isEmpty, isObjectEmpty} from "../../../utils/helpers/app.helpers";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";

const TempRefrigerant = (props) => {
    const dispatch = useDispatch();
    const serviceReportDetails = useSelector(state => state.serviceReport);
    const fleetServiceReports = useSelector(state => state.fleetServiceReports);
    const [refrigerantDetails, setRefrigerantDetails] = useState([]);
    const [refrigerantOptions, setRefrigerantOptions] = useState([]);

    useEffect(() => {
        getSelectedRefrigerants();
    }, []);

    function handleRefrigerants(event) {
        event.target.name === "recovered" && calculateTempRefrigerantByRecovered(event);
        event.target.name === "recharged" && calculateTempRefrigerantByRecharged(event);
        event.target.name === "refrigerant" && handleRefrigerant(event);
    }

    function handleTemps(event) {
        let _fields = {
            [event.target.name]: event.target.value
        }

        dispatch(setServiceReport(_fields));
        props.updateServiceReport(_fields);
    }

    function handleRefrigerant(event) {
        let refrigerantType = event.target.value;
        let recoveredAmount = serviceReportDetails.recovered;
        let rechargedAmount = serviceReportDetails.recharged;

        if(refrigerantType != null) {
            let refrigerant_item = refrigerantOptions.find(ro => ro.part_number == refrigerantType);
            setRefrigerantDetails(refrigerant_item);

            let _fields = {
                "refrigerant": refrigerantType,
                "recovered": recoveredAmount,
                "recharged": rechargedAmount,
                "refrigerant_item": refrigerant_item,
            }

            finalRefrigerantCalculations(_fields);
        }
    }

    function getSelectedRefrigerants() {
        let url = API_ENDPOINT + 'v1/Inventories/getSelectedRefrigerants';
        let params = {};

        postAuthenticated(url, params).then(results => {
            results.data[results.data.length] = {
                part_number: null
            }
            setRefrigerantOptions(results.data);
        })
    }

    function calculateTempRefrigerantByRecovered(event) {
        let recoveredAmount = event.target.value;
        let refrigerantType = Object.keys(fleetServiceReports).length > 1 ? Object.assign([], fleetServiceReports).find(x => x.refrigerant !== null).refrigerant : serviceReportDetails.refrigerant;
        let rechargedAmount = Object.keys(fleetServiceReports).length > 1 ? Object.assign([], fleetServiceReports).find(x => x.recharged !== null).recharged : serviceReportDetails.recharged;

        if(refrigerantType != null) {
            let refrigerant_item = refrigerantOptions.find(ro => ro.part_number == refrigerantType);
            setRefrigerantDetails(refrigerant_item);

            let _fields = {
                "recovered": recoveredAmount,
                "refrigerant": refrigerantType,
                "recharged": rechargedAmount,
                "refrigerant_item": refrigerant_item,
            }
            finalRefrigerantCalculations(_fields);
        }
    }

    function calculateTempRefrigerantByRecharged(event) {
        let rechargedAmount = event.target.value;
        let refrigerantType = serviceReportDetails.refrigerant;
        let recoveredAmount = serviceReportDetails.recovered;

        if(refrigerantType != null) {
            let refrigerant_item = refrigerantOptions.find(ro => ro.part_number == refrigerantType);
            setRefrigerantDetails(refrigerant_item);

            let _fields = {
                "recharged": rechargedAmount,
                "recovered": recoveredAmount,
                "refrigerant": refrigerantType,
                "refrigerant_item": refrigerant_item,
            }
            finalRefrigerantCalculations(_fields);
        }
    }

    function finalRefrigerantCalculations(fields) {
        let partNo, description, price;
        let qtyDifference = parseFloat(fields['recharged']) - parseFloat(fields['recovered']);

        let _fields = {
            [Object.keys(fields)[0]]: fields[Object.keys(fields)[0]],
        }
        if((!isObjectEmpty(refrigerantDetails) || !isObjectEmpty(fields['refrigerant_item'] || !isEmpty(fields['refrigerant'])))) {
            if(!isObjectEmpty(fields['refrigerant_item'])) {
                partNo = fields['refrigerant_item'].part_number;
                description = fields['refrigerant_item'].description;
                price = parseFloat(fields['refrigerant_item'].rrp).toFixed(2);
            }else{
                partNo = refrigerantDetails.part_number;
                description = refrigerantDetails.description;
                price = parseFloat(refrigerantDetails.rrp).toFixed(2);
            }

            let totalPrice = qtyDifference * price;
            let _partsTable = serviceReportDetails.parts_table;

            let existingIndex = Object.assign([], _partsTable).findIndex(row => {
                return row.desc == description;
            });
            let emptyPartRowIndex = Object.assign([], _partsTable).findIndex(row => {
                return row.desc == "" || row.desc == null;
            });

            let selectedRowIndex = existingIndex > -1 ? existingIndex : emptyPartRowIndex;

            if (!isEmpty(fields['refrigerant']) && (!isEmpty(fields['recovered'] && !isNaN(fields['recovered'])) && (!isEmpty(fields['recharged']) && !isNaN(fields['recharged'])) && qtyDifference >= 0)) {
                _fields[Object.keys(fields)[1]] = fields[Object.keys(fields)[1]];
                _fields[Object.keys(fields)[2]] = fields[Object.keys(fields)[2]];
                _fields["parts_table"] = Object.assign([], {
                    ...serviceReportDetails.parts_table,
                        [selectedRowIndex]: {...serviceReportDetails.parts_table[selectedRowIndex],
                            'part': partNo,
                            'desc': description,
                            'qty': qtyDifference.toFixed(1),
                            'price': parseFloat(price).toFixed(2),
                            'total_price': totalPrice.toFixed(2)
                        }
                });
                let totalmprice = (parseFloat(serviceReportDetails.totalmprice) - serviceReportDetails.parts_table[selectedRowIndex].total_price) + totalPrice;
                let exgst = !isEmpty(serviceReportDetails.totalmprice)
                    ? parseFloat(serviceReportDetails.exgst) - parseFloat(serviceReportDetails.totalmprice) + totalmprice
                    : parseFloat(serviceReportDetails.exgst) + totalmprice

                _fields['totalmprice'] = totalmprice.toFixed(2);
                _fields['exgst'] = exgst.toFixed(2);

            }else if(!isEmpty(fields['refrigerant']) && (!isEmpty(fields['recharged']) && !isNaN(fields['recharged']))) {
                _fields[Object.keys(fields)[1]] = fields[Object.keys(fields)[1]];
                _fields[Object.keys(fields)[2]] = fields[Object.keys(fields)[2]];
                _fields["parts_table"] = Object.assign([], {
                    ...serviceReportDetails.parts_table,
                    [selectedRowIndex]: {
                        ...serviceReportDetails.parts_table[selectedRowIndex],
                        'part': "",
                        'desc': "",
                        'qty': "",
                        'price': "",
                        'total_price': ""
                    }
                });

                let totalmprice = (parseFloat(serviceReportDetails.totalmprice) - serviceReportDetails.parts_table[selectedRowIndex].total_price);
                let exgst = !isEmpty(serviceReportDetails.totalmprice)
                    ? (parseFloat(serviceReportDetails.exgst) - parseFloat(serviceReportDetails.totalmprice)) + totalmprice
                    : parseFloat(serviceReportDetails.exgst)

                _fields['totalmprice'] = totalmprice.toFixed(2);
                _fields['exgst'] = exgst.toFixed(2);
            }
        }


        dispatch(setServiceReport(_fields));
        props.updateServiceReport(_fields);
    }

    return (
        <div className="mt-3">
            <div>
                <table className="service-report-tables w-100">
                    <thead>
                        <tr>
                            <th className="p-1">{serviceReportConstants.AMBIENT_TEMP_LABEL}</th>
                            <th className="p-1">{serviceReportConstants.SUPPLY_AIR_TEMP_LABEL}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="border border-dark">
                                <TextInput
                                    name="ambTemprature"
                                    value={serviceReportDetails.ambTemprature}
                                    onChange={(e) => handleTemps(e)}
                                    disabled={props.isEditable}
                                    className="w-100 border-0 text-center"
                                />
                            </td>
                            <td className="border border-dark">
                                <TextInput
                                    name="supplyTemprature"
                                    value={serviceReportDetails.supplyTemprature}
                                    onChange={(e) => handleTemps(e)}
                                    disabled={props.isEditable}
                                    className="w-100 border-0 text-center"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="mt-3">
                <table className="service-report-tables w-100">
                    <thead>
                        <tr>
                            <th className="p-1">{serviceReportConstants.REFRIGERANT_LABEL}</th>
                            <th className="p-1">{serviceReportConstants.RECOVERED_LABEL}</th>
                            <th className="p-1">{serviceReportConstants.CAPACITY_LABEL}</th>
                            {/*<th className="p-1">{serviceReportConstants.TOTAL_WEIGHT_LABEL}</th>*/}
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="border border-dark">
                            <td className="border border-dark">
                                <select id="refrigerant" name="refrigerant" className="form-control rounded-0" onChange={(e) => handleRefrigerants(e)} disabled={props.isEditable}>
                                    {refrigerantOptions.map((ref, index) =>
                                        <React.Fragment>
                                            <option key={index} value={ref.part_number} selected={ref.part_number == serviceReportDetails.refrigerant}>{ref.part_number}</option>
                                        </React.Fragment>
                                    )}
                                </select>
                            </td>
                            <td className="border border-dark">
                                <TextInput
                                    name="recovered"
                                    value={serviceReportDetails.recovered}
                                    onChange={(e) => handleRefrigerants(e)}
                                    disabled={props.isEditable}
                                    className="form-control w-100 border-0 text-center rounded-0"
                                />
                            </td>
                            <td className="border border-dark">
                                <TextInput
                                    name="recharged"
                                    value={serviceReportDetails.recharged}
                                    onChange={(e) => handleRefrigerants(e)}
                                    disabled={props.isEditable}
                                    className="form-control w-100 border-0 text-center rounded-0"
                                />
                            </td>
                            {/*
                            <td className="border border-dark">
                                <TextInput
                                    name="total_weight"
                                    value={serviceReportDetails.total_weight}
                                    onChange={(e) => handleChange(e)}
                                    disabled={isEditable}
                                    className="form-control w-100 border-0 text-center"
                                />
                            </td>
                            */}
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TempRefrigerant;
