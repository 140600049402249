import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setToast} from "../../utils/slices/toasts.slice";

import "../../styles/modals/modal.custom.css";

const Toast = (props) => {
    const dispatch = useDispatch();
    const toasts = useSelector(state => state.toasts);

    function clearToast(index) {
        let _toasts = [...toasts];
        _toasts.indexOf(index)

        if (index > -1) { // only splice array when item is found
            _toasts.splice(index, 1); // 2nd parameter means remove one item only
        }

        dispatch(setToast(_toasts));
    }

    return (
        toasts.map((toast, index) =>
            <div key={index} className={`toast show position-fixed top-0 end-0 me-2 modal-entrance`} style={{marginTop: `${index*20}px`}}>
                <div className="toast-header">
                    <strong className="me-auto">{toast.head}</strong>
                    <button className="btn-close" onClick={() => clearToast(index)}></button>
                </div>
                <div className="toast-body bg-white py-3 rounded">
                    {toast.body}
                </div>
            </div> )
    )
};

export default Toast;
