export const SERVICE_REPORT_SECTIONS = [
    {
        constant: "basic_details",
        label: "Basic Details",
        type: "service_report"
    },
    {
        constant: "service_checklist",
        label: "Service Checklist - Maintenance Description",
        type: "service_report"
    },
    {
        constant: "work_instructions",
        label: "Work Instructions - Customer Complaint",
        type: "service_report"
    },
    {
        constant: "work_performed",
        label: "Work Performed",
        type: "service_report"
    },
    {
        constant: "temp_refrigerant",
        label: "Temp Refrigerant",
        type: "service_report"
    },
    {
        constant: "parts_table",
        label: "Parts Table",
        type: "service_report"
    },
    {
        constant: "tech_initials",
        label: "Tech Initials",
        type: "service_report"
    },
    {
        constant: "calculation_list",
        label: "Calculation List",
        type: "service_report"
    },
    {
        constant: "service_status",
        label: "Service Status",
        type: "service_report"
    }
];

export const USER_ROLES = [
    {
        id: null,
        constant: "choose_here",
        label: "Choose here..."
    },
    {
        id: 4,
        constant: "super_admin",
        label: "Super Admin"
    },
    {
        id: 1,
        constant: "admin",
        label: "Admin"
    },
    {
        id: 2,
        constant: "account_manager",
        label: "Account Manager"
    },
    {
        id: 3,
        constant: "technician",
        label: "Technician"
    },
    {
        id: 0,
        constant: "customer",
        label: "Customer"
    },
]

export const USER_PERMISSION_PAGES = [
    { constant: "timesheet",
      type: "main_navigation",
      label: "Timesheet"},

    { constant: "users",
      type: "main_navigation",
      label: "Users"},

    { constant: "fleets",
      type: "main_navigation",
      label: "Fleets"},

    { constant: "new_sr",
      type: "main_navigation",
      label: "New Service Report"},

    { constant: "history",
      type: "main_navigation",
      label: "History"},

    { constant: "jobs",
      type: "main_navigation",
      label: "Jobs"},

    { constant: "archived",
      type: "main_navigation",
      label: "Archived",
      sub_pages: [
        { parent_constant: "archived",
          constant: "service_report_logs",
          type: "main_navigation",
          label: "Service Report Logs"},

        { parent_constant: "archived",
          constant: "archived_reports",
          type: "main_navigation",
          label: "Archived Reports"},

        { parent_constant: "archived",
          constant: "fleet_logs",
          type: "main_navigation",
          label: "Fleet Logs"},

        { parent_constant: "archived",
          constant: "user_logs",
          type: "main_navigation",
          label: "User Logs"}
      ]
    }
];
