import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from "react-redux";
import {setModal} from "../../../utils/slices/modal.slice";
import ReactToPrint from "react-to-print";

import "../../../styles/materialise/materialize.css";

import Back from "../../common/Back";
import TextInput from "../../form/TextInput";
import FiltersModal from "../../modals/FiltersModal";
import MaterializeLoader from "../../common/MaterializeLoader";

import {postAuthenticated} from "../../../utils/actions/post.actions";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";

const Analytics = () => {
    const dispatch = useDispatch();

    const TABLE_COLUMNS = ['service_report_no', 'rego_no', 'service_status', 'total_labour_hours_spent','total_amount_on_labour',	'total_amount_on_parts', 'total_amount_on_quote', 'total_of_all'];
    const TOTAL_LABOUR_HOUR_SPENT_CONSTANT = 'total_labour_hours_spent';

    const [isSearching, setIsSearching] = useState(false);
    const [appliedFilters, setAppliedFilters] = useState({});
    const [reportData, setReportData] = useState([]);
    const [display, setDisplay] = useState('rows');
    const tableRef = useRef();

    useEffect(() => {
        getReports();
    }, [appliedFilters]);

    function getReports() {
        setIsSearching(true);
        let url = API_ENDPOINT + "v1/ServiceReports/getAnalyticalReports";
        let params = {
            filters: appliedFilters
        };

        postAuthenticated(url, params).then(results => {
            setIsSearching(false);
            setReportData(results.data);
        });
    }

    function applyFilters(filters) {
        setAppliedFilters(filters);
    }

    function deleteFilter(key) {
        let preAppliedFilters = {...appliedFilters};
        delete preAppliedFilters[key];

        setAppliedFilters(preAppliedFilters);
    }

    function launchFilterModal() {
        let modalSettings = {
            config: {
                component: FiltersModal,
                props: {
                    appliedFilters: appliedFilters
                },
            },
            onClose: (filters) => {
                applyFilters(filters)
            }
        }
        dispatch(setModal(modalSettings));
    }

    function scrollToElement(id) {
        let ele = document.getElementById(id);
        if(ele) {
            ele.scrollIntoView();
        }
    }

    return (
        <div id="use-materialise" className="w-80 m-auto mt-4 pb-5">
            <div className="d-flex mb-3">
                <div className="col-9">
                    <h2 id="filters" className="lead fs-32 m-0">Analytics Report</h2>
                </div>
                <div className="col-3 align-self-center text-end">
                    <div className="switch">
                        <label>
                            <span className="fs-16 p-0 m-0">Rows</span>
                            <input type="checkbox" name="display" onChange={() => setDisplay(display === 'rows' ? 'table' : 'rows')} />
                            <span className="lever fs-16" />
                            <span className="fs-16 p-0 m-0">Table</span>
                        </label>
                    </div>
                </div>
            </div>

            <div>
                <div className="col-2 p-0">
                    <button className="bg-light waves-effect btn-small text-dark" onClick={(e) => launchFilterModal(e)}>
                        <i className="material-icons left">add</i>Filters
                    </button>
                </div>

                <div className="row mb-0 mt-2">
                    <div className="chips border border-0 p-0 m-0 mt-1 mh-auto">
                        {Object.keys(appliedFilters).length === 0 ? <div className="text-secondary-emphasis fs-14">No filters have been selected</div> :
                        Object.keys(appliedFilters).map((key, index) =>
                            appliedFilters[key] !== "" ?
                                <div className="chip w-auto border border-0">
                                    <span className="text-dark fw-bold">{key.replace("_", " ")}: </span> {appliedFilters[key]}
                                    <i role="button" className="bi bi-x-lg ms-1 fw-bolder" onClick={() => deleteFilter(key)} />
                                </div>
                            :null
                        )}
                    </div>
                </div>

                <div className="d-flex mt-3 w-100">
                    <div className="col-10">
                        <TextInput
                            name="search"
                            value={appliedFilters.search}
                            onChange={(e) => setAppliedFilters({...appliedFilters, [e.target.name]: e.target.value})}
                            placeholder="Search..."
                            className="border border-1 ps-3 pb-0 mb-0"
                        />
                    </div>
                    <button className="col-2 btn btn-primary h-auto" onClick={() => getReports()}>
                        {isSearching ? <MaterializeLoader /> : 'Find'}
                    </button>
                </div>

                <div className="d-flex mt-4">
                    <div className="col-6 text-secondary-emphasis fs-14">Results: {reportData && reportData.reports ? reportData.reports.length : 0}</div>
                    <div className="col-6 text-end">
                        {reportData.reports ?
                            <a role="button" className="link-primary" onClick={() => scrollToElement('total_calculations')}>
                                Jump to totals
                                <i className="ms-1 fa fa-arrow-down" />
                            </a>
                        :null}
                    </div>
                </div>
            </div>

            {display === "rows" ?
                <React.Fragment>
                    <div className="mt-1">
                        {reportData && reportData.reports && reportData.reports.map((report, index) =>
                            <div className="border border-1 rounded p-3 mb-3">
                                <div className="d-flex">
                                    <div className="col-6">
                                        {Object.keys(report).map(key =>
                                            <div>
                                                {!key.includes('total') ?
                                                    <div className="lead text-secondary-emphasis fs-18 text-capitalize">{key.replace(/_/g, " ")}:
                                                        <span className="fw-semibold"> {report[key]}</span>
                                                    </div>
                                                :null}
                                            </div>
                                        )}
                                    </div>

                                    <div className="col-6">
                                        <div className="d-flex">
                                            <div className="col-6">
                                                {Object.keys(report).map(key =>
                                                    <div>
                                                        {key.includes('total') ?
                                                            <div className="lead text-secondary-emphasis fs-18 text-capitalize">{key.replace(/_/g, " ")}:
                                                                <span className="fw-semibold">
                                                                    {key == TOTAL_LABOUR_HOUR_SPENT_CONSTANT
                                                                        ? ` ${report[key]} hrs`
                                                                        : ` $${report[key]}`
                                                                    }
                                                                </span>
                                                            </div>
                                                        :null}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    {reportData.reports ?
                        <div className="mt-4 bg-light p-3">
                            <div id="total_calculations" className="d-flex w-100">
                                <div className="col-9 lead fs-30">Total Calculations</div>
                                <div className="col-3 align-self-center text-end">
                                    <a role="button" className="link-primary" onClick={() => scrollToElement('filters')}>
                                        Jump to filters
                                        <i className="ms-1 fa fa-arrow-up" />
                                    </a>
                                </div>
                            </div>
                            <hr />

                            <div>
                                {reportData && reportData.totals && reportData.reports && Object.keys(reportData.totals).map((key, index) =>
                                    <div key={index} className="d-flex">
                                        <div className="col-6 lead text-secondary-emphasis fs-18 text-capitalize text-end">{key.replace(/_/g, " ")}:</div>
                                        <div className="col-6 lead text-secondary-emphasis fs-18 fw-semibold text-end">
                                            {key == TOTAL_LABOUR_HOUR_SPENT_CONSTANT
                                                ? `${reportData.totals[key]} hrs`
                                                : `$${reportData.totals[key]}`
                                            }
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    :null}
                </React.Fragment>
            :null}

            {display === "table" ?
                <React.Fragment>

                    {reportData && reportData.reports ?
                        <div className="py-1">
                            <ReactToPrint
                                trigger={() =>
                                    <div>
                                        <button className="btn btn-warning rounded-0">
                                            <i className="fa fa-print me-2" />Print this report
                                        </button>
                                    </div>
                                }
                                content={() => tableRef.current}
                            />
                        </div>
                    :null}

                    <div ref={tableRef}>
                        <table className="table table-striped border border-1 mt-2">
                            <thead>
                                <tr>
                                    {reportData && reportData.reports && Object.keys(reportData.reports[0]).map(column =>
                                        TABLE_COLUMNS.includes(column) ? <th className="text-capitalize">{column.replace(/_/g, " ")}</th> : null
                                    )}
                                </tr>
                            </thead>

                            <tbody>
                                {reportData && reportData.reports && reportData.reports.map((report, index) =>
                                    <tr>
                                        {Object.keys(report).map(key =>
                                            TABLE_COLUMNS.includes(key) ?
                                            <td className="lead text-secondary-emphasis fs-18 text-capitalize">
                                                {key.includes('total') ? `$${report[key]}` : `${report[key]}`}
                                            </td>
                                            :null
                                        )}
                                    </tr>
                                )}

                                {reportData && reportData.reports ?
                                    <tr id="total_calculations">
                                        <td className="lead text-secondary-emphasis fs-18 fw-semibold">Total</td> <td /> <td />
                                        {reportData && reportData.totals && Object.keys(reportData.totals).map((key, index) =>
                                            <td key={index}>
                                                <div className="lead text-secondary-emphasis fs-18 fw-semibold">
                                                    {key == TOTAL_LABOUR_HOUR_SPENT_CONSTANT
                                                        ? `${reportData.totals[key]} hrs`
                                                        : `$${reportData.totals[key]}`
                                                    }
                                                </div>
                                            </td>
                                        )}
                                    </tr>
                                :null}
                            </tbody>
                        </table>

                        {reportData && reportData.reports ?
                            <div className="text-end">
                                <a role="button" className="link-primary" onClick={() => scrollToElement('filters')}>
                                    Jump to filters
                                    <i className="ms-1 fa fa-arrow-up" />
                                </a>
                            </div>
                        :null}
                    </div>
                </React.Fragment>
            :null}

            <Back />
        </div>
    );
};

export default Analytics;