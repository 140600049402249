import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import * as serviceReportConstants from "../../../utils/constants/serviceReport.constants";
import TextInput from "../../form/TextInput";
import {isEmpty} from "../../../utils/helpers/app.helpers";
import {setServiceReport} from "../../../utils/helpers/action.helpers";
import {TECH_INITIALS_TABLE} from "../../../utils/structures/serviceReport.structures";

const TechInitialsTable = (props) => {
    const dispatch = useDispatch();
    const serviceReportDetails = useSelector(state => state.serviceReport);
    const [totalTimeSpent, setTotalTimeSpent] = useState(0);
    const [techInitialRow, setTechInitialRow] = useState(3);

    useEffect(() => {
        calculateTotalTimeSpent(serviceReportDetails);
    }, []);

    function handleChange(event, index) {
        let _fields = {
            tech_initials_table: Object.assign([], {
                ...serviceReportDetails.tech_initials_table,
                [index]: {
                    ...serviceReportDetails.tech_initials_table[index],
                    [event.target.name]: event.target.value
                }
            })
        };

        calculateTotalTimeSpent(_fields);

        dispatch(setServiceReport(_fields));
        props.updateServiceReport(_fields);
    }

    function clearTechInitialSlot(index) {
        let _fields = {
            tech_initials_table: Object.assign([], {
                ...serviceReportDetails.tech_initials_table,
                [index]: {
                    ...serviceReportDetails.tech_initials_table[index],
                        worked_on: "",
                        tech: "",
                        time_spent: "",
                        has_value: false
                }
            })
        };

        calculateTotalTimeSpent(_fields);

        dispatch(setServiceReport(_fields));
        props.updateServiceReport(_fields);
    }

    function renderTD(index) {
        let td = [];
        TECH_INITIALS_TABLE.map((list, list_index) =>
            td.push(
                <td key={list_index} className="h-100" style={{width: `33%`}}>
                    {list.name === "worked_on" &&
                        <input
                            type="date"
                            name="worked_on"
                            value={serviceReportDetails.tech_initials_table[index].worked_on}
                            onChange={(e) => handleChange(e, index)}
                            disabled={props.isEditable}
                            className="form-control w-100 rounded-0"
                        />
                    }
                    {list.name === "tech" &&
                        <TextInput
                            name="tech"
                            value={serviceReportDetails.tech_initials_table[index].tech}
                            disabled={props.isEditable}
                            onChange={(e) => handleChange(e, index)}
                            className="border-0 text-center rounded-0"
                        />
                    }
                    {list.name === "time_spent" &&
                        <React.Fragment>
                            <TextInput
                                name="time_spent"
                                value={serviceReportDetails.tech_initials_table[index].time_spent}
                                disabled={props.isEditable}
                                onChange={(e) => handleChange(e, index)}
                                className="border-0 text-center rounded-0"
                            />
                            <i role={!props.isEditable ? 'button' : ''} className="fa fa-remove position-absolute" onClick={!props.isEditable ? () => clearTechInitialSlot(index) : null} style={{margin: "-25px 0px 0px 120px"}} />
                        </React.Fragment>
                    }
                </td>
            )
        )
        return td;
    }

    function renderTR() {
        let tr = [];
        serviceReportDetails.tech_initials_table.map((row, index) =>
            row.has_value ?
                tr.push(
                    <tr key={index}>{renderTD(row.index)}</tr>
                )
            :null);

        serviceReportDetails.tech_initials_table.map((row, index) =>
            !(row.has_value) && tr.length < techInitialRow ?
                tr.push(
                    <tr key={index}>{renderTD(row.index)}</tr>
                )
            :null);

        return tr;
    }

    function renderTotalTimeSpent() {
        let tr = [];
        tr.push(
            <tr key={0}>
                <td colSpan="2"><b>Total Time Spent</b></td>
                <td><b>{totalTimeSpent}</b></td>
            </tr>
        )
        return tr;
    }

    function calculateTotalTimeSpent(_fields) {
        setTotalTimeSpent(0);
        let totalTime = 0;
        for(let i=0; i<12; i++) {
            if(!isEmpty(_fields.tech_initials_table[i].time_spent)) {
                let parsedValue = parseFloat(_fields.tech_initials_table[i].time_spent);
                totalTime += parsedValue;
            }
        }
        if(!isNaN(totalTime)) {
            setTotalTimeSpent(totalTime);
        }
    }

    function addRow() {
        let updatedRow = techInitialRow + 1;
        setTechInitialRow(updatedRow);
    }

    return (
        <React.Fragment>
            <div className={`${props.currentLayoutView === "full_width_view" ? "col-xl-8 mr-2" : "col-12 mt-3"}`}>
                <table className="service-report-tables w-100 text-center">
                    <thead>
                        <tr>
                            <th className="w-25">{serviceReportConstants.TECH_INITIALS_LABEL}</th>
                            <th className="w-25">{serviceReportConstants.DATE_LABEL}</th>
                            <th className="w-25">{serviceReportConstants.HOURS_LABEL}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderTR()}
                        {renderTotalTimeSpent()}
                    </tbody>
                </table>
                {techInitialRow < 12 && (props.actionType !== "view" && props.actionType !== "revision") &&
                    <div>
                        <button type="button" className="btn btn-light w-100 border border-1 mt-2 r" onClick={() => addRow()}>Add Tech Initial Row</button>
                    </div> }
            </div>
        </React.Fragment>
    );
};

export default TechInitialsTable;
