import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import moment from "moment";

import Back from "../../common/Back";
import {removeTags} from "../../../utils/helpers/app.helpers";
import {postAuthenticated} from "../../../utils/actions/post.actions";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";

const RevisionReport = (props) => {
    const location = useLocation()
    const serviceReportLog = location.state.serviceReportLog;
    const log = location.state.log;

    const [init, setInit] = useState(false);
    const [reportColors, setReportColors] = useState([]);

    useEffect(() => {
        getReportColors();
    }, []);

    function getReportColors() {
        setInit(false);
        let url = API_ENDPOINT + 'v1/ReportColors/getReportColors';
        let params = {}

        postAuthenticated(url, params).then(results => {
            setReportColors(results.data);
            setInit(true);
        });
    }

    function translateField(field_name) {
        if(field_name.includes("tech (row")) {
            return "Tech - Initials";
        }else if(field_name.includes("worked_on (row")) {
            return "Tech - Date";
        }else if(field_name.includes("time_spent (row")) {
            return "Tech - Hours";
        }else{
            return field_name;
        }
    }

    function translateValue(field_name, value) {
        if(field_name == "service_status") {
            let status = reportColors.find(x => x.id == value);
            return status.report_status;
        }else if(field_name == "hide_notes") {
            return value == 0 ? "Show" : "Hide";
        }else{
            return value;
        }
    }

    return (
        init &&
        <div className="mt-4">
            <div className="lead fs-3 fw-bold">Revision #{log.id}</div>
            {serviceReportLog.changes.map((change, index) =>
                <div>
                    {change.id == log.id &&
                        <div>
                            <span>{moment(change.created).fromNow()} - {moment(change.created).format("DD/MM/YYYY")} {moment(change.created).format("hh:mm:ss A")}</span>
                            <table className="table table-bordered table-striped mt-1">
                                <thead>
                                    <tr>
                                        <th>Field</th>
                                        <th>Before</th>
                                        <th>After</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {change.changes && Object.keys(change.changes).map((field, changeIndex) =>
                                        <tr>
                                            <td className="col-3 lead">{translateField(field)}</td>
                                            <td className="col-3">{field === "updated_at" && change.changes[field].before != null ? moment(change.changes[field].before).format("DD/MM/YYYY hh:mm:ss A") : removeTags(translateValue(field, change.changes[field].before))}</td>
                                            <td>{field === "updated_at" && change.changes[field].after != null ? moment(change.changes[field].after).format("DD/MM/YYYY hh:mm:ss A") : removeTags(translateValue(field, change.changes[field].after))}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            )}

            <Back />
        </div>
    );
};

export default RevisionReport;