import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";

import Loader from "../../../common/Loader";
import TextInput from "../../../form/TextInput";
import {API_ENDPOINT} from "../../../../utils/constants/app.constants";
import {postAuthenticated} from "../../../../utils/actions/post.actions";
import {setTracker} from "../../../../utils/slices/tracker.slice";

const FleetLogs = () => {
    const tracker = useSelector(state => state.tracker);

    const dispatch = useDispatch();
    const [init, setInit] = useState(false);
    const [fleetLogs, setFleetLogs] = useState([]);

    useEffect(() => {
        searchFleetLogs();
    }, []);

    useEffect(() => {
        if(init) {
            searchFleetLogs();
        }
    }, [tracker.fleet_logs.search_term]);

    function searchFleetLogs() {
        let url = API_ENDPOINT + "v1/FleetLogs/SearchFleetLogs";
        let params = {
            search_term: tracker.fleet_logs.search_term
        }

        postAuthenticated(url, params).then(results => {
           setFleetLogs(results.data);
           setInit(true);
        });
    }

    function getCreatedDate(log) {
        let fromNow = moment(log.created).fromNow();
        let dateFormat = moment(log.created).format("DD/MM/YYYY");
        let timeFormat = moment(log.created).format("hh:mm:ss A")

        return `${fromNow} - ${dateFormat} ${timeFormat}`;
    }

    function updateTracker(event) {
        let _tracker = {
            ...tracker,
            fleet_logs: {
                ...tracker.fleet_logs,
                search_term: event.target.value
            }
        }
        dispatch(setTracker(_tracker));
    }

    return (
        <div className='main-container w-100'>
            <div>
                <hr />
                <h2 className="lead fs-1 p-2">Fleet Logs</h2>

                <div className="w-100 m-auto input-group mb-2 mt-2">
                    <TextInput
                        name="search_term"
                        value={tracker.fleet_logs.search_term}
                        placeholder="Search..."
                        onChange={(e) => updateTracker(e)}
                    />
                </div>

                <div className="lead text-decoration-underline mt-3">Results: {fleetLogs.length}</div>

                {init ?
                    fleetLogs.length != 0 ?
                        <div>
                            {fleetLogs.map((log, index) =>
                                <div className="p-3 mt-3 mb-3 rounded" style={{border: "1px solid #CFCFCF"}}>
                                    <span className="fw-bold">{log.user.name}</span> has:
                                    <div>
                                        {log.fleet_id != 0 ?
                                            log.content.is_active == 0
                                                ? <div><span className="text-danger fw-bold">Deleted</span> this fleet record {getCreatedDate(log)}</div>
                                                : <div><span className="text-primary fw-bold">Updated</span> this fleet record {getCreatedDate(log)}</div>
                                            : <div><span className="text-success fw-bold">Created</span> this fleet record {getCreatedDate(log)}</div> }
                                    </div>

                                    <div className="mt-3 mb-1">Customer: <span className="lead fw-bold fs-6">{log.fleet_user.company_name}</span></div>
                                    <table className="table table-bordered w-40 border border-1">
                                        <thead>
                                            <tr className="border border-1">
                                                <th>Fleet</th>
                                                <th>Rego</th>
                                                <th>Brought In</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr className="border border-1">
                                                <td>{log.content.fleet_no}</td>
                                                <td>{log.content.rego_no}</td>
                                                <td>{log.content.brought_in_dt}</td>
                                                <td>{log.content.is_active == 0 ? 'Deleted' : 'Active'}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    : <div className="mt-2">No fleet logs were found.</div>
                    : <Loader />
                }
            </div>
        </div>
    );
};

export default FleetLogs;