import UserLogs from "../../components/pages/archived/userLogs/UserLogs";
import FleetLogs from "../../components/pages/archived/fleetLogs/FleetLogs";
import ArchivedReports from "../../components/pages/archived/archivedReports/ArchivedReports";
import ServiceReportLogs from "../../components/pages/archived/serviceReportLogs/ServiceReportLogs";

export const PAGE_SELECTIONS = [
    { constant: "none",
      label: "...",
      component: null },

    { constant: "archived_reports",
      label: "Archived Reports",
      component: ArchivedReports},

    { constant: "service_report_logs",
      label: "Service Report Logs",
      component: ServiceReportLogs},

    { constant: "fleet_logs",
      label: "Fleet Logs",
      component: FleetLogs},

    { constant: "user_logs",
      label: "User Logs",
      component: UserLogs}
];
