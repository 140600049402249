import React, {useEffect, useState} from 'react';
import moment from "moment";

import "../../../../styles/archived/archived.scss";

import TextInput from "../../../form/TextInput";
import {isEmpty, isObjectEmpty} from "../../../../utils/helpers/app.helpers";
import {postAuthenticated} from "../../../../utils/actions/post.actions";
import {API_ENDPOINT} from "../../../../utils/constants/app.constants";
import {Link} from "react-router-dom";

const ArchivedReports = () => {
    let incrementAmount = 20;
    const [init, setInit] = useState(false);
    const [reportColors, setReportColors] = useState([]);
    const [archivedServiceReports, setArchivedServiceReports] = useState([]);
    const [startNumber, setStartNumber] = useState(0);
    const [endNumber, setEndNumber] = useState(incrementAmount);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        getReportColors();
        getArchivedServiceReports();
    }, []);

    useEffect(() => {
        getArchivedServiceReports();
    }, [searchTerm]);

    function getArchivedServiceReports() {
        setInit(true);
        let url = API_ENDPOINT + 'v1/ServiceReports/getArchivedServiceReports';
        let params = {
            keywords: searchTerm
        }

        postAuthenticated(url, params).then(results => {
            setArchivedServiceReports(results.data);
            setInit(false);
        });
    }

    function getReportColors() {
        let url = API_ENDPOINT + 'v1/ReportColors/getReportColors';
        let params = {}

        postAuthenticated(url, params).then(results => {
            setReportColors(results.data);
        });
    }

    function restoreServiceReport(sr) {
        let url = API_ENDPOINT + 'v1/ServiceReports/restoreServiceReport';
        let params = {
            service_report_id: sr.id
        }

        postAuthenticated(url, params).then(results => {
            const updatedServiceReport = archivedServiceReports.filter((item) => item.id !== sr.id);
            setArchivedServiceReports(updatedServiceReport);
        });
    }

    function getReportColorById(id) {
        if(!isEmpty(id)) {
            let reportStatus = reportColors.find(i => i.id == id);

            if(!isObjectEmpty(reportStatus)){
                return reportStatus.color
            }
        }
    }

    function nextServiceReports() {
        let _startNumber = startNumber + incrementAmount;
        let _endNumber = endNumber + incrementAmount;

        setStartNumber(_startNumber);
        setEndNumber(_endNumber);
    }

    function prevServiceReports() {
        let _startNumber = startNumber - incrementAmount;
        let _endNumber = endNumber - incrementAmount;

        setStartNumber(_startNumber);
        setEndNumber(_endNumber);
    }

    function updateSearchTerm(event) {
        setSearchTerm(event.target.value);
    }

    return (
        <div className='main-container w-100'>
            <div>
                <hr />
                <h2 className="lead fs-1 p-2">Archived Service Reports</h2>

                <div className="w-100 m-auto input-group mb-2 mt-2">
                    <TextInput
                        name="search"
                        value={searchTerm}
                        placeholder="Search..."
                        onChange={(e) => updateSearchTerm(e)}
                    />
                </div>
            </div>

            <div className="lead text-decoration-underline mt-3">Results: {archivedServiceReports.length}</div>

            {!init && archivedServiceReports.length > 0 ?
                <div className='mt-3 mb-5'>
                    <table className="table table-responsive fw-bold">
                        <thead>
                        <tr>
                            <th style={{width: "20%"}}>Customer</th>
                            <th style={{width: "20%"}}>Rego NO#</th>
                            <th style={{width: "20%"}}>Fleet NO#</th>
                            <th style={{width: "15%"}}>SR NO#</th>
                            <th style={{width: "15%"}}>Start Date</th>
                            <th style={{width: "10%"}}>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {archivedServiceReports.slice(startNumber, endNumber).map(sr => (
                            <tr key={sr.id} style={{background: `${getReportColorById(sr.service_status)}`}}>
                                <td>{sr.customer}</td>
                                <td>{sr.rego_no}</td>
                                <td>{sr.fleet_no}</td>
                                <td>{sr.srno}</td>
                                <td>{!isEmpty(sr.start_date) ? moment(sr.start_date).format("DD/MM/YYYY") : ""}</td>

                                <td className="align-middle">
                                    <Link to={`/service-report/view/${sr.id}`}>
                                        <span title="View" className="rounded fs-5 p-1 me-2 fa fa-eye icon-white" style={{backgroundColor: "purple"}} />
                                    </Link>

                                    <span title="Restore" className="action-icons rounded fs-5 p-1 me-2 bg-danger fa fa-undo icon-white" onClick={() => restoreServiceReport(sr)} />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                    <div className="text-center">
                        {startNumber !== 0 &&
                            <button className="btn btn-warning me-3" onClick={() => prevServiceReports()} style={{width: "10%"}}>Prev</button>
                        }
                        {archivedServiceReports.length > endNumber &&
                            <button className="btn btn-warning" onClick={() => nextServiceReports()} style={{width: "10%"}}>Next</button>
                        }
                    </div>
                </div>
                : !init && archivedServiceReports.length === 0 ? <div className="text-center">No archived service reports found.</div>
                    : init ?
                        <div className="w-100 position-absolute">
                            <div className="loader"/>
                        </div>
                        : null
            }
        </div>
    );
};

export default ArchivedReports;
