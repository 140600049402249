export const SERVICE_REPORT_HISTORY_TABLE = [
    {
        label: "Customer",
        property: "customer",
        type: "string"
    },
    {
        label: "Invoice NO#",
        property: "invoice_no",
        type: "string"
    },
    {
        label: "Invoice Date",
        property: "invoice_date",
        type: "date"
    },
    {
        label: "Service Type(s)",
        property: "work_instructions",
        type: "string"
    },
    {
        label: "Rego NO#",
        property: "rego_no",
        type: "string"
    },
    {
        label: "Fleet NO#",
        property: "fleet_no",
        type: "string"
    },
    {
        label: "SR NO#",
        property: "srno",
        type: "string"
    },
    {
        label: "Start Date",
        property: "start_date",
        type: "date"
    },
    {
        label: "Finish Date",
        property: "end_date",
        type: "date"
    },
    {
        label: "Tech Initials",
        property: "tech_initials_table",
        type: "array"
    },
];
