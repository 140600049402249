export const CHANGE_LOGS = [
    { version: "0.0.7",
        date: "21 February 2024",
        log: [
            {change: "New <span class='fw-bold'>'Jobs'</span> page has been <span class='fw-bold'>created</span>"},
            {change: "New <span class='fw-bold'>'Analytics'</span> page has been <span class='fw-bold'>created</span> (for Super Admin only)"},
            {change: "<span class='fw-bold'>Job cards</span> are now <span class='fw-bold'>linked</span> with service reports"},
            {change: "<span class='fw-bold'>UIUX improvements</span> to the entire page (like navigation, tables, modal, titles"}
        ]},

    { version: "0.0.6",
        date: "22 December 2023",
        log: [
            { change: "Invoiced reports are now <span class='fw-bold'>uneditable</span>, regardless of its status"},
            { change: "<span class='fw-bold'>Fixed an issue</span> where sub report numbers were displaying 0"},
            { change: "<span class='fw-bold'>Changed</span> 'Hide from customer' icon to a <span class='fw-bold'>toggle</span>"},
            { change: "<span class='fw-bold'>Fixed an issue</span> where rows were hidden from the Part Rows modal table"},
            { change: "Option to <span class='fw-bold'>redirect</span> to the service report page from the fleet 'View service reports' modal"},
            { change: "<span class='fw-bold'>Fixed numerous issues</span> with the print page (TechInitial table cutting off, fleet no missing, footer header issues, edges cut off)"},
            { change: "<span class='fw-bold'>Redesigned</span> tables and modals to a more modern and readable look"},
        ]},

    { version: "0.0.5",
        date: "14 September 2023",
        log: [
            { change: "Option to <span class='fw-bold'>print</span> report from the service report page (redirect)"},
            { change: "<span class='fw-bold'>Fixed</span> all search functionalities and errors on the history page"},
            { change: "Rego_no now <span class='fw-bold'>populates</span> its field upon selecting a customer (with fleet_no)"},
            { change: "Once a service report has been invoiced, all service status buttons are <span class='fw-bold'>hidden</span> (except from Admins and Super Admins)"},
            { change: "<span class='fw-bold'>Fixed</span> sub reports showing incorrect sub number"},
            { change: "Additional bug <span class='fw-bold'>fixes</span> with the layout view option"},
            { change: "Additional bug <span class='fw-bold'>fixes</span> with the calculation section (numbers weren't showing up)"},
            { change: "<span class='fw-bold'>Fixed</span> user permission issue when creating a new user"},
            { change: "<span class='fw-bold'>Fixed</span> auto scroll on history page"},
        ]},

    { version: "0.0.4",
        date: "15 August 2023",
        log: [
            { change: "Ability to <span class='fw-bold'>deactivate</span> a user"},
            { change: "<span class='fw-bold'>Tightened</span> security by verifying user's token upon each API call"},
            { change: "View parts table via the <span class='fa fa-wrench icon-white bg-dark fs-6'></span> icon on the history page"},
        ]},

    { version: "0.0.3",
        date: "09 August 2023",
        log: [
            { change: "Ability to hide <span class='fw-bold'>notes</span> section when printing a report"},
            { change: "Ability to <span class='fw-bold'>restrict</span> users from viewing individual pages inside Archived"},
        ]},

    { version: "0.0.2",
        date: "03 August 2023",
        log: [
            { change: "Creation of sub reports"},
            { change: "Last selected revision of a report now <span class='fw-bold'>highlights</span> itself"},
            { change: "Date (brought in) column <span class='fw-bold'>added</span> to fleet modal"},
        ]},

    { version: "0.0.1",
        date: "06 July 2023",
        log: [
            { change: "Ability to <span class='fw-bold'>add additional</span> rows to the parts table"},
            { change: "Minor UI & UX <span class='fw-bold'>changes</span> to modals"},
        ]},
];