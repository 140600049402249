import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import moment from "moment";

import Loader from "../../common/Loader";
import InvoiceModal from "../../modals/historyModals/InvoiceModal";
import PartTableModal from "../../modals/historyModals/PartTableModal";
import WorkInstructionModal from "../../modals/historyModals/WorkInstructionModal";

import {setModal} from "../../../utils/slices/modal.slice";
import {postAuthenticated} from "../../../utils/actions/post.actions";
import {isEmpty, isObjectEmpty} from "../../../utils/helpers/app.helpers";
import {setHistoryPageNumber, setUserProfile} from "../../../utils/helpers/action.helpers";
import {setHistoryTracker} from "../../../utils/slices/historyTracker.slice";
import {API_ENDPOINT} from "../../../utils/constants/app.constants";
import {WORK_INSTRUCTIONS_CHECKLIST} from "../../../utils/structures/serviceReport.structures";
import {SERVICE_REPORT_HISTORY_TABLE} from "../../../utils/structures/historyTable.structures";


const HistoryTable = props => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const historyTracker = useSelector(state => state.historyTracker);
    const userProfile = useSelector(state => state.userProfile);
    const userPermissions = useSelector(state => state.userPermissions);

    const [init, setInit] = useState(false);
    const [focus, setFocus] = useState(false);
    const [adjustPage, setAdjustPage] = useState(false);
    const [serviceReports, setServiceReports] = useState([]);

    let incrementAmount = 20;
    const [startNumber, setStartNumber] = useState(0);
    const [endNumber, setEndNumber] = useState(incrementAmount);

    const is_user_super_admin = userProfile.role == 4;
    const firstServiceReport = useRef(null);
    const executeScroll = () => firstServiceReport.current.scrollIntoView();

    useEffect(() => {
        setServiceReports(props.searchedReports);
    }, [props.searchedReports]);

    useEffect(() => {
        setInit(false);
        serviceReports.length > 0 && setAdjustPage(true);
        setInit(true);
    }, [serviceReports]);

    useEffect(() => {
        if(adjustPage) {
            let endAmount = historyTracker.page * incrementAmount;
            let startAmount = endAmount - incrementAmount;

            setStartNumber(startAmount);
            setEndNumber(endAmount);

            setFocus(true);
        }
    }, [adjustPage]);

    useEffect(() => {
        if(focus) {
            let recentServiceReport = document.getElementById(historyTracker.lastSelectedReport);
            if(recentServiceReport) {
                recentServiceReport.focus();
            }
        }
    }, [focus]);

    useEffect(() => {
        if(props.resetPage) {
            setStartNumber(0);
            setEndNumber(20);
        }
    }, [props.resetPage]);

    function archiveServiceReport(event, sr) {
        event.stopPropagation();
        let url = API_ENDPOINT + 'v1/ServiceReports/archiveServiceReport';
        let params = {
            service_report_id: sr.id
        }

        postAuthenticated(url, params).then(results => {
            const updatedServiceReport = serviceReports.filter((item) => item.id != sr.id);
            setServiceReports(updatedServiceReport);
        });
    }

    function createSubReport(event, sr) {
        event.stopPropagation();
        let url = API_ENDPOINT + 'v1/ServiceReports/createSubReport';
        let params = {
            service_report_id: sr.id
        };

        postAuthenticated(url, params).then(results => {
            //navigate(`/service-report/edit/${results.data}`);
        });
    }

    function hideServiceReport(event, serviceReport) {
        event.stopPropagation();
        let value = serviceReport.is_hidden == 0 ? 1 : 0;

        let url = API_ENDPOINT + "v1/ServiceReports/hideServiceReport";
        let params = {
            id: serviceReport.id,
            is_hidden: value
        };

        postAuthenticated(url, params).then(results => {
            props.searchServiceReports();
        });
    }

    function getReportColorById(id) {
        return props.reportColors.find(x => x.id == id).color;
    }

    function nextServiceReports() {
        let _startNumber = startNumber + incrementAmount;
        let _endNumber = endNumber + incrementAmount;

        setStartNumber(_startNumber);
        setEndNumber(_endNumber);

        let _historyPage = {
            ...historyTracker,
            page: (historyTracker.page + 1),
            historyReportCount: _startNumber,
            lastSelectedReport: 0,
        }
        dispatch(setHistoryPageNumber(_historyPage));
        executeScroll();
    }

    function prevServiceReports() {
        let _startNumber = startNumber - incrementAmount;
        let _endNumber = endNumber - incrementAmount;

        setStartNumber(_startNumber);
        setEndNumber(_endNumber);

        let _historyPage = {
            ...historyTracker,
            page: (historyTracker.page - 1),
            historyReportCount: _startNumber,
            lastSelectedReport: 0,
        }
        dispatch(setHistoryPageNumber(_historyPage));
        executeScroll();
    }

    function updateLastSelectedReport(event, sr) {
        event.stopPropagation();
        let _historyPage = {
            ...historyTracker,
            lastSelectedReport: sr.id
        };

        dispatch(setHistoryTracker(_historyPage));
    }

    function launchWorkInstructionsModal(event, sr) {
        event.stopPropagation();
        updateLastSelectedReport(event, sr);

        let modalSettings = {
            config: {
                component: WorkInstructionModal,
                props: {
                    sr: sr,
                }
            }
        }

        updateLastSelectedReport(event, sr);
        dispatch(setModal(modalSettings));
    }

    function launchInvoiceModal(event, sr) {
        event.stopPropagation();
        updateLastSelectedReport(event, sr);

        let modalSettings = {
            config: {
                component: InvoiceModal,
                props: {
                    sr: sr,
                }
            },
            onClose: () => props.searchServiceReports("")
        }
        dispatch(setModal(modalSettings));
    }

    function launchPartTableModal(event, service_report) {
        event.stopPropagation();
        updateLastSelectedReport(event, service_report);

        let modalSettings = {
            config: {
                component: PartTableModal,
                props: {
                    service_report: service_report,
                }
            },
            onClose: () => {}
        }
        dispatch(setModal(modalSettings));
    }

    function canEditServiceReport(sr) {
        let canView = true;
        let isNotSuperAdmin = userProfile.role != 4;

        if(!isEmpty(sr.invoice_no) && (isNotSuperAdmin)){ canView = false; }
        if(!isEmpty(sr.invoice_date) && (isNotSuperAdmin)){ canView = false; }
        if(!isEmpty(sr.signature) && (isNotSuperAdmin)){ canView = false; }
        if(sr.service_status == 5 && (isNotSuperAdmin)){ canView = false; }

        return canView;
    }

    function canSetInvoiceDetails() {
        return userProfile.role == 1 || userProfile.role == 4;
    }

    function serviceReportActionPermission(constant) {
        return userPermissions.service_report_actions[constant] == true;
    }

    function canCreateSubReport(sr) {
        let canCreateSubReport = true;

        if(isEmpty(sr.invoice_no) || isEmpty(sr.invoice_date)){ canCreateSubReport = false; }
        if(sr.service_status != 2){ canCreateSubReport = false; }

        return canCreateSubReport;
    }

    function getSubReportCount(srno, report_id) {
        let subReports = serviceReports.filter(x => x.srno == srno && x.parent_id != null);
        subReports.reverse();
        let reportIndex = subReports.findIndex(x => x.id == report_id);

        return reportIndex+1 < 0 ? 0 : reportIndex;
    }

    return (
        init &&
        <div className='mt-5 mb-5 m-auto'>
            <div className="lead mb-1 text-decoration-underline">Results: {serviceReports.length}</div>
            <div className="lead mb-1 text-decoration-underline mb-3">Page: {historyTracker.page}</div>

            {!isObjectEmpty(serviceReports) ?
                userProfile.layout_view_constant != "mobile_view" ?
                    <table className="fw-bold" ref={firstServiceReport}>
                        <thead>
                            <tr>
                                <th scope="col" style={{width: "1%"}}>Customer</th>
                                <th scope="col" style={{width: "1%"}}>Invoice NO#</th>
                                <th scope="col" style={{width: "1%"}}>Invoice Date</th>
                                <th scope="col" style={{width: "3%"}}>Service Type</th>
                                <th scope="col" style={{width: "1%"}}>Rego NO#</th>
                                <th scope="col" style={{width: "1%"}}>Fleet NO#</th>
                                <th scope="col" style={{width: "1%"}}>SR NO#</th>
                                <th scope="col" style={{width: "1%"}}>Start Date</th>
                                <th scope="col" style={{width: "1%"}}>Finish Date</th>
                                <th scope="col" style={{width: "2%"}}>Tech initials</th>
                                <th scope="col" style={{width: "1%"}}>Actions</th>
                            </tr>
                        </thead>
                        <tbody className="">
                            {serviceReports.slice(startNumber, endNumber).map((sr, index) => (
                               <tr className="p-2 align-top" key={sr.id} id={sr.id} tabIndex={0} onClick={(e) => launchWorkInstructionsModal(e, sr)} style={{backgroundColor: `${getReportColorById(sr.service_status)}`, border: `${historyTracker.lastSelectedReport == sr.id ? '3px solid blue' : 'none'}`}}>
                                   <td className="ps-2 pt-2" data-label="Customer">{sr.customer}</td>
                                   <td className="pt-2" data-label="Invoice NO#" role="button">{sr.invoice_no}</td>
                                   <td className="pt-2" data-label="Invoice Date" role="button">{!isEmpty(sr.invoice_date) ? moment(sr.invoice_date).format("DD-MM-YYYY") : ""}</td>
                                   <td className="pt-2" data-label="Service Type(s)" role="button">
                                       {WORK_INSTRUCTIONS_CHECKLIST.map(list => sr.work_instructions[list.constant] && `${list.label}, `)}
                                   </td>
                                   <td className="pt-2" data-label="Rego NO#" role="button">{sr.rego_no}</td>
                                   <td className="pt-2" data-label="Fleet NO#" role="button">{sr.fleet_no}</td>
                                   <td className="pt-2" data-label="SR NO#" role="button">
                                       {sr.parent_id != null ?
                                           <React.Fragment>
                                               <div>{sr.srno} - {sr.sub_count}</div>
                                           </React.Fragment>
                                           : sr.srno
                                       }
                                   </td>
                                   <td className="pt-2" data-label="Start Date" role="button">{!isEmpty(sr.start_date) ? moment(sr.start_date).format("DD/MM/YYYY") : ""}</td>
                                   <td className="pt-2" data-label="End Date" role="button">{!isEmpty(sr.end_date) ? moment(sr.end_date).format("DD/MM/YYYY") : ""}</td>
                                   <td className="pt-2" data-label="Tech Initials" role="button">
                                       {!isObjectEmpty(sr.tech_initials_table) && sr.tech_initials_table.map((ti, index) => !isEmpty(ti.tech) && `${ti.tech}, `)}
                                   </td>
                                   <td className="align-middle p-2 pt-2">
                                       {canEditServiceReport(sr) && serviceReportActionPermission('edit') &&
                                           <Link to={`/service-report/edit/${sr.id}`} state={{sub: getSubReportCount(sr.srno, sr.id)}} onClick={(e) => updateLastSelectedReport(e, sr)}>
                                               <span title="EditJob" className="rounded fs-5 p-1 me-2 bg-warning fa fa-edit icon-white mb-1"/></Link> }

                                       {serviceReportActionPermission('view') &&
                                           <Link to={`/service-report/view/${sr.id}`} state={{sub: getSubReportCount(sr.srno, sr.id)}} onClick={(e) => updateLastSelectedReport(e, sr)}>
                                               <span title="View" className="rounded fs-5 p-1 me-2 fa fa-eye icon-white mb-1" style={{backgroundColor: "purple"}} /></Link> }

                                       {serviceReportActionPermission('print') &&
                                           <Link to={`/print-report/${sr.id}`} onClick={(e) => updateLastSelectedReport(e, sr)}>
                                               <span title="Print" className="rounded fs-5 p-1 me-2 fa fa-print icon-white mb-1" style={{backgroundColor: "dimgray"}} /></Link> }

                                       {canSetInvoiceDetails() && serviceReportActionPermission('invoice') &&
                                           <span title="Invoice" className="action-icons rounded fs-5 p-1 px-2 me-2 bg-success fa fa-dollar icon-white mb-1" onClick={(e) => launchInvoiceModal(e, sr)}/> }

                                       {canCreateSubReport(sr) && serviceReportActionPermission('create_sub_report') &&
                                           <span title="Create sub-report" className="action-icons rounded fs-5 p-1 px-2 me-2 bg-primary fa fa-angle-double-right icon-white mb-1" onClick={(e) => createSubReport(e, sr)} /> }

                                       <span title="View parts table" className="action-icons rounded fs-5 p-1 px-1 me-2 bg-black fa fa-wrench icon-white mb-1" onClick={(e) => launchPartTableModal(e, sr)} />

                                       {(sr.service_status != 5 || is_user_super_admin) && serviceReportActionPermission('archive') &&
                                           <span title="Archive" className="action-icons rounded fs-5 px-2 py-1 me-2 bg-danger fa fa-trash icon-white mb-1" onClick={(e) => archiveServiceReport(e, sr)}/> }

                                       {is_user_super_admin &&
                                           <span role="button" className={`form-check form-switch mb-1 mt-1`} onClick={(e) => e.stopPropagation()}>
                                               <input type="checkbox" role="switch" className="form-check-input align-middle"
                                                      title="Hide from customer"
                                                      name='is_hidden' value={sr.is_hidden}
                                                      checked={sr.is_hidden == 1}
                                                      onChange={(e) => hideServiceReport(e, sr)} />
                                           </span>
                                       }
                                   </td>
                               </tr>
                            ))}
                        </tbody>
                    </table>
                :
                    <div ref={firstServiceReport}>
                        {serviceReports.slice(startNumber, endNumber).map(sr =>
                            <div key={sr.id} id={sr.id} tabIndex={0} className="mb-3 p-3" style={{backgroundColor: `${getReportColorById(sr.service_status)}`, border: `${historyTracker.lastSelectedReport == sr.id ? '4px solid blue' : 'none'}`}}>
                                {SERVICE_REPORT_HISTORY_TABLE.map((column, index) =>
                                        <div className="d-flex pb-1 border border-dark border-top-0 border-end-0 border-start-0">
                                            <div className="col-3 fw-bolder">{column.label}:</div>
                                            <div className="col-9">
                                                {column.property === "work_instructions" ?
                                                    WORK_INSTRUCTIONS_CHECKLIST.map(list =>
                                                        sr.work_instructions[list.constant] && <span className="float-end fw-bold">{`${list.label}, `}</span>)
                                                    : column.property === "tech_initials_table" ?
                                                        sr.tech_initials_table.map((ti, index) =>
                                                            !isEmpty(ti.tech) && <span className="float-end fw-bold">{ti.tech},</span>)
                                                        : <span className="float-end fw-bold">
                                {column.type === "date" ?
                                    !isEmpty(sr[column.property]) ? moment(sr[column.property]).format("DD/MM/YYYY") : ""
                                    : sr[column.property] }</span> }
                                            </div>
                                        </div>
                                )}
                                <div className="mt-3 text-end">
                                    {canEditServiceReport(sr) && serviceReportActionPermission('edit') &&
                                        <Link to={`/service-report/edit/${sr.id}`} onClick={(e) => updateLastSelectedReport(e, sr)}>
                                            <span title="EditJob" className="rounded fs-5 p-1 me-2 bg-warning fa fa-edit icon-white mb-1"/></Link> }

                                    {serviceReportActionPermission('view') &&
                                        <Link to={`/service-report/view/${sr.id}`} onClick={(e) => updateLastSelectedReport(e, sr)}>
                                            <span title="View" className="rounded fs-5 p-1 me-2 fa fa-eye icon-white mb-1" style={{backgroundColor: "purple"}} /></Link> }

                                    {serviceReportActionPermission('print') &&
                                        <Link to={`/print-report/${sr.id}`} onClick={(e) => updateLastSelectedReport(e, sr)}>
                                            <span title="Print" className="rounded fs-5 p-1 me-2 fa fa-print icon-white mb-1" style={{backgroundColor: "dimgray"}} /></Link> }

                                    {canSetInvoiceDetails() && serviceReportActionPermission('invoice') &&
                                        <span title="Invoice" className="action-icons rounded fs-5 p-1 px-2 me-2 bg-success fa fa-dollar icon-white mb-1" onClick={(e) => launchInvoiceModal(e, sr)}/> }

                                    {canCreateSubReport(sr) && serviceReportActionPermission('create_sub_report') &&
                                        <span title="Create sub-report" className="action-icons rounded fs-5 p-1 px-2 me-2 bg-primary fa fa-angle-double-right icon-white mb-1" onClick={(e) => createSubReport(e, sr)} /> }

                                    <span title="View parts table" className="action-icons rounded fs-5 p-1 px-2 me-2 bg-dark fa fa-wrench icon-white mb-1" onClick={(e) => launchPartTableModal(e, sr)} />

                                    {(sr.service_status != 5 || is_user_super_admin) && serviceReportActionPermission('archive') &&
                                        <span title="Archive" className="action-icons rounded fs-5 px-2 py-1 me-2 bg-danger fa fa-trash icon-white mb-1" onClick={(e) => archiveServiceReport(e, sr)}/> }

                                    {is_user_super_admin &&
                                        <span role="button" className={`form-check form-switch mb-1 mt-1`}>
                                               <input type="checkbox" role="switch" className="form-check-input align-middle"
                                                      title="Hide from customer"
                                                      name='is_hidden' value={sr.is_hidden}
                                                      checked={sr.is_hidden == 1}
                                                      onChange={(e) => hideServiceReport(e, sr)} />
                                           </span>
                                    }
                                </div>
                            </div>
                        )}
                    </div>

            : init && serviceReports.length == 0 ? <div className="text-center">No service reports found.</div>
            : !init ? <Loader /> : null}

            {init &&
                <div className="text-center mt-3 w-100">
                    {startNumber != 0 && <button className="btn btn-warning me-3" onClick={() => prevServiceReports()}>Prev</button> }
                    {serviceReports.length > endNumber && <button className="btn btn-warning" onClick={() => nextServiceReports()}>Next</button> }
                </div>
            }
        </div>
    );
};

HistoryTable.propTypes = {

};

export default HistoryTable;
