import React, {useEffect, useState} from 'react';
import {API_ENDPOINT} from "../../../utils/constants/app.constants";
import {postAuthenticated} from "../../../utils/actions/post.actions";
import moment from "moment/moment";
import {removeTags} from "../../../utils/helpers/app.helpers";

const ServiceLogModal = (props) => {
    const serviceReportLog = props.serviceReportLog;
    const [changes, setChanges] = useState([]);

    useEffect(() => {
        getServiceReportLogChanges();
    }, []);

    function getServiceReportLogChanges() {
        let url = API_ENDPOINT + "v1/ServiceReports/getServiceReportLogChanges";
        let params = {
            service_report_id: serviceReportLog.logs[0].service_report_id
        }

        postAuthenticated(url, params).then(results => {
            setChanges(results.data);
        });
    }

    return (
        <div className="modal d-block" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog modal-xl modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header d-block">
                        <h2 className="lead fs-2">Service Log - Changes</h2>
                        <div>for <span className="text-decoration-underline fw-light">{serviceReportLog.srno} | Changes ({serviceReportLog.revisions-1})</span></div>
                    </div>

                    <div className="modal-body" style={{overflowY: "scroll", minHeight: "500px"}}>
                        {changes.map((change, index) =>
                            <div className="">
                                <div className="fw-bolder fs-3">Change #{index+1}</div>
                                <span>{moment(change.created).fromNow()} - {moment(change.created).format("DD/MM/YYYY")} {moment(change.created).format("hh:mm:ss A")}</span>
                                <table className="table table-bordered table-striped mt-1">
                                    <thead>
                                        <tr>
                                            <th>Field</th>
                                            <th>Before</th>
                                            <th>After</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {change.changes && Object.keys(change.changes).map((field, changeIndex) =>
                                            <tr>
                                                <td className="col-3 lead">{field}</td>
                                                <td className="col-3">{removeTags(change.changes[field].before)}</td>
                                                <td>{removeTags(change.changes[field].after)}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                <hr />
                            </div>
                        )}
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => {document.body.style.overflow = "auto"; props.closeModal(false);}}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceLogModal;